import { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { QueryStatus } from '@reduxjs/toolkit/query'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { SHOW_STRIPE_CHECKOUT } from '@/index'
import { useLazyGetOrganizationWithIntegrationsQuery } from '@/services/hasura/organizations'
import { useLazyGetUserByFirebaseIdQuery } from '@/services/hasura/users'

import LoginSpinner from './LoginSpinner'
import CheckInbox from './Views/CheckInbox'
import SignIn from './Views/SignIn'
import SignUp from './Views/SignUp'
import useEmailVerification from './hooks/useEmailVerification'
import useSignUp from './hooks/useSignUp'
import useTextField from './hooks/useTextField'
import { LoginState } from './shared'
import { useAuth } from '../../context/AuthContext'
import OnboardingSurface from '../UIKit/Page/OnboardingSurface'

const FirebaseLogin = () => {
  const navigate = useNavigate()
  const location = useLocation()

  let [searchParams] = useSearchParams()
  const searchEmail = searchParams.get('email')
  const searchInvitationId = searchParams.get('invitation')
  const impersonateId = searchParams.get('impersonate') || undefined

  const [loginState, setLoginState] = useState<LoginState>(
    searchEmail || searchInvitationId ? LoginState.SignUp : LoginState.SignIn
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [notification, setNotification] = useState<string>('')

  const withRequest = async (fn: () => any) => {
    setLoading(true)
    setError('')
    setNotification('')
    try {
      await fn()
    } catch (error) {
      setError((error as Error).message)
    } finally {
      setLoading(false)
    }
  }
  const [getProfile] = useLazyGetUserByFirebaseIdQuery()
  const { login, profile, requestPasswordReset, signUp, user } = useAuth()

  useEmailVerification()

  const {
    handleUserInsert,
    insertOrganizationStatus,
    invitation,
    isInvitationLoading,
  } = useSignUp({
    invitationId: searchInvitationId,
  })

  const [getOrganization] = useLazyGetOrganizationWithIntegrationsQuery()
  const redirectFromPath = location.state?.from?.pathname as string
  const pathname = location.pathname
  useEffect(() => {
    if (profile && user?.emailVerified && profile.organizationId) {
      const handler = async () => {
        // TODO reimplement later
        /*if (!organization.onboardingComplete) {
          // 👇 Redirects - note the `replace: true`
          navigate(`/organization/${profile?.organizationId}/onboarding`, {
            replace: true,
          })
        } else */
        const redirectPath = await (async () => {
          const baseURL = `/organization/${profile.organizationId}`
          const onboardingURL = `${baseURL}/onboarding`
          const dashboardURL = `${baseURL}/dashboard`

          if (insertOrganizationStatus === QueryStatus.fulfilled) {
            return onboardingURL
          }

          const organization = await getOrganization(
            profile.organizationId!
          ).unwrap()

          if (!organization.stripeCustomerId && SHOW_STRIPE_CHECKOUT) {
            return `${onboardingURL}${
              organization.name ? '?start=payment' : ''
            }`
          }
          if (!organization.integrations.length) {
            return `${onboardingURL}${
              organization.name ? '?start=integration' : ''
            }`
          }
          if (redirectFromPath?.includes(profile.organizationId!)) {
            return redirectFromPath
          }
          if (pathname.startsWith('/p')) {
            return null
          }
          return dashboardURL
        })()

        if (redirectPath) {
          navigate(redirectPath, { replace: true })
        }
      }
      handler()
    }
  }, [
    profile,
    insertOrganizationStatus,
    navigate,
    redirectFromPath,
    getOrganization,
    pathname,
    user?.emailVerified,
  ])

  const {
    handleChange: handleEmailChange,
    setValue: setEmail,
    value: email,
  } = useTextField({
    defaultValue: searchEmail || undefined,
    forceLowerCase: true,
  })

  useEffect(() => {
    if (invitation) {
      setEmail(invitation.email)
    }
  }, [invitation, setEmail])

  const { handleChange: handleChoosePasswordChange, value: choosePassword } =
    useTextField({})
  const { handleChange: handleConfirmPasswordChange, value: confirmPassword } =
    useTextField({})

  const handleLoginStateChange = (value: LoginState) => {
    setLoginState(value)
  }

  const handleSignUpClick = () => {
    return withRequest(async () => {
      const authUser = await signUp(email, choosePassword)
      if (authUser) {
        await handleUserInsert(authUser)
        setLoginState(LoginState.CheckInbox)
      }
    })
  }

  const handleLoginClick = () => {
    return withRequest(async () => {
      const authUser = await login(email, choosePassword, impersonateId)
      if (authUser) {
        const authProfile = await getProfile(authUser.uid).unwrap()
        if (!authProfile) {
          await handleUserInsert(authUser)
        }
      }
    })
  }

  const handleSendPasswordResetClick = async () => {
    return withRequest(async () => {
      await requestPasswordReset(email)
      setNotification('Password reset email sent, check your inbox.')
    })
  }

  const showEmailVerification =
    user !== null &&
    (user?.emailVerified === false || loginState === LoginState.CheckInbox)

  return (
    <Stack>
      {user !== null ? (
        <OnboardingSurface login>
          {showEmailVerification ? (
            <CheckInbox email={user?.email || email} />
          ) : (
            <LoginSpinner />
          )}
        </OnboardingSurface>
      ) : null}

      {user === null ? (
        <OnboardingSurface login>
          {loginState === LoginState.SignUp ? (
            <SignUp
              authError={error}
              authNotification={notification}
              choosePassword={choosePassword}
              confirmPassword={confirmPassword}
              disabledEmail={Boolean(searchEmail)}
              email={email}
              handleContinueClick={handleSignUpClick}
              handleLoginStateChange={handleLoginStateChange}
              loading={loading || isInvitationLoading}
              onChoosePasswordChange={handleChoosePasswordChange}
              onConfirmPasswordChange={handleConfirmPasswordChange}
              onEmailChange={handleEmailChange}
            />
          ) : null}
          {loginState === LoginState.SignIn ||
          loginState === LoginState.ForgotPassword ? (
            <SignIn
              authError={error}
              authNotification={notification}
              email={email}
              handleLoginStateChange={handleLoginStateChange}
              handleSendPasswordResetClick={handleSendPasswordResetClick}
              loading={loading}
              loginState={loginState}
              onEmailChange={handleEmailChange}
              onLoginClick={handleLoginClick}
              onPasswordChange={handleChoosePasswordChange}
              password={choosePassword}
            />
          ) : null}
        </OnboardingSurface>
      ) : null}
    </Stack>
  )
}

export default FirebaseLogin
