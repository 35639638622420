import { Button, ButtonProps, Typography, useTheme } from '@mui/material'

import NeonOutlinedButton from '../../../packages/neon/NeonOutlinedButton'

export type DialogButtonProps = Omit<ButtonProps, 'variant'> & {
  variant: 'contained' | 'outlined'
}

// TODO design: Need an extra large or dialog button size variant
const DialogButton = ({
  children,
  sx,
  variant,
  ...restProps
}: DialogButtonProps) => {
  const theme = useTheme()
  const darkMode = theme.palette.mode === 'dark'

  const styles = {
    contained: { background: 'primary.main' },
    outlined: { borderColor: theme.palette.grey['50'], borderWidth: 1 },
  }

  // TODO design: Need an extra large or dialog button size variant
  const buttonContent = darkMode ? (
    <NeonOutlinedButton {...restProps}>{children}</NeonOutlinedButton>
  ) : (
    <Button
      sx={{
        borderRadius: 28,
        height: '56px !important',
        px: 3,
        py: 1.25,
        ...styles[variant],
        ...sx,
      }}
      variant={variant}
      {...restProps}
    >
      <Typography variant="button2">{children}</Typography>
    </Button>
  )

  return buttonContent
}

export default DialogButton
