import { Stack } from '@mui/material'

type SplitViewContainerListProps = {
  children: React.ReactNode
}

const SplitViewContainerList = ({ children }: SplitViewContainerListProps) => {
  return <Stack sx={{ width: 300 }}>{children}</Stack>
}

export default SplitViewContainerList
