import { Stack } from '@mui/material'

import LogoTextWhite from '../../../logos/LogoTextWhite'
import LogoWhite32 from '../../../logos/LogoWhite32'

export const LOGIN_PAGE_HEADER_HEIGHT = 80

const LoginPageHeader = () => {
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        maxHeight: LOGIN_PAGE_HEADER_HEIGHT,
        minHeight: LOGIN_PAGE_HEADER_HEIGHT,
        px: 10,
        zIndex: 1,
      }}
    >
      <Stack alignItems="center" direction="row" spacing={1.125}>
        <LogoWhite32 />
        <LogoTextWhite />
      </Stack>
    </Stack>
  )
}

export default LoginPageHeader
