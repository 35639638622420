import { Stack } from '@mui/material'

type LoginFieldsProps = {
  children: React.ReactNode
}

const LoginFields = ({ children }: LoginFieldsProps) => {
  return <Stack spacing={2}>{children}</Stack>
}

export default LoginFields
