import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const sierraQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_CORS_PROXY_URL}/${process.env.REACT_APP_SIERRA_API_URL}`,
})

export const sierraApi = createApi({
  reducerPath: 'sierraApi',
  baseQuery: sierraQuery,
  tagTypes: ['SierraWebhook'],
  endpoints: () => ({}),
})
