import { Box } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { map } from 'lodash'

import NotificationSectionGroup from './NotificationSectionGroup'
import OutcomeItem from '../Call/LeftPanel/Outcome/OutcomeItem'

type NotificationCallOutcomeProps = {
  outcomes: string[]
}
const COLUMN_SPACING = 2
const ROW_SPACING = 1

const NotificationCallOutcome = ({
  outcomes,
}: NotificationCallOutcomeProps) => {
  return (
    <NotificationSectionGroup title="Outcome">
      <Box>
        <Grid2
          container
          rowSpacing={ROW_SPACING}
          spacing={COLUMN_SPACING}
          width={1}
        >
          {map(outcomes, (outcome) => (
            <Grid2 key={outcome} xs={6}>
              <OutcomeItem title={outcome} />
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </NotificationSectionGroup>
  )
}

export default NotificationCallOutcome
