import { Link, Stack, Typography } from '@mui/material'

import DialogButton from '../../UIKit/Button/DialogButton'
import PageModalHeaderText from '../../UIKit/Page/PageModalHeaderText'
import AuthError from '../AuthError'
import AuthNotification from '../AuthNotification'
import EmailField from '../EmailField'
import LoginContent from '../LoginContent'
import LoginFields from '../LoginFields'
import LoginForm from '../LoginForm'
import LoginHeader from '../LoginHeader'
import LoginPasswordField from '../LoginPasswordField'
import SecondaryLoginButton from '../SecondaryLoginButton'
import { INPUT_WIDTH } from '../consts'
import { LoginState } from '../shared'

type SignUpProps = {
  authError: any
  authNotification: any
  choosePassword: string
  confirmPassword: string
  disabledEmail: boolean
  email: string
  handleContinueClick: () => void
  handleLoginStateChange: (loginState: LoginState) => void
  loading: boolean
  onChoosePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onConfirmPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SignUp = ({
  authError,
  authNotification,
  choosePassword,
  confirmPassword,
  disabledEmail,
  email,
  handleContinueClick,
  handleLoginStateChange,
  loading,
  onChoosePasswordChange,
  onConfirmPasswordChange,
  onEmailChange,
}: SignUpProps) => {
  return (
    <LoginContent>
      <LoginHeader>
        <PageModalHeaderText>Sign up</PageModalHeaderText>
      </LoginHeader>
      <LoginForm>
        {!!authNotification && (
          <AuthNotification authNotification={authNotification} />
        )}
        <LoginFields>
          <EmailField
            disabled={disabledEmail}
            email={email}
            onEmailChange={onEmailChange}
          />
          <LoginPasswordField
            label="Password"
            onChange={onChoosePasswordChange}
            // Firebase is not currently configured for password complexity
            //helperText="Use 8 or more characters with a mix of letters, numbers & symbols."
            value={choosePassword}
          />
          <Stack>
            <LoginPasswordField
              label="Confirm Password"
              onChange={onConfirmPasswordChange}
              value={confirmPassword}
            />
            {!!authError && <AuthError error={authError} />}
          </Stack>
        </LoginFields>

        <Typography
          sx={{ color: (theme) => theme.palette.grey[500], width: INPUT_WIDTH }}
          variant="caption"
        >
          By clicking Sign Up, you agree to our{' '}
          <Link
            href="https://app.termly.io/document/terms-of-service/29af4708-d94e-4fef-b0c3-f5de4a47e03d"
            rel="noreferrer"
            target="_blank"
            underline="none"
            variant="caption"
          >
            Terms
          </Link>
          ,{' '}
          <Link
            href="https://app.termly.io/document/privacy-policy/9e1a0070-1423-4c36-8a2d-745ea1ad6aa7"
            rel="noreferrer"
            target="_blank"
            underline="none"
            variant="caption"
          >
            Privacy Policy
          </Link>
          ,{' '}
          <Link
            href="https://app.termly.io/document/cookie-policy/dfaf7524-48d8-462f-a082-1cd1ba8da724"
            rel="noreferrer"
            target="_blank"
            underline="none"
            variant="caption"
          >
            Cookies Policy
          </Link>{' '}
          and{' '}
          <Link
            href="https://app.termly.io/document/acceptable-use/859621e6-6c19-4bfe-9bad-e091dfb265a4"
            rel="noreferrer"
            target="_blank"
            underline="none"
            variant="caption"
          >
            Acceptable Use Policy
          </Link>
          .
        </Typography>
        <DialogButton
          disabled={loading}
          onClick={handleContinueClick}
          variant="contained"
        >
          Sign up
        </DialogButton>
        <Stack alignItems="center">
          <SecondaryLoginButton
            label="Sign In"
            onClick={() => handleLoginStateChange(LoginState.SignIn)}
          />
        </Stack>
      </LoginForm>
    </LoginContent>
  )
}

export default SignUp
