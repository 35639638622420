import { Typography, type TypographyProps } from '@mui/material'

type CallInsightProps = {
  count: number
  selected?: boolean
  title: string
} & Omit<TypographyProps, 'variant'>

const CallInsightChip = ({
  count,
  selected,
  sx,
  title,
  ...rest
}: CallInsightProps) => (
  <Typography
    sx={{
      backgroundColor: selected ? 'primary.main' : 'transparent',
      border: (theme) => `1px solid  ${theme.palette.grey['50']}`,
      borderRadius: 3,
      color: (theme) =>
        selected
          ? theme.palette.getContrastText(theme.palette.primary.main)
          : theme.palette.grey['800'],
      px: 1.5,
      py: 0.25,
      ...sx,
    }}
    variant="caption"
    {...rest}
  >{`${title} (${count})`}</Typography>
)

export default CallInsightChip
