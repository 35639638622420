import { Typography } from '@mui/material'

import NotificationSectionGroup from './NotificationSectionGroup'

type NotificationCallSummaryProps = {
  summary: string
}
const NotificationCallSummary = ({ summary }: NotificationCallSummaryProps) => {
  return (
    <NotificationSectionGroup title="Call Summary">
      <Typography color={(theme) => theme.palette.grey[500]} variant="body2">
        {summary}
      </Typography>
    </NotificationSectionGroup>
  )
}

export default NotificationCallSummary
