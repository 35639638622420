import { useContext, useEffect } from 'react'
import {
  Navigate,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import type { OrganizationWithIntegrations } from '@/models/hasura/OrganizationWithIntegrations'

import { AuthContext } from '../context/AuthContext'
import LoadingPage from '../pages/LoadingPage'

export const ProtectedRoutes = () => {
  const organization = useLoaderData() as OrganizationWithIntegrations

  const location = useLocation()
  const navigate = useNavigate()
  const { isAuth, isUserLoading, logout, profile } = useContext(AuthContext)

  const belongsToOrganization = profile?.organizationId === organization?.id

  useEffect(() => {
    if (!isUserLoading && (!organization || !belongsToOrganization)) {
      ;(async () => {
        await logout()
        navigate('/')
      })()
    }
  }, [isUserLoading, organization, logout, navigate, belongsToOrganization])

  // race condition, user has not been initialized yet
  if (isUserLoading || !organization || !belongsToOrganization) {
    return <LoadingPage />
  }

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate replace state={{ from: location }} to="/login" />
  )
}
