import { Box, Button, Link, Stack, type SxProps } from '@mui/material'

import NotificationCallKeywords from './NotificationCallKeywords'
import NotificationCallTopRecent from './NotificationCallTopRecent'
import NotificationDivider from './NotificationDivider'
import NotificationHeader from './NotificationHeader'
import NotificationCallRatingOutRange from './content/NotificationCallRatingOutRange'
import useNotification from './useNotification'
import WithLoader from '../UIKit/WithLoader/WithLoader'

const SPACING = 3
type NotificationProps = {
  id: string
  sx?: SxProps
}

const contentByType = {
  agent_call_rating_low: ({ notification }: { notification: TODO }) => (
    <NotificationCallRatingOutRange notification={notification} />
  ),
}
const footerByType = {
  agent_call_rating_low: ({
    notification: _notification,
  }: {
    notification: TODO
  }) => (
    <Box>
      <Button LinkComponent={Link} href="/" sx={{ px: 3 }} variant="contained">
        View full summary
      </Button>
    </Box>
  ),
}

const Notification = ({ id, sx }: NotificationProps) => {
  const { data, error, isLoading, title } = useNotification(id)
  const divider = <NotificationDivider />

  return (
    <WithLoader error={error} isLoading={isLoading}>
      {() => (
        <Stack spacing={SPACING} sx={sx}>
          <NotificationHeader date={data!.date} title={title} />
          {divider}
          <Stack flex="1" overflow="auto" pb={SPACING} spacing={SPACING}>
            {contentByType[data.type]({ notification: data })}
            <NotificationCallKeywords
              keywords={data.keywords}
              objections={data.keywords}
            />
            <NotificationCallTopRecent title="Top 3 Calls" />
            <NotificationCallTopRecent title="Bottom 3 Calls" />
            {footerByType[data.type]({ notification: data })}
          </Stack>
        </Stack>
      )}
    </WithLoader>
  )
}

export default Notification
