import { FormHelperText } from '@mui/material'

import { INPUT_WIDTH } from './consts'

type AuthErrorProps = {
  error?: string
}

const AuthError = ({ error }: AuthErrorProps) => {
  return (
    <FormHelperText error sx={{ pt: 0.5, px: 2, width: INPUT_WIDTH }}>
      {error}
    </FormHelperText>
  )
}

export default AuthError
