import { Button } from '@mui/material'

type SecondaryLoginButtonProps = {
  label: string
  onClick: () => void
}

const SecondaryLoginButton = ({
  label,
  onClick,
}: SecondaryLoginButtonProps) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        fontSize: 12,

        fontWeight: 400,

        lineHeight: '18px',
        //color: '#BAD4FF',
        textTransform: 'none',
        width: 'fit-content',
      }}
    >
      {label}
    </Button>
  )
}

export default SecondaryLoginButton
