import { alpha } from '@mui/material'

import tertiary from './palette/colors/tertiary'

export const OPACITY_BACKGROUND_BOX_SHADOW =
  '0px 4px 12px 0px rgba(206, 206, 206, 0.25)'

export const OPACITY_BACKGROUND_BACKDROP_FILTER = 'blur(4px)'

export const CONTENT_BACKGROUND_BOX_SHADOW =
  '0px 6px 10px 0px rgba(77, 77, 77, 0.06)'

export const FILTER_BOX_SHADOW = CONTENT_BACKGROUND_BOX_SHADOW
export const CHIP_BOX_SHADOW = CONTENT_BACKGROUND_BOX_SHADOW
export const SELECT_BOX_SHADOW = CONTENT_BACKGROUND_BOX_SHADOW
export const BUTTON_CONTAINED_HOVERED_BOX_SHADOW = `0px 1px 2px 0px ${alpha(
  tertiary[800],
  0.3
)}, 0px 1px 3px 1px ${alpha(tertiary[800], 0.15)}`

export const MENU_BOX_SHADOW = '0px 3px 20px 0px rgba(69, 69, 85, 0.24);'

export const TOOLTIP_BACKGROUND_BOX_SHADOW =
  '0px 4px 12px 0px rgba(50, 69, 88, 0.25)'
