// External tooltip implemented according to official sample from https://www.chartjs.org/docs/latest/samples/tooltip/html.html
import { GlobalStyles } from '@mui/material'
import type { Chart, ChartType, TooltipModel, TooltipOptions } from 'chart.js'

import {
  TOOLTIP_BACKDROP_FILTER,
  TOOLTIP_BACKGROUND_LIGHT,
  TOOLTIP_TEXT_COLOR_DARK,
} from '../../theme/components/Tooltip'
import { TOOLTIP_BACKGROUND_BOX_SHADOW } from '../../theme/elevation'

export const TOOLTIP_CLASS = 'chartjs-tooltip'
export const TOOLTIP_ALIGN_RIGHT_CLASS = 'chartjs-tooltip--align-right'
export const TOOLTIP_ALIGN_LEFT_CLASS = 'chartjs-tooltip--align-left'
export const TOOLTIP_TABLE_CLASS = 'chartjs-tooltip__table'
export const TOOLTIP_TR_CLASS = 'chartjs-tooltip__table-row'
export const TOOLTIP_TR_HEADER_CLASS = 'chartjs-tooltip__table-header-row'
export const TOOLTIP_TH_CLASS = 'chartjs-tooltip__table-th'
export const TOOLTIP_TD_CLASS = 'chartjs-tooltip__table-td'
export const TOOLTIP_BACKGROUND_CLASS = 'chartjs-tooltip__background'
export const TOOLTIP_LABEL_ICON_CLASS = 'chartjs-tooltip__table-label-icon'

const tooltipMap = new WeakMap<HTMLCanvasElement, HTMLElement>()

const ALIGN_OFFSET = 20

export const TooltipGlobalStyle = () => {
  return (
    <GlobalStyles
      styles={{
        [`.${TOOLTIP_BACKGROUND_CLASS}`]: {
          backdropFilter: TOOLTIP_BACKDROP_FILTER,
          backgroundColor: TOOLTIP_BACKGROUND_LIGHT,
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: -1,
        },
        [`.${TOOLTIP_CLASS}`]: {
          borderRadius: 16,
          boxShadow: TOOLTIP_BACKGROUND_BOX_SHADOW,
          color: TOOLTIP_TEXT_COLOR_DARK,
          fontSize: 10,
          left: 0,
          lineHeight: '12px',
          minWidth: 200,
          opacity: 1,
          overflow: 'hidden',
          padding: 16,
          pointerEvents: 'none',
          position: 'fixed',
          top: 0,
          transform:
            'translate(calc(-50% + var(--tooltip-x, 0px)), var(--tooltip-y, 0px))',
          transition: 'all 200ms ease',
        },
        [`.${TOOLTIP_ALIGN_LEFT_CLASS}`]: {
          transform: `translate(calc(-100% - ${ALIGN_OFFSET}px + var(--tooltip-x, 0px)), var(--tooltip-y, 0px))`,
        },
        [`.${TOOLTIP_ALIGN_RIGHT_CLASS}`]: {
          transform: `translate(calc(${ALIGN_OFFSET}px + var(--tooltip-x, 0px)), var(--tooltip-y, 0px))`,
        },
        [`.${TOOLTIP_TABLE_CLASS}`]: {
          borderCollapse: 'collapse',
          width: '100%',
        },
        [`.${TOOLTIP_TR_CLASS}`]: {
          backgroundColor: 'inherit',
          borderWidth: 0,
        },
        [`.${TOOLTIP_TR_HEADER_CLASS}`]: {
          borderWidth: 0,
          textAlign: 'left',
        },
        [`.${TOOLTIP_TH_CLASS}`]: {
          borderWidth: 0,
          fontWeight: 400,
          paddingBottom: '8px',
        },
        [`.${TOOLTIP_TD_CLASS}`]: {
          borderWidth: 0,
          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          '&:last-of-type:not(:first-of-type)': {
            textAlign: 'right',
          },
        },
        [`.${TOOLTIP_LABEL_ICON_CLASS}`]: {
          background: 'inherit',
          borderColor: 'inherit',
          borderRadius: '50%',
          borderWidth: 2,
          display: 'inline-block',
          height: 8,
          marginRight: 4,
          width: 8,
        },
      }}
    />
  )
}

export type TooltipAlign = 'left' | 'right' | 'center'
type GetTooltipBoundariesParams = {
  align: TooltipAlign
  chart: Chart
  tooltip: TooltipModel<ChartType>
  tooltipEl: HTMLElement
}
const getTooltipBoundaries = ({
  align,
  chart,
  tooltip,
  tooltipEl,
}: GetTooltipBoundariesParams) => {
  const position = chart.canvas.getBoundingClientRect()

  let top = position.top + tooltip.caretY
  let left = position.left + window.scrollX + tooltip.caretX

  const bottom = top + tooltipEl.offsetHeight
  if (bottom > window.innerHeight) {
    top -= bottom - window.innerHeight
  }
  if (top < 0) {
    top = 0
  }

  const leftTransform =
    align === 'center'
      ? tooltipEl.offsetWidth / 2
      : align === 'left'
        ? tooltipEl.offsetWidth + ALIGN_OFFSET
        : -ALIGN_OFFSET

  const leftAfterTransform = left - leftTransform
  const rightAfterTransform = leftAfterTransform + tooltipEl.offsetWidth

  if (rightAfterTransform > window.innerWidth) {
    left -= rightAfterTransform - window.innerWidth
  }
  if (leftAfterTransform < 0) {
    left += Math.abs(leftAfterTransform)
  }

  return { left, top }
}
const getOrCreateTooltip = ({
  align,
  chart,
  tooltip,
}: {
  align: TooltipAlign
  chart: Chart
  tooltip: TooltipModel<ChartType>
}) => {
  let tooltipEl = tooltipMap.get(chart.canvas)

  const setPosition = (element: HTMLElement) => {
    const { left, top } = getTooltipBoundaries({
      align,
      chart,
      tooltip,
      tooltipEl: element,
    })
    element.style.setProperty('--tooltip-x', `${left}px`)
    element.style.setProperty('--tooltip-y', `${top}px`)
  }
  if (tooltipEl) {
    setPosition(tooltipEl)
  } else {
    tooltipEl = document.createElement('div')
    tooltipEl.classList.add(TOOLTIP_CLASS)
    if (align === 'right') tooltipEl.classList.add(TOOLTIP_ALIGN_RIGHT_CLASS)
    if (align === 'left') {
      tooltipEl.classList.add(TOOLTIP_ALIGN_LEFT_CLASS)
    }

    const table = document.createElement('table')
    table.classList.add(TOOLTIP_TABLE_CLASS)

    const bg = document.createElement('div')
    bg.classList.add(TOOLTIP_BACKGROUND_CLASS)

    tooltipEl.appendChild(table)
    tooltipEl.appendChild(bg)
    // set position before appending to body to avoid initial transition
    setPosition(tooltipEl)
    document.body.appendChild(tooltipEl)
    tooltipMap.set(chart.canvas, tooltipEl)
  }

  return tooltipEl
}

type CreateExternalTooltipHandlerOptions = {
  align?: TooltipAlign
  ref: React.MutableRefObject<HTMLElement | null>
}
export const createExternalTooltipHandler: (
  options: CreateExternalTooltipHandlerOptions
) => TooltipOptions['external'] = (options) => (context) => {
  // Tooltip Element
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip({
    align: options.align || 'center',
    chart,
    tooltip,
  })
  options.ref.current = tooltipEl

  // hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0'
    return
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || []
    const bodyLines = tooltip.body.map((b) => b.lines)

    const combinedBodyLines =
      tooltip.body?.reduce<string[][]>((acc, item) => {
        return [...acc, [...item.before, ...item.lines, ...item.after]]
      }, []) ?? []

    const tableHead = document.createElement('thead')
    const cells = Math.max(...combinedBodyLines.map((line) => line.length))

    titleLines.forEach((title: string) => {
      const tr = document.createElement('tr')
      tr.classList.add(TOOLTIP_TR_HEADER_CLASS)
      tr.classList.add(TOOLTIP_TR_CLASS)

      const th = document.createElement('th')
      th.classList.add(TOOLTIP_TH_CLASS)
      th.classList.add(TOOLTIP_TD_CLASS)
      const text = document.createTextNode(title)

      th.appendChild(text)
      tr.appendChild(th)
      // generate rest headers, leave empty
      for (let i = 1; i < cells; i++) {
        const th = document.createElement('th')
        th.classList.add(TOOLTIP_TH_CLASS)
        th.classList.add(TOOLTIP_TD_CLASS)
        tr.appendChild(th)
      }
      tableHead.appendChild(tr)
    })

    const tableBody = document.createElement('tbody')
    bodyLines.forEach((body: any, i: number) => {
      const colors = tooltip.labelColors[i]!

      const span = document.createElement('span')
      span.style.background = colors.backgroundColor.toString()
      span.style.borderColor = colors.borderColor.toString()
      span.classList.add(TOOLTIP_LABEL_ICON_CLASS)

      const tr = document.createElement('tr')
      tr.classList.add(TOOLTIP_TR_CLASS)

      for (let c = 0; c < cells; c++) {
        const td = document.createElement('td')
        td.classList.add(TOOLTIP_TD_CLASS)
        if (c === 0) {
          td.appendChild(span)
        }
        const text = document.createTextNode(combinedBodyLines?.[i]?.[c] || '')
        td.appendChild(text)
        tr.appendChild(td)
      }

      tableBody.appendChild(tr)
    })

    const tableRoot = tooltipEl.querySelector('table')!

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove()
    }

    // Add new children
    tableRoot.appendChild(tableHead)
    tableRoot.appendChild(tableBody)
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1'

  if ('string' in tooltip.options.bodyFont) {
    tooltipEl.style.font = tooltip.options.bodyFont.string as string
  }
  tooltipEl.style.padding =
    tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
}
