import {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  type PropsWithChildren,
} from 'react'
import {
  useLocation,
  useMatches,
  useNavigate,
  type UIMatch,
} from 'react-router-dom'

import type { SlugsType } from '../modules/navigation/slugs'

type RouteHandle = {
  name?: SlugsType
  title?: string
}

type Route = {
  pathname: string
} & RouteHandle

type HistoryTrackContextType = {
  back: (fallback: string) => void
  lastRoute: Route | undefined
  navigate: ReturnType<typeof useNavigate>
  pop: () => void
}

const HistoryTrackContext = createContext<HistoryTrackContextType>({
  back: () => {},
  lastRoute: undefined,
  navigate: () => {},
  pop: () => {},
})

type RouteMatch = UIMatch<any, { name?: SlugsType; title?: string } | undefined>

const getLastRouteMatch = (matches: RouteMatch[]): Route | undefined => {
  const lastMatch = matches[matches.length - 1]
  return lastMatch
    ? {
        name: lastMatch.handle?.name,
        pathname: lastMatch.pathname,
        title: lastMatch.handle?.title,
      }
    : undefined
}

const HistoryTrackProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()

  const location = useLocation()
  const matches = useMatches() as RouteMatch[]

  const [track, setTrack] = useState<Route[]>([])

  const shouldSkipNextRoute = useRef(false)

  const lastRoute = useMemo(() => {
    return track[track.length - 1]
  }, [track])

  const prevLocationRef = useRef(location)
  const prevMatchesRef = useRef(getLastRouteMatch(matches))

  useLayoutEffect(() => {
    if (prevLocationRef.current !== location) {
      if (!shouldSkipNextRoute.current) {
        const lastRoute = {
          name: prevMatchesRef.current?.name,
          pathname: prevLocationRef.current.pathname,
          title: prevMatchesRef.current?.title,
        }
        setTrack((current) => [...current, lastRoute])
      }

      prevLocationRef.current = location
      prevMatchesRef.current = getLastRouteMatch(matches)
      shouldSkipNextRoute.current = false
    }
  }, [location, matches])

  const pop = () => {
    shouldSkipNextRoute.current = true
    setTrack((current) => current.slice(0, -1))
  }

  const back = (fallback: string) => {
    pop()
    navigate(lastRoute?.pathname || fallback)
  }
  return (
    <HistoryTrackContext.Provider value={{ back, lastRoute, navigate, pop }}>
      {children}
    </HistoryTrackContext.Provider>
  )
}

export const useHistoryTrack = () => useContext(HistoryTrackContext)

export default HistoryTrackProvider
