import dayjs from 'dayjs'

import { plural } from './string'

export const formatTime = (value: number) => {
  return value >= 10 ? value.toString() : `0${value}`
}

export const formatDate = (value: string) => {
  return dayjs(value).format('MMMM D, YYYY')
}

export const getTimestamp = (timestamp: number) => {
  const seconds = Math.floor((timestamp / 1000) % 60)
  const minutes = Math.floor(timestamp / 1000 / 60)

  const secondsString = formatTime(seconds)
  const minutesString = formatTime(minutes)

  return `${minutesString}:${secondsString}`
}

export const getTimestampInMinSec = (timestamp: number) => {
  const seconds = Math.floor((timestamp / 1000) % 60)
  const minutes = Math.floor(timestamp / 1000 / 60)

  return minutes < 1 ? plural(seconds, 'sec') : plural(minutes, 'min')
}

export const doRangesOverlap = (
  x1: number,
  x2: number,
  y1: number,
  y2: number
) => {
  return (
    (x1 >= y1 && x1 <= y2) ||
    (x2 >= y1 && x2 <= y2) ||
    (y1 >= x1 && y1 <= x2) ||
    (y2 >= x1 && y2 <= x2)
  )
}

export const getHoursFromSeconds = (value: number, decimalPlaces = 1) => {
  const hours = value / 60 / 60
  return Number(hours.toFixed(decimalPlaces))
}

export const getDurationInSeconds = (seconds?: number) => {
  if (!seconds) {
    return '0:00'
  }
  let hours = Math.floor(seconds / 60 / 60).toString()
  if (hours === '0') {
    hours = ''
  }
  const minutes = Math.floor(seconds / 60) % 60
  const remainingSeconds = seconds % 60
  let displayString = ''
  if (hours) {
    displayString += `${hours}:`
  }
  if (hours && minutes < 10) {
    displayString += `0${minutes}:`
  } else {
    displayString += `${minutes}:`
  }
  if (remainingSeconds < 10) {
    displayString += `0${remainingSeconds}`
  } else if (remainingSeconds >= 10) {
    displayString += `${remainingSeconds}`
  }
  return displayString
}
