import { Stack, type SvgIconTypeMap } from '@mui/material'
import type { OverridableComponent } from '@mui/material/OverridableComponent'
import { findIndex, map } from 'lodash'
import { useLocation, useParams } from 'react-router-dom'

import SideMenuItem from './SideMenuItem'
import CallFilledIcon from '../../../assets/icons/call-filled-two-tone-icon.svg?react'
import CallOutlinedIcon from '../../../assets/icons/call-outlined-icon.svg?react'
import HomeFilledIcon from '../../../assets/icons/home-filled-two-tone-icon.svg?react'
import HomeOutlinedIcon from '../../../assets/icons/home-outlined-icon.svg?react'
import PeopleFilledIcon from '../../../assets/icons/people-filled-two-tone-icon.svg?react'
import PeopleOutlinedIcon from '../../../assets/icons/people-outlined-icon.svg?react'
import SettingsFilledIcon from '../../../assets/icons/settings-filled-two-tone-icon.svg?react'
import SettingsOutlinedIcon from '../../../assets/icons/settings-outlined-icon.svg?react'
import useFiltersCache from '../../../hooks/filters/useFiltersCache'
import useAccessControl from '../../../hooks/useAccessControl'
import { getPaginationCacheKey } from '../../../hooks/usePagination'
import { Slugs } from '../../../modules/navigation/slugs'

const ITEMS: Record<
  Slugs,
  {
    ActiveIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
    label: string
    slug: string
  }
> = {
  [Slugs.Dashboard]: {
    ActiveIcon: HomeFilledIcon,
    Icon: HomeOutlinedIcon,
    label: 'home',
    slug: Slugs.Dashboard,
  },
  [Slugs.Calls]: {
    ActiveIcon: CallFilledIcon,
    Icon: CallOutlinedIcon,
    label: 'calls',
    slug: Slugs.Calls,
  },
  [Slugs.Agents]: {
    ActiveIcon: PeopleFilledIcon,
    Icon: PeopleOutlinedIcon,
    label: 'agents',
    slug: Slugs.Agents,
  },
  [Slugs.Settings]: {
    ActiveIcon: SettingsFilledIcon,
    Icon: SettingsOutlinedIcon,
    label: 'settings',
    slug: Slugs.Settings,
  },
}

const toURL = (organizationId: string, slug: string) =>
  `/organization/${organizationId}/${slug}`
const SideMenu = () => {
  const cacheControl = useFiltersCache('sidemenu')
  const { organizationId: organizationIdParam } = useParams()
  const { pathname } = useLocation()

  const { isMember, profile } = useAccessControl()
  const isMemberWithAgentId = isMember && profile?.agentId

  const organizationId = organizationIdParam || profile?.organizationId

  const items = [
    ITEMS[Slugs.Dashboard],
    ITEMS[Slugs.Calls],
    {
      ...ITEMS[Slugs.Agents],
      slug: isMemberWithAgentId
        ? `${Slugs.Agents}/${profile!.agentId!}`
        : Slugs.Agents,
    },
    ITEMS[Slugs.Settings],
  ]
  const getActiveIndex = () => {
    return findIndex(items, (item) => pathname.includes(item.slug))
  }

  const activeIndex = getActiveIndex()

  const onItemClick = (slug: string) => {
    cacheControl.reset(getPaginationCacheKey(slug))
  }
  return (
    <Stack spacing={3}>
      {map(items, (item, index) => {
        return (
          <SideMenuItem
            active={activeIndex === index}
            key={item.slug}
            onClick={onItemClick}
            to={toURL(organizationId!, item.slug)}
            {...item}
          />
        )
      })}
    </Stack>
  )
}

export default SideMenu
