import { InvitationRole } from '@/modules/user/invitation'

export enum InvitationStatus {
  accepted = 'accepted',
  cancelled = 'cancelled',
  pending = 'pending',
  rejected = 'rejected',
}

export type Invitation = {
  acceptedDate?: string | null
  agentId: string
  cancelledBy?: string | null
  cancelledDate?: string | null
  createdDate?: string | null
  email: string
  expiresDate?: string | null
  firstName: string | null
  id: string
  integrationId?: string
  invitedBy: string
  modifiedBy?: string | null
  name?: string | null
  organizationId: string
  picture?: string | null
  role: InvitationRole
  status: InvitationStatus
  updatedDate?: string | null
}
type HasuraInvitation = DeepCamelToSnakeCase<Invitation>
export type InsertInvitationInput = Omit<HasuraInvitation, 'id' | 'status'>
export type UpdateInvitationInput = Partial<HasuraInvitation> &
  Pick<HasuraInvitation, 'id'>
