import FirebaseLogin from '../components/Login/FirebaseLogin'
import LoginPage from '../components/UIKit/LoginPage/LoginPage'

const FirebasePage = () => {
  return (
    <LoginPage>
      <FirebaseLogin />
    </LoginPage>
  )
}

export default FirebasePage
