import { shiloApi } from '../clients/shilo'

export const shiloAuthAPI = shiloApi.injectEndpoints({
  endpoints: (builder) => ({
    impersonate: builder.mutation<
      { token: string },
      { email: string; id: string; password: string }
    >({
      query: (body) => ({
        url: '/impersonate',
        body,
        method: 'POST',
      }),
    }),
  }),
})

export const { useImpersonateMutation } = shiloAuthAPI
