import { Middleware } from '@reduxjs/toolkit'

import { toastSlice } from '@/app/features/toast/toastSlice'

const endpointsMessagesMap = {
  updateUser: 'Your profile information has been updated',
  updateIntegration: 'Integration settings have been updated',
  updateAgentPrefs: 'Agent preferences have been updated',
  updateStagePrefs: 'Stage preferences have been updated',
}

type EndpointName = keyof typeof endpointsMessagesMap

const toastMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const endpointName = (action as any)?.meta?.arg?.endpointName as
    | EndpointName
    | undefined
  const actionType = (action as any)?.type as string | undefined

  if (endpointName && actionType && actionType.includes('/fulfilled')) {
    const endpoints = [
      'updateUser',
      'updateIntegration',
      'updateAgentPrefs',
      'updateStagePrefs',
    ]
    if (endpoints.includes(endpointName)) {
      storeAPI.dispatch(
        toastSlice.actions.open({ message: endpointsMessagesMap[endpointName] })
      )
    }
  }

  const result = next(action)

  return result
}

export default toastMiddleware
