import type { Theme, SxProps } from '@mui/material'

type PureSx<T extends object> = Exclude<SxProps<T>, ReadonlyArray<unknown>>
type SxAsArray<T extends object> = Array<PureSx<T>>

type AnyStyle<T extends Theme = Theme> = false | SxProps<T> | undefined | ''
const combineSx = <T extends Theme = Theme>(styles: AnyStyle<T>[]) => {
  const combined: SxAsArray<T> = []
  for (const sx of styles) {
    if (sx) {
      if (Array.isArray(sx)) {
        for (const sub of sx as SxAsArray<T>) {
          combined.push(sub)
        }
      } else {
        combined.push(sx as PureSx<T>)
      }
    }
  }
  return combined
}

export const mergeSx = <T extends Theme = Theme>(
  ...styles: AnyStyle<T>[]
): SxProps<T> => {
  return combineSx(styles)
}

export const mergeSxAsObject = <T extends Theme = Theme>(
  ...styles: AnyStyle<T>[]
) => {
  const combined = combineSx(styles)
  return combined.reduce((acc, sx) => ({ ...acc, ...sx }), {})
}
