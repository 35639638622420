import { useRef } from 'react'
import { useMatch } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

import NotificationPopover from '@/components/Notifications/NotificationPopover'

import BellFilledIcon from '../../../assets/icons/bell-filled-icon.svg?react'
import BellOutlinedIcon from '../../../assets/icons/bell-outlined-icon.svg?react'
import SideMenuItem from '../SideMenu/SideMenuItem'

const NotificationsButton = () => {
  const { setFalse: close, setTrue: open, value: isOpen } = useBoolean()
  const anchorRef = useRef<HTMLDivElement>(null)

  const routeMatch = useMatch('/organization/:organizationId/notifications/*')

  return (
    <>
      <SideMenuItem
        ActiveIcon={BellFilledIcon}
        Icon={BellOutlinedIcon}
        active={!!routeMatch || isOpen}
        key="notifications"
        label="Notifications"
        onClick={open}
        ref={anchorRef}
        slug="notifications"
      />
      <NotificationPopover
        anchorRef={anchorRef}
        close={close}
        isOpen={isOpen}
      />
    </>
  )
}

export default NotificationsButton
