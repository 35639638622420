// TODO provide real data based on notification type
// different type requires different data
const useNotification = (id: string) => {
  const { data, error, isLoading } = {
    isLoading: false,
    data: {
      id,
      rating: 3,
      type: 'agent_call_rating_low',
      target_rating: 4,
      duration: 145,
      talking: 145,
      fillerWords: 5,
      incoming: true,
      listening: 55,
      monologues: 10,
      questionsAsked: 3,
      personName: 'Jackie Lander',
      date: new Date().toISOString(),
      outcomes: ['Outcome1', 'Outcome2', 'Outcome3', 'Outcome4', 'Outcome5'],
      topMoments: ['Top Moment 1', 'Top Moment 2', 'Top Moment 3'],
      additionalItems: [],
      mostImportant: ['Most Important 1', 'Most Important 2'],
      keywords: [
        { title: 'Location', count: 3 },
        { title: 'Price', count: 2 },
        { title: 'Financing', count: 1 },
        { title: 'Market', count: 1 },
        { title: 'Property Condition', count: 1 },
      ],
      topPossibleImprovements: [
        'Top Possible Improvement 1',
        'Top Possible Improvement 2',
        'Top Possible Improvement 3',
      ],
      summary:
        'Jackie called Frank to let him know she signed the paperwork and was unable to locate the home inspection report from when she originally purchased the house three years ago. She confirmed this was okay and that not having the old report would not be an issue. Jackie mentioned the only major item flagged during the recent inspection was an outdated garage outlet, which has since been replaced. Otherwise, the inspector noted only minor cosmetic issues that are common in older homes. Jackie was pleased with the positive inspection results. She plans to list the house for sale this week once photos are complete and will keep Frank updated on the listing status.',
    },
    error: null,
  } as const

  const title = (() => {
    if (data.type === 'agent_call_rating_low') {
      return `[Agent Name]: Call Rating Below ${data.target_rating}`
    }
    return 'Notification'
  })()

  return { data, error, isLoading, title }
}

export default useNotification
