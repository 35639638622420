import { Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'

import { useAuth } from '@/context/AuthContext'

type NotificationCallGreetingsProps = {
  date: string
  personName: string
}
const NotificationCallGreetings = ({
  date,
  personName,
}: NotificationCallGreetingsProps) => {
  const { profile } = useAuth()
  return (
    <Stack spacing={3}>
      <Typography variant="body1">Hi {profile?.name} 👋</Typography>
      <Typography variant="body1">
        Here is an overview of your {personName}’s call on{' '}
        {dayjs(date).format('MMMM DD, YYYY [at] h:mma')}.
      </Typography>
    </Stack>
  )
}

export default NotificationCallGreetings
