import { forwardRef } from 'react'
import {
  PaletteColor,
  styled,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material/styles'
import clsx from 'clsx'

import { BoxIconName } from '@/components/Common/BoxIcon/types'
import { mergeSx } from '@/modules/sx/mergeSx'
import { LiteralStringUnion } from '@/typescript/typescript'

type ColorProp = LiteralStringUnion<keyof Theme['palette']>
export type BoxIconProps = {
  animation?:
    | 'spin'
    | 'tada'
    | 'flashing'
    | 'burst'
    | 'fade-left'
    | 'fade-right'
    | 'spin-hover'
    | 'tada-hover'
    | 'flashing-hover'
    | 'burst-hover'
    | 'fade-left-hover'
    | 'fade-right-hover'

  'animation-duration'?: number
  'animation-iteration-count'?: number
  border?: 'square' | 'circle'
  className?: string
  color?: ColorProp

  flip?: 'horizontal' | 'vertical'
  name: BoxIconName
  pull?: 'left' | 'right'
  rotate?: 90 | 180 | 270
  // xs: 16, sm 24, md 36, lg 48
  size?: LiteralStringUnion<'xs' | 'sm' | 'md' | 'lg'>
  sx?: SxProps
  type?: 'regular' | 'solid' | 'logo'
}

const BoxIconStyled = styled('i')(({ sx }) => {
  return {
    ...sx,
  }
})

const BoxIcon = forwardRef<HTMLDivElement, BoxIconProps>(
  (
    {
      animation,
      border,
      className,
      color,
      flip,
      name,
      pull,
      rotate,
      size,
      sx,
      type = 'regular',
      ...props
    },
    ref
  ) => {
    const theme = useTheme()
    const namePrefix =
      type === 'regular' ? 'bx' : type === 'solid' ? 'bxs' : 'bxl'
    const isStandardSize = size && ['xs', 'sm', 'md', 'lg'].includes(size)

    const cn = clsx(
      'bx',
      `${namePrefix}-${name}`,
      type && `bx-${type}`,
      animation && `bx-${animation}`,
      border && `bx-border${border === 'circle' ? '-circle' : ''}`,
      flip && `bx-flip-${flip}`,
      pull && `bx-pull-${pull}`,
      rotate && `bx-rotate-${rotate}`,
      isStandardSize && `bx-${size}`,
      className
    )

    const getColor = (colorProp: ColorProp | undefined) => {
      if (!colorProp) return undefined
      const paletteColor =
        theme.palette[colorProp as keyof typeof theme.palette]
      if (
        paletteColor &&
        typeof paletteColor === 'object' &&
        'main' in paletteColor
      ) {
        return (paletteColor as PaletteColor).main
      }
      return colorProp
    }

    return (
      <BoxIconStyled
        className={cn}
        ref={ref}
        {...props}
        sx={mergeSx(
          {
            color: getColor(color),
            fontSize: isStandardSize ? undefined : size,
          },
          sx
        )}
      />
    )
  }
)

export default BoxIcon
