import React from 'react'
import { IconProps, styled } from '@mui/material'
import Box from '@mui/material/Box'

import {
  neonBottomLayerProps,
  neonMiddleLayerProps,
  neonTopLayerProps,
} from './common'
import useHover from '../../components/UIKit/ListItem/useHover'

// mui does not currently have an alias for this
type FontSizeProp = 'inherit' | 'large' | 'medium' | 'small'

type NeonIconFontSizeProp = number | FontSizeProp

const getFontSizeNumber = (fontSize: NeonIconFontSizeProp) => {
  // TODO make a TS map
  if (fontSize === 'small') {
    return 20
    // TODO size="inherit" has incorrect logic
  } else if (fontSize === 'medium' || fontSize === 'inherit') {
    return 24
  } else if (fontSize === 'large') {
    return 35
  } else {
    return fontSize
  }
}

type OwnerState = {
  actionable: boolean
  fontSize: NeonIconFontSizeProp
}

const NeonIconRoot = styled('div')(
  ({ ownerState }: { ownerState: OwnerState }) => ({
    cursor: !ownerState.actionable ? 'inherit' : 'pointer',
    height: ownerState.fontSize,
    name: 'NeonIcon',
    position: 'relative',
    width: ownerState.fontSize,
  })
)

export type NeonIconProps = IconProps & {
  actionable?: boolean
  component: React.ComponentType<any>
  inactive?: boolean
  mediaIgnoreColor?: boolean
  neonComponent?: React.ComponentType<any>
}

const NeonIcon = ({
  actionable = false,
  color = 'inherit',
  component,
  fontSize = 'medium',
  inactive = false,
  mediaIgnoreColor = false,
  neonComponent,
  sx = {},
  ...other
}: NeonIconProps) => {
  // @ts-ignore
  const { color: sxColor, fontSize: sxFontSize, ...restSx } = sx
  const { handleMouseOut, handleMouseOver, hovered } = useHover()
  const fontSizeNumber = getFontSizeNumber(sxFontSize || fontSize)
  const blurFontSize = Math.ceil(fontSizeNumber / 4)
  const hoverMultiplier = 0.5

  const rootOwnerState = {
    actionable,
    fontSize: fontSizeNumber,
  }

  return (
    <NeonIconRoot
      onMouseOut={(event) => actionable && handleMouseOut(event)}
      onMouseOver={(event) => actionable && handleMouseOver(event)}
      ownerState={rootOwnerState}
    >
      <Box
        component={component}
        {...other}
        sx={{
          ...neonTopLayerProps({
            active: !inactive,
            color: sxColor || color,
            mediaIgnoreColor,
          }),

          fontSize: fontSizeNumber,
          ...restSx,
        }}
      />
      <Box
        component={neonComponent ?? component}
        {...other}
        sx={{
          ...neonMiddleLayerProps({ active: !inactive }),
          filter: `blur(${blurFontSize}px)`,
          fontSize: hovered
            ? fontSizeNumber * (1 + hoverMultiplier)
            : fontSizeNumber,
          ...restSx,
          left: hovered ? (-fontSizeNumber * hoverMultiplier) / 2 : 0,
          top: hovered ? (-fontSizeNumber * hoverMultiplier) / 2 : 0,
        }}
      />
      <Box
        component={component}
        {...other}
        sx={{
          ...neonBottomLayerProps({ active: !inactive }),
          fontSize: fontSizeNumber,
          ...restSx,
        }}
      />
    </NeonIconRoot>
  )
}

export default NeonIcon
