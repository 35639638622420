import StarIcon from '@mui/icons-material/Star'
import { Stack } from '@mui/material'

import yellowOrange from '@/theme/palette/colors/yellowOrange'

import NotificationCallOverviewItem from './NotificationCallOverviewItem'
import NotificationSectionGroup from './NotificationSectionGroup'

type NotificationCallRatingProps = {
  rating: number
  ratingTitle: string
  targetRating?: number
  targetRatingTitle?: string
  title?: string
}

const Rating = ({ rating }: { rating: number }) => {
  return (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      <StarIcon sx={{ color: yellowOrange[800], fontSize: 16 }} />
      {rating}
    </Stack>
  )
}
const NotificationCallRating = ({
  rating,
  ratingTitle,
  targetRating,
  targetRatingTitle,
  title = 'Call Rating',
}: NotificationCallRatingProps) => {
  const cardSx = { maxWidth: 1, width: 160 }
  return (
    <NotificationSectionGroup title={title}>
      <Stack direction="row" spacing={2}>
        <NotificationCallOverviewItem
          hasBullet={false}
          label={ratingTitle}
          sx={cardSx}
          title={<Rating rating={rating} />}
        />
        {!!targetRating && (
          <NotificationCallOverviewItem
            hasBullet={false}
            label={targetRatingTitle!}
            sx={cardSx}
            title={<Rating rating={targetRating} />}
          />
        )}
      </Stack>
    </NotificationSectionGroup>
  )
}

export default NotificationCallRating
