import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../../store'

export type FilterCacheValues = Record<string, any>
export type FilterCacheKey = string
export type FiltersCacheState = {
  cache: Record<FilterCacheKey, FilterCacheValues | undefined>
}

const initialState: FiltersCacheState = {
  cache: {},
}
export const filtersCacheSlice = createSlice({
  name: 'filtersCache',
  initialState,
  reducers: {
    setFiltersCache: (
      state,
      action: PayloadAction<{ data: FilterCacheValues; key: FilterCacheKey }>
    ) => {
      state.cache[action.payload.key] = action.payload.data
    },
    resetFiltersCache: (
      state,
      action: PayloadAction<{ key: FilterCacheKey }>
    ) => {
      state.cache[action.payload.key] = undefined
    },
  },
})

export const { resetFiltersCache, setFiltersCache } = filtersCacheSlice.actions
export const useFiltersCacheByKeySelector =
  (key: FilterCacheKey) => (state: RootState) =>
    state.filtersCache.cache[key]
