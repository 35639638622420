// http://mcg.mbitson.com/#!?mcgpalette0=%23c9adce
/*const secondary = {
  '50': '#fffeee',
  '100': '#fefcd4',
  '200': '#fefbb7',
  '300': '#fef99a',
  '400': '#fdf785',
  '500': '#fdf66f',
  '600': '#fdf567',
  '700': '#fcf35c',
  '800': '#fcf252',
  '900': '#fcef40',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#fffde5',
  A700: '#fffbcb',
}*/

// force A<x> colors for dark mode
const secondary = {
  '50': '#fffeee',
  '100': '#fefcd4',
  '200': '#fefbb7',
  '300': '#fef99a',
  '400': '#fdf785',
  '500': '#fdf66f',
  '600': '#fdf567',
  '700': '#fcf35c',
  '800': '#fcf252',
  '900': '#fcef40',
  A100: '#fdf66f',
  A200: '#fdf66f',
  A400: '#fdf66f',
  A700: '#fdf66f',
}

export default secondary
