import { Divider, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'

import Rating from '../Common/Rating/Rating'
import Avatar from '../UIKit/Avatar/Avatar'

const AVATAR_SIZE = 24

type NotificationCallCardProps = {
  agentName: string
  clientName?: string
  date: string
  leadSource?: string
  leadStage?: string
  rating: number
}
const NotificationCallCard = ({
  agentName,
  clientName = 'Unknown',
  date,
  leadSource,
  leadStage,
  rating,
}: React.PropsWithChildren<NotificationCallCardProps>) => {
  const leadSourceText = leadSource ? `Lead Source: ${leadSource}` : ''
  const leadStageText = leadStage ? `Lead Stage: ${leadStage}` : ''
  const hasAnyFooterContent = Boolean(leadSourceText || leadStageText)
  const shoudDivideFooterContent = Boolean(leadSourceText && leadStageText)

  return (
    <Stack
      borderRadius={3}
      p={2}
      sx={{
        boxShadow: (theme) => `inset 0 0 0 1px ${theme.palette.grey[50]}`,
      }}
    >
      <Stack alignItems="center" direction="row" spacing={1}>
        <Avatar size={AVATAR_SIZE} />
        <Typography variant="caption">
          {agentName}'s Call with {clientName}
        </Typography>
        <Typography
          color={(theme) => theme.palette.grey[500]}
          variant="caption"
        >
          {dayjs(date).format('hh:mma [on] MM/DD/YY')}
        </Typography>
        <Rating iconSx={{ fontSize: 14 }} rating={rating} spacing={0.5} />
      </Stack>
      {hasAnyFooterContent && (
        <Stack
          color={(theme) => theme.palette.grey[500]}
          direction="row"
          pl={AVATAR_SIZE / 8 + 1}
          spacing={1}
        >
          {leadSourceText && (
            <Typography variant="body3">{leadSourceText}</Typography>
          )}
          {shoudDivideFooterContent && (
            <Divider
              orientation="vertical"
              sx={{
                borderColor: (theme) => theme.palette.grey[50],
                height: 'auto',
              }}
            />
          )}
          {!!leadStageText && (
            <Typography variant="body3">{leadStageText}</Typography>
          )}
        </Stack>
      )}
    </Stack>
  )
}

export default NotificationCallCard
