import { useEffect } from 'react'
import { Box, Stack, Typography } from '@mui/material'

import { formatDateBasedOnToday } from '@/modules/date/utils'

import { type NotificationVariant } from './NotificationList'
import Bullet from '../Common/Bullet/Bullet'
import SplitViewContainerListItem from '../Common/SplitViewContainer/SplitViewContainerListItem'

type NotificationListItemProps = {
  active: boolean
  notification: TODO
  onClick: (notification: TODO) => void
  variant?: NotificationVariant
}

const NotificationListItem = ({
  active,
  notification,
  onClick,
  variant = 'compact',
}: NotificationListItemProps) => {
  useEffect(() => {
    if (notification.isUnread && active) {
      // TODO update notification read status
      notification.isUnread = false
    }
  }, [notification, active])

  const hasBullet = notification.isUnread

  return (
    <SplitViewContainerListItem
      active={active}
      onClick={() => onClick(notification)}
      sx={{
        borderRadius: variant === 'separated' ? 0 : 2,
        pr: variant === 'separated' ? 3 : 2,
        py: variant === 'separated' ? 2 : 1.5,
      }}
    >
      <Stack
        alignItems="flex-start"
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{ width: '100%' }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{ alignItems: 'center', flexGrow: 1, minWidth: 0 }}
        >
          {variant === 'separated' && hasBullet && (
            <Box sx={{ pr: 1 }}>
              <Bullet color="primary" />
            </Box>
          )}
          <Stack sx={{ minWidth: 0 }}>
            <Stack alignItems="center" direction="row" gap={1}>
              {variant === 'compact' && hasBullet && <Bullet color="primary" />}
              <Typography
                color={(theme) => theme.palette.common.black}
                noWrap
                variant="body2"
              >
                {notification.title}
              </Typography>
            </Stack>
            <Typography
              color={(theme) => theme.palette.grey[500]}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              variant="caption"
            >
              {notification.description}
            </Typography>
          </Stack>
        </Stack>
        <Typography
          color={(theme) => theme.palette.grey[500]}
          sx={{ flexShrink: 0, pl: variant === 'separated' ? 1 : 0 }}
          variant={variant === 'separated' ? 'caption' : 'caption2'}
        >
          {formatDateBasedOnToday(notification.createdAt)}
        </Typography>
      </Stack>
    </SplitViewContainerListItem>
  )
}

export default NotificationListItem
