const LogoTextWhite32 = () => {
  return (
    <svg
      fill="none"
      height="32"
      viewBox="0 0 98 32"
      width="98"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9804 7.99996C16.9804 7.99996 14.047 9.12589 11.1581 10.9778C9.73592 11.8815 8.31366 12.9778 7.2618 14.1926C5.54327 16.1778 4.9211 18.6074 6.80254 21.037C0.713664 19.5556 -5.76041 11.8815 8.98033 0L16.9804 7.99996Z"
        fill="white"
      />
      <path
        d="M10.6989 17.822C9.64704 19.0369 8.22486 20.1183 6.80259 21.0368C4.92115 18.5924 5.54332 16.1776 7.26184 14.1924C8.3137 12.9775 9.73597 11.896 11.1582 10.9775C13.0397 13.4072 12.4322 15.822 10.6989 17.822Z"
        fill="#9F9F9F"
      />
      <path
        d="M8.98043 31.9999L0.980469 24C0.980469 24 3.91377 22.874 6.80265 21.0222C8.22491 20.1036 9.6471 19.0221 10.699 17.8073C12.4175 15.8221 13.0397 13.3925 11.1582 10.9629C17.262 12.4444 23.736 20.1184 8.98043 31.9999Z"
        fill="white"
      />
      <path
        d="M34.5032 27.6901C33.0954 27.6901 31.8284 27.4488 30.7021 26.9661C29.5759 26.4634 28.691 25.7595 28.0474 24.8544C27.4038 23.9494 27.082 22.8936 27.082 21.6868H30.7624C30.8429 22.5919 31.1948 23.336 31.8183 23.9193C32.4618 24.5024 33.3568 24.794 34.5032 24.794C35.6898 24.794 36.6149 24.5125 37.2786 23.9494C37.9422 23.3661 38.2741 22.6221 38.2741 21.717C38.2741 21.0131 38.0629 20.4399 37.6406 19.9975C37.2383 19.555 36.7255 19.2131 36.102 18.9718C35.4987 18.7305 34.654 18.469 33.568 18.1875C32.2004 17.8254 31.0842 17.4635 30.2194 17.1014C29.3747 16.7193 28.6507 16.1361 28.0474 15.3518C27.4441 14.5674 27.1424 13.5216 27.1424 12.2143C27.1424 11.0077 27.4441 9.95178 28.0474 9.04681C28.6507 8.14177 29.4954 7.44793 30.5815 6.96523C31.6675 6.4826 32.9244 6.24121 34.3523 6.24121C36.3836 6.24121 38.0428 6.7541 39.3299 7.7798C40.6372 8.7853 41.3612 10.173 41.502 11.9428H37.7009C37.6406 11.1786 37.2786 10.525 36.6149 9.98196C35.9512 9.43897 35.0763 9.16747 33.9904 9.16747C33.0049 9.16747 32.2004 9.41884 31.577 9.92167C30.9535 10.4244 30.6418 11.1484 30.6418 12.0937C30.6418 12.7373 30.8328 13.2702 31.2149 13.6925C31.6172 14.0947 32.12 14.4165 32.7233 14.6579C33.3266 14.8993 34.1513 15.1607 35.197 15.4422C36.5848 15.8243 37.711 16.2065 38.5758 16.5886C39.4606 16.9707 40.2048 17.564 40.8081 18.3684C41.4316 19.1528 41.7433 20.2087 41.7433 21.536C41.7433 22.6019 41.4517 23.6075 40.8685 24.5527C40.3054 25.4979 39.4707 26.2622 38.3646 26.8455C37.2786 27.4086 35.9915 27.6901 34.5032 27.6901Z"
        fill="white"
      />
      <path
        d="M54.3984 10.5854C55.6653 10.5854 56.7914 10.8569 57.7774 11.3999C58.7827 11.9429 59.5667 12.7474 60.1302 13.8132C60.7137 14.8792 61.0047 16.1663 61.0047 17.6746V27.479H57.5963V18.1875C57.5963 16.6993 57.224 15.563 56.4803 14.7786C55.7357 13.9741 54.7203 13.5719 53.4333 13.5719C52.1462 13.5719 51.12 13.9741 50.3561 14.7786C49.6116 15.563 49.2401 16.6993 49.2401 18.1875V27.479H45.8008V5.15527H49.2401V12.7875C49.8229 12.0836 50.5573 11.5406 51.4419 11.1586C52.3474 10.7765 53.3327 10.5854 54.3984 10.5854Z"
        fill="white"
      />
      <path
        d="M67.1433 8.6544C66.5202 8.6544 65.9971 8.44327 65.5745 8.02093C65.1527 7.5986 64.9414 7.07565 64.9414 6.45224C64.9414 5.82875 65.1527 5.30588 65.5745 4.88355C65.9971 4.46121 66.5202 4.25 67.1433 4.25C67.7469 4.25 68.26 4.46121 68.6818 4.88355C69.1044 5.30588 69.3157 5.82875 69.3157 6.45224C69.3157 7.07565 69.1044 7.5986 68.6818 8.02093C68.26 8.44327 67.7469 8.6544 67.1433 8.6544ZM68.8328 10.8566V27.4788H65.3942V10.8566H68.8328Z"
        fill="white"
      />
      <path d="M76.787 5.15527V27.479H73.3477V5.15527H76.787Z" fill="white" />
      <path
        d="M88.5096 27.7501C86.9408 27.7501 85.523 27.3981 84.2561 26.6942C82.9891 25.9702 81.9931 24.9647 81.2694 23.6775C80.545 22.3703 80.1836 20.8619 80.1836 19.1525C80.1836 17.4631 80.5551 15.9648 81.2996 14.6575C82.0434 13.3503 83.0596 12.3447 84.3466 11.6408C85.6337 10.9369 87.0716 10.585 88.6605 10.585C90.2494 10.585 91.6874 10.9369 92.9745 11.6408C94.2615 12.3447 95.2769 13.3503 96.0207 14.6575C96.7652 15.9648 97.1375 17.4631 97.1375 19.1525C97.1375 20.8418 96.7552 22.3401 95.9913 23.6473C95.2266 24.9546 94.181 25.9702 92.8537 26.6942C91.5465 27.3981 90.0985 27.7501 88.5096 27.7501ZM88.5096 24.7636C89.3942 24.7636 90.2193 24.5524 90.9831 24.13C91.7671 23.7077 92.401 23.0742 92.8839 22.2295C93.3661 21.3848 93.6075 20.3591 93.6075 19.1525C93.6075 17.9457 93.3761 16.9301 92.9141 16.1055C92.4513 15.2609 91.8375 14.6274 91.0737 14.2051C90.309 13.7826 89.4848 13.5715 88.6002 13.5715C87.7148 13.5715 86.8905 13.7826 86.1259 14.2051C85.3821 14.6274 84.7885 15.2609 84.3466 16.1055C83.9039 16.9301 83.6826 17.9457 83.6826 19.1525C83.6826 20.9423 84.1353 22.3301 85.0401 23.3155C85.9657 24.2809 87.1219 24.7636 88.5096 24.7636Z"
        fill="white"
      />
    </svg>
  )
}

export default LogoTextWhite32
