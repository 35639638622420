const secondary = {
  '50': '#E5F2FE',
  '100': '#BFDDFC',
  '200': '#98C9FA',
  '300': '#73B3F7',
  '400': '#5BA3F5',
  '500': '#4B93F2',
  '600': '#4885E3',
  '700': '#4273D0',
  '800': '#3D62BD',
  '900': '#35449D',
  A100: '#ffffff',
  A200: '#eff4ff',
  A400: '#bcd0ff',
  A700: '#a2beff',
}

export default secondary
