import { useMemo } from 'react'
import { Stack } from '@mui/material'
import { map } from 'lodash'

import PhoneCallIcon from '@/assets/icons/call-outlined-icon.svg?react'
import PersonIcon from '@/assets/icons/person-outlined-icon.svg?react'
import SelectItemCounter from '@/components/UIKit/Select/SelectItemCounter'
import type { GroupedCount } from '@/models/hasura/GroupedCount'

import type { SelectOption } from './MUI/useSelect'

type UseSelectOptionsWithCountersProps = {
  agentCounter?: GroupedCount[]
  callCounter?: GroupedCount[]
  options: SelectOption[]
}
const useSelectOptionsWithCounters = ({
  agentCounter,
  callCounter,
  options,
}: UseSelectOptionsWithCountersProps) => {
  const stringifiedOptions = JSON.stringify(options)

  const extendedOptions = useMemo(() => {
    if (!agentCounter && !callCounter) return options
    return map(options, (option) => {
      const agentCount = agentCounter
        ? agentCounter.find((item) => item.name === option.value)?.count || 0
        : null
      const callCount = callCounter
        ? callCounter.find((item) => item.name === option.value)?.count || 0
        : null
      return {
        ...option,
        appendSlot: (
          <Stack alignItems="center" direction="row" gap={1}>
            {agentCount != null ? (
              <SelectItemCounter count={agentCount} icon={<PersonIcon />} />
            ) : null}
            {callCount != null ? (
              <SelectItemCounter count={callCount} icon={<PhoneCallIcon />} />
            ) : null}
          </Stack>
        ),
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedOptions, agentCounter, callCounter])

  return { extendedOptions }
}

export default useSelectOptionsWithCounters
