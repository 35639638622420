/* eslint-disable sort-keys-fix/sort-keys-fix */
import 'boxicons/css/boxicons.min.css'
import '@fontsource/manrope/300.css'
import '@fontsource/manrope/400.css'
import '@fontsource/manrope/500.css'
import '@fontsource/manrope/600.css'
import '@fontsource/manrope/700.css'
import './App.css'
import './bx.css'
import './index.css'
import 'boxicons'

import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { LicenseInfo } from '@mui/x-license'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom'

import Toast from '@/components/Common/Toast/Toast'
import IntercomMessenger from '@/components/global/IntercomMessenger'

import { store } from './app/store'
import { TooltipGlobalStyle } from './components/ChartJS/ExternalTooltip'
import PaymentSetup from './components/Onboarding/Payment/PaymentSetup'
import AuthProvider from './context/AuthContext'
import HistoryTrackProvider from './context/HistoryTrackContext'
import { Slugs } from './modules/navigation/slugs'
import FirebasePage from './pages/FirebasePage'
import reportWebVitals from './reportWebVitals'
import MobileWarningProtectedRoutes from './routes/MobileWarningProtectedRoutes'
import { ProtectedRoutes } from './routes/ProtectedRoutes'
import PublicRoutes from './routes/PublicRoutes'
import theme from './theme/MuiTheme'

Sentry.init({
  dsn: 'https://f48acb53531effd236eebae9af5f75f7@o4507153555390464.ingest.us.sentry.io/4507701613428736',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const asyncOrganizationLoader = async ({
  params,
  request,
}: {
  params: any
  request: any
}) => {
  let { organizationLoader } = await import('./routes/root')
  return organizationLoader({ request, params })
}

export const router = createBrowserRouter([
  {
    path: '',
    element: (
      <HistoryTrackProvider>
        <MobileWarningProtectedRoutes />
        <IntercomMessenger />
      </HistoryTrackProvider>
    ),
    children: [
      {
        path: '/',
        element: <FirebasePage />,
        //element: <SplashPage />,
      },
      {
        path: '/login',
        element: <FirebasePage />,
      },
      {
        path: '/organization/:organizationId',
        loader: asyncOrganizationLoader,
        element: <ProtectedRoutes />,
        children: [
          {
            path: Slugs.Agents,
            lazy: () => import('./routes/lazy/agents-component'),
            loader: asyncOrganizationLoader,
            handle: {
              name: Slugs.Agents,
              title: 'Agents',
            },
          },
          {
            path: 'agents/:agentId',
            lazy: () => import('./routes/lazy/agent-component'),
            loader: asyncOrganizationLoader,
          },
          {
            path: 'agents/:agentId/calls/:callId',
            lazy: () => import('./routes/lazy/call-component'),
            loader: asyncOrganizationLoader,
          },
          {
            path: Slugs.Calls,
            lazy: () => import('./routes/lazy/calls-component'),
            loader: asyncOrganizationLoader,
            handle: {
              name: Slugs.Calls,
              title: 'Calls',
            },
          },
          {
            path: 'calls/:callId',
            lazy: () => import('./routes/lazy/call-component'),
            loader: asyncOrganizationLoader,
          },
          {
            path: Slugs.Dashboard,
            lazy: () => import('./routes/lazy/dashboard-component'),
            loader: asyncOrganizationLoader,
            handle: {
              name: Slugs.Dashboard,
              title: 'Dashboard',
            },
          },
          {
            path: 'notifications',
            lazy: () => import('./routes/lazy/notifications-component'),
            loader: asyncOrganizationLoader,
            children: [
              {
                path: ':notificationId',
                lazy: () => import('./routes/lazy/notifications-component'),
              },
            ],
          },
          {
            path: 'onboarding',
            lazy: () => import('./routes/lazy/onboarding-component'),
            //loader: teamLoader,
          },
          {
            path: Slugs.Settings,
            lazy: () => import('./routes/lazy/settings-component'),
            loader: asyncOrganizationLoader,
          },
          {
            path: 'walkthrough',
            element: <Outlet />,
            children: [
              {
                path: '',
                lazy: () => import('./routes/lazy/walkthrough-component'),
                loader: asyncOrganizationLoader,
              },
            ],
          },
        ],
      },
      {
        path: '/p',
        element: <PublicRoutes />,
        children: [
          {
            path: 'calls/:callId',
            lazy: () => import('./routes/lazy/call-component'),
          },
        ],
      },
      {
        path: '/payment/setup/:organizationId',
        element: <PaymentSetup />,
      },
    ],
  },
])

// MUI License
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string)

// A/B Features, Future Feature Flags

const getFeatureFlag = (feature: string) => {
  return process.env[`REACT_APP_FEATURE_${feature}`] === 'true'
}
// Sidebar

// Onboarding
// going to /onboarding will not redirect to /dashboard
export const FORCE_ONBOARDING = getFeatureFlag('FORCE_ONBOARDING')

export const SHOW_STRIPE_CHECKOUT = getFeatureFlag('SHOW_STRIPE_SETUP')

export const SHOW_NOTIFICATIONS = getFeatureFlag('SHOW_NOTIFICATIONS')

// Dashboard

// Calls

// Call
export const SHOW_OBJECTIONS = getFeatureFlag('SHOW_OBJECTIONS')
export const SHOW_RATING_TOOLTIP = getFeatureFlag('SHOW_RATING_TOOLTIP')
export const SHOW_SHARE_BUTTON = getFeatureFlag('SHOW_SHARE_BUTTON')

// Agents

// Settings
export const SHOW_INVITE_MEMBER_ROLE = getFeatureFlag('SHOW_INVITE_MEMBER_ROLE')
export const SHOW_COACHES_TAB = getFeatureFlag('SHOW_COACHES_TAB')
// Settings > ProfileAvatar
// Settings > Integrations
export const SHOW_MANUAL_SYNC = getFeatureFlag('SHOW_MANUAL_SYNC')
export const SHOW_FOLLOW_AGENT = getFeatureFlag('SHOW_FOLLOW_AGENT')
export const SHOW_MANAGE_NOTIFICATIONS = getFeatureFlag(
  'SHOW_MANAGE_NOTIFICATIONS'
)
export const SHOW_KEYWORDS = getFeatureFlag('SHOW_KEYWORDS')
export const SHOW_PREFERENCE_AI_MODEL = getFeatureFlag(
  'SHOW_PREFERENCE_AI_MODEL'
)
export const SHOW_RECRUITING_AI_MODEL = getFeatureFlag(
  'SHOW_RECRUITING_AI_MODEL'
)
// Settings > Usage
export const SHOW_INVOICES = getFeatureFlag('SHOW_INVOICES')
export const SHOW_INCLUDED_ON_THIS_PLAN = getFeatureFlag(
  'SHOW_INCLUDED_ON_THIS_PLAN'
)
export const SHOW_TOKEN_BREAKDOWN_CHART = getFeatureFlag(
  'SHOW_TOKEN_BREAKDOWN_CHART'
)
export const SHOW_TOKEN_CHART = getFeatureFlag('SHOW_TOKEN_CHART')

// Login
export const SHOW_MOBILE_WARNING_HELP = getFeatureFlag(
  'SHOW_MOBILE_WARNING_HELP'
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <TooltipGlobalStyle />
          <Toast />
          <RouterProvider router={router} />
        </ThemeProvider>
      </AuthProvider>
    </Provider>

    {/*<App />*/}
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
