import { useEffect, useRef } from 'react'
import Intercom, { boot, shutdown } from '@intercom/messenger-js-sdk'
import { InitType } from '@intercom/messenger-js-sdk/dist/types'
import { useLocation } from 'react-router-dom'

import { useAuth } from '@/context/AuthContext'

const INTERCOM_APP_ID = 'umi7y1ia'

const IntercomMessenger = () => {
  const { profile, user } = useAuth()
  const isInited = useRef(false)

  const location = useLocation()
  const isOnboardingRoute = location.pathname.endsWith('/onboarding')
  const isPaymentRoute = location.pathname.startsWith('/payment')
  const isOrganizationRoute = location.pathname.startsWith('/organization')

  const isRouteHasWidget =
    isOrganizationRoute && !isPaymentRoute && !isOnboardingRoute

  useEffect(() => {
    let config: InitType = {
      app_id: INTERCOM_APP_ID,
    }

    if (profile && isRouteHasWidget) {
      config = {
        ...config,
        created_at: profile.createdDate
          ? new Date(profile.createdDate).getTime()
          : undefined,
        email: user?.email || undefined,
        name: profile.name! || undefined,
        user_id: profile.id,
      }

      if (isInited.current) {
        boot(config)
      } else {
        Intercom(config)
        isInited.current = true
      }
    } else {
      shutdown()
    }
  }, [profile, user, isRouteHasWidget])

  return null
}

export default IntercomMessenger
