import { Divider, Stack } from '@mui/material'

type SplitViewContainerProps = {
  children: React.ReactNode
}

const SplitViewContainer = ({ children }: SplitViewContainerProps) => {
  return (
    <Stack
      direction="row"
      divider={<Divider flexItem orientation="vertical" />}
      spacing={3}
      sx={{ height: 1 }}
    >
      {children}
    </Stack>
  )
}

export default SplitViewContainer
