import type { FetchArgs } from '@reduxjs/toolkit/query'

import { Response } from '../../models/fub/Response'

// TODO move cors anywhere to env
export const fubBaseUrl =
  'https://shilo-ai-cors-anywhere-a060d8860ead.herokuapp.com/https://api.followupboss.com/v1'

const LIMIT = 100

export const getAuthorization = (apiKey: string) => {
  return `Basic ${btoa(apiKey)}`
}

// this functionality assumes that we are using endpoints that
// do not need a 'next' url key to get the next group of items
export async function getAllItems(
  fetchWithBQ: (obj: string | FetchArgs) => any,
  url: string,
  authorization: string,
  key: string,
  queryParams?: Record<string, any>
) {
  const params = {
    limit: LIMIT,
    offset: 0,
    ...queryParams,
  }
  const initialResult = await fetchWithBQ({
    url,
    params,
    headers: {
      authorization,
      'X-System': process.env.REACT_APP_FUB_X_SYSTEM,
      'X-System-Key': process.env.REACT_APP_FUB_X_SYSTEM_KEY,
    },
  })
  if (initialResult.error?.status) {
    return { error: initialResult.error }
  }

  const data = initialResult.data as Response
  const { total } = data._metadata
  if (total > LIMIT) {
    const promises = []
    for (let offset = LIMIT; offset < total; offset += LIMIT) {
      promises.push(
        fetchWithBQ({
          url,
          params: {
            ...params,
            offset,
          },
          headers: {
            authorization,
            'X-System': process.env.REACT_APP_FUB_X_SYSTEM,
            'X-System-Key': process.env.REACT_APP_FUB_X_SYSTEM_KEY,
          },
        })
      )
    }
    const result = await Promise.all(promises)
    const resultsArrays = [initialResult]
      .concat(result)
      .map((item) => item.data[key])
    let items = resultsArrays.reduce((acc, val) => acc.concat(val))
    return { data: items }
  } else {
    return { data: initialResult.data[key] }
  }
}
