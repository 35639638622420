import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
  useDispatch,
  useSelector,
  type TypedUseSelectorHook,
} from 'react-redux'

import { toastSlice } from '@/app/features/toast/toastSlice'
import toastMiddleware from '@/app/middlewares/toastMiddleware'
import { fubApi } from '@/services/clients/fub'
import { sierraApi } from '@/services/clients/sierra'

import { filtersCacheSlice } from './features/filtersCache/filtersCacheSlice'
import { transcriptsApi } from '../services/assemblyai/transcripts'
import { hasuraApi } from '../services/clients/hasura'
import { shiloApi } from '../services/clients/shilo'

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [transcriptsApi.reducerPath]: transcriptsApi.reducer,
    [filtersCacheSlice.reducerPath]: filtersCacheSlice.reducer,
    [hasuraApi.reducerPath]: hasuraApi.reducer,
    [shiloApi.reducerPath]: shiloApi.reducer,
    [fubApi.reducerPath]: fubApi.reducer,
    [sierraApi.reducerPath]: sierraApi.reducer,

    [toastSlice.reducerPath]: toastSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(shiloApi.middleware)
      .concat(transcriptsApi.middleware)
      .concat(hasuraApi.middleware)
      .concat(sierraApi.middleware)
      .concat(fubApi.middleware)
      .concat(toastMiddleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
