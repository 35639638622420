import { darken } from '@mui/material'

// mui does not currently have an alias for this
type ColorProp =
  | 'inherit'
  | 'action'
  | 'disabled'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'

export const getColor = (color: ColorProp | string, active: boolean) => {
  if (color[0] !== '#') {
    return active ? `${color}.main` : `${color}.dark`
  } else {
    return active ? color : darken(color, 0.3)
  }
}

type NeonTopLayerProps = {
  active?: boolean
  color?: ColorProp | string
  mediaIgnoreColor?: boolean
}

// primary content layer
export const neonTopLayerProps = ({
  active = false,
  color = 'inherit',
  mediaIgnoreColor = false,
}: NeonTopLayerProps) =>
  ({
    color: getColor(color, active),
    left: 0,
    position: 'relative',
    top: 0,
    transition: 'ease-in-out .25s all',
    zIndex: 2,
    '@media print': {
      color: !mediaIgnoreColor ? getColor(color, active) : 'black',
    },
  }) as const

type NeonMiddleLayerProps = {
  active?: boolean
}

// middle content layer
export const neonMiddleLayerProps = ({
  active = false,
}: NeonMiddleLayerProps) =>
  ({
    color: '#FFF',
    opacity: !active ? 0 : 1,
    left: 0,
    position: 'absolute',
    top: 0,
    transition: 'ease-in-out .25s all',
    zIndex: 1,
    '@media print': {
      display: 'none',
    },
  }) as const

// bottom content layer
export const neonBottomLayerProps = ({
  active = false,
}: NeonMiddleLayerProps) =>
  ({
    color: active ? '#191919' : 'hsla(0, 0%, 0%, 0)',
    left: '.125rem',
    position: 'absolute',
    top: '.125rem',
    zIndex: 0,
    '@media print': {
      display: 'none',
    },
  }) as const
