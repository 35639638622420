import { Outlet } from 'react-router-dom'

import { useAuth } from '@/context/AuthContext'
import LoadingPage from '@/pages/LoadingPage'

const PublicRoutes = () => {
  const { isUserLoading } = useAuth()
  if (isUserLoading) {
    return <LoadingPage />
  }
  return <Outlet />
}

export default PublicRoutes
