import { Box, Stack, SvgIconTypeMap, SxProps } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import Logo1 from '../../logos/Logo1'
import LogoTextBlack from '../../logos/LogoTextBlack'

type LoginHeaderProps = {
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  children: React.ReactNode
  sx?: SxProps
}

const LoginHeader = ({ Icon, children, sx }: LoginHeaderProps) => {
  return (
    <Stack alignItems="center" spacing={3} sx={{ pb: 3, ...sx }}>
      <Stack alignItems="center" direction="row" spacing={1.5}>
        {Icon ? (
          <Box
            component={Icon}
            sx={{ color: 'primary.main', fontSize: 80 }}
          ></Box>
        ) : (
          <>
            <Logo1 />
            <LogoTextBlack />
          </>
        )}
      </Stack>
      {children}
    </Stack>
  )
}

export default LoginHeader
