import { Box, SxProps, Typography, TypographyProps } from '@mui/material'

import {
  neonBottomLayerProps,
  neonMiddleLayerProps,
  neonTopLayerProps,
} from './common'

type NeonTypographyProps = TypographyProps &
  SxProps & {
    inactive?: boolean
    mediaIgnoreColor?: boolean
  }

const NeonTypography = ({
  children,
  color,
  inactive = false,
  mediaIgnoreColor = false,
  sx = {},
  variant,
  ...otherProps
}: NeonTypographyProps) => {
  // TODO need to tweak this for different variants
  const blurFontSize = variant === 'h5' ? 0.35 : 0.5

  return (
    <Box sx={{ position: 'relative', px: 0.125 }}>
      <Typography
        {...otherProps}
        sx={{
          ...neonTopLayerProps({
            active: !inactive,
            // @ts-ignore
            color,
            mediaIgnoreColor,
          }),
          ...sx,
        }}
        variant={variant}
      >
        {children}
      </Typography>
      <Typography
        {...otherProps}
        sx={{
          ...neonMiddleLayerProps({ active: !inactive }),
          filter: `blur(${blurFontSize}rem)`,
          ...sx,
        }}
        variant={variant}
      >
        {children}
      </Typography>
      <Typography
        {...otherProps}
        sx={{
          ...neonBottomLayerProps({ active: !inactive }),
          ...sx,
        }}
        variant={variant}
      >
        {children}
      </Typography>
    </Box>
  )
}

export default NeonTypography
