import { skipToken } from '@reduxjs/toolkit/query'
import { omit } from 'lodash'

import type { CallsFilters } from '@/hooks/filters/useCallsFilters'
import useSelectOptionsWithCounters from '@/hooks/useSelectOptionsWithCounters'
import type { GroupedCount } from '@/models/hasura/GroupedCount'
import { toSnakeCase } from '@/modules/object/caseTransformer'
import { useGetCallsCountGroupedByGroupQuery } from '@/services/hasura/calls'

import { SelectOption, UseSelect } from '../../../hooks/MUI/useSelect'
import Select from '../../UIKit/Select/Select'
import type { SelectVariant } from '../../UIKit/Select/types'
type GroupSelectProps = Partial<UseSelect> & {
  agentCounter?: GroupedCount[]
  filters?: CallsFilters
  onChange?: (value: string) => void
  options: SelectOption[]
  variant?: SelectVariant
}

export default function GroupSelect({
  agentCounter,
  filters,
  handleChange,
  onChange,
  options,
  value,
  variant,
}: GroupSelectProps) {
  const { data } = useGetCallsCountGroupedByGroupQuery(
    filters ? toSnakeCase(omit(filters, ['groupId'])) : skipToken,
    {
      skip: !filters || !!agentCounter,
    }
  )

  const { extendedOptions } = useSelectOptionsWithCounters({
    agentCounter,
    callCounter: data,
    options,
  })

  return (
    <Select
      handleChange={handleChange}
      hasFilter
      id="group-select"
      label="Group"
      labelId="group-select-label"
      menuSize="lg"
      onChange={onChange}
      options={extendedOptions}
      renderValue={(_, option) => option?.label}
      title="Group"
      value={value || ''}
      variant={variant}
    />
  )
}
