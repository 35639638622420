import type { FetchArgs } from '@reduxjs/toolkit/query'

export const assemblyAIBaseUrl = 'https://api.assemblyai.com/v2'
export const hasuraBaseUrl = process.env.REACT_APP_HASURA_URL
  ? `${process.env.REACT_APP_HASURA_URL}/api/rest`
  : `https://${process.env.REACT_APP_HASURA_PROJECT_NAME}.hasura.app/api/rest`

type GetAllItemsFactoryParams<
  TSuccessResponse = unknown,
  TFailedResponse = unknown,
  TData = unknown,
> = {
  fetchWithBQ: (obj: string | FetchArgs) => any
  getData: (response: TSuccessResponse) => TData[]
  getError: (response: TSuccessResponse | TFailedResponse) => string | undefined
  getHeaders?: () => Record<string, string>
  getQueryParams: (offset: number) => Record<string, any>
  getTotal: (response: TSuccessResponse) => number
  limit?: number
  url: string
}
export const getAllItemsFactory = async <
  TSuccessResponse = unknown,
  TFailedResponse = unknown,
  TData = unknown,
>({
  fetchWithBQ,
  getData,
  getError,
  getHeaders,
  getQueryParams,
  getTotal,
  limit = 100,
  url,
}: GetAllItemsFactoryParams<TSuccessResponse, TFailedResponse, TData>) => {
  type TResponse = TSuccessResponse | TFailedResponse
  const params = getQueryParams(0)
  const { data: initialResult }: { data: TResponse } = await fetchWithBQ({
    url,
    params,
    headers: getHeaders?.(),
  })
  const error = getError(initialResult)
  if (error) {
    return { error }
  }
  const total = getTotal(initialResult as TSuccessResponse)

  if (total > limit) {
    const promises: Array<Promise<{ data: TResponse }>> = []
    for (let offset = limit; offset < total; offset += limit) {
      promises.push(
        fetchWithBQ({
          url,
          params: getQueryParams(offset),
          headers: getHeaders?.(),
        })
      )
    }

    const results = await Promise.all(promises)
    const records = results
      .filter(
        (result): result is Awaited<{ data: TSuccessResponse }> =>
          !!result && !getError(result.data)
      )
      .reduce(
        (acc, result) => {
          return [...acc, ...getData(result.data)]
        },
        getData(initialResult as TSuccessResponse)
      )

    return { data: records }
  }
  return { data: getData(initialResult as TSuccessResponse) }
}
