import { alpha } from '@mui/material'

import primary from './colors/primary'

export const HOVER_OPACITY = 0.08
// TODO - ask design to add
//export const SELECTED_OPACITY = ?
// TODO - ask design to add
export const DISABLED_OPACITY = 0.12
export const DISABLED_OPACITY_LIGHT = 0.08
export const FOCUS_OPACITY = 0.12
// TODO - ask design to add
//export const ACTIVATED_OPACITY = ?
export const DRAG_OPACITY = 0.16

export const HOVER_ACTION = (color: string) => alpha(color, HOVER_OPACITY)
export const FOCUS_ACTION = (color: string) => alpha(color, FOCUS_OPACITY)
export const PRESS_ACTION = (color: string) => alpha(color, FOCUS_OPACITY)
export const DRAG_ACTION = (color: string) => alpha(color, DRAG_OPACITY)

export const PRIMARY_HOVER = HOVER_ACTION(primary[500])
export const PRIMARY_FOCUS = FOCUS_ACTION(primary[500])
export const PRIMARY_PRESS = PRESS_ACTION(primary[500])
export const PRIMARY_DRAG = DRAG_ACTION(primary[500])

/*

// mui theme default for reference

const action = {
  active: 'rgba(0, 0, 0, 0.54)',
  hover: 'rgba(0, 0, 0, 0.04)',
  hoverOpacity: 0.04,
  selected: 'rgba(0, 0, 0, 0.08)',
  selectedOpacity: 0.08,
  disabled: 'rgba(0, 0, 0, 0.26)',
  disabledBackground: 'rgba(0, 0, 0, 0.12)',
  disabledOpacity: 0.38,
  focus: 'rgba(0, 0, 0, 0.12)',
  focusOpacity: 0.12,
  activatedOpacity: 0.12,
}

*/
