import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { fubBaseUrl } from '../fub'

export const fubQuery = fetchBaseQuery({
  baseUrl: fubBaseUrl,
})

export const fubApi = createApi({
  reducerPath: 'fubApi',
  baseQuery: fubQuery,
  tagTypes: ['FUBWebhooks'],
  endpoints: () => ({}),
})
