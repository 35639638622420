import { Button } from '@mui/material'

import { useAuth } from '@/context/AuthContext'

type FloatLogoutButtonProps = {
  onClick?: () => void
}
const FloatLogoutButton = ({ onClick }: FloatLogoutButtonProps) => {
  const { logout } = useAuth()

  const handleClick = async () => {
    await logout()
    onClick?.()
  }

  return (
    <Button
      onClick={handleClick}
      sx={{
        position: 'fixed',
        right: 80,
        top: 20,
        zIndex: 10,
      }}
      variant="contained"
    >
      Logout
    </Button>
  )
}

export default FloatLogoutButton
