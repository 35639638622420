import { alpha, Stack, Typography } from '@mui/material'
import SnackBar from '@mui/material/Snackbar'

import useToast from '@/hooks/useToast'

import CheckOutlinedIcon from '../BoxIcon/outlined/CheckIcon'

const Toast = () => {
  const { closeToast, toastState } = useToast()

  return (
    <SnackBar
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      autoHideDuration={toastState.autoHideDuration}
      onClose={closeToast}
      open={toastState.isOpened}
      sx={{
        mb: 5.5,
      }}
    >
      <Stack
        alignItems="center"
        bgcolor={(t) => t.palette.grey[800]}
        borderRadius={40}
        direction="row"
        gap={1}
        px={2}
        py={1}
        sx={{
          backdropFilter: 'blur(10px)',
          background: (t) => alpha(t.palette.grey[800], 0.8),
        }}
      >
        <CheckOutlinedIcon sx={{ color: '#fff' }} />
        <Typography sx={{ color: '#fff' }} variant="button2">
          {toastState.message}
        </Typography>
      </Stack>
    </SnackBar>
  )
}

export default Toast
