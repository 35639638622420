import { Card, CardContent, Stack } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import GroupSelect from '@/components/Common/GroupSelect/GroupSelect'
import TeamSelect from '@/components/Common/TeamSelect/TeamSelect'
import SortBySelect from '@/components/Settings/Users/SortBySelect'
import ControlBar from '@/components/UIKit/ControlBar/ControlBar'
import SearchInput from '@/components/UIKit/SearchInput/SearchInput'

import Notification from './Notification'
import NotificationList from './NotificationList'
import SplitViewContainer from '../Common/SplitViewContainer/SplitViewContainer'
import SplitViewContainerItem from '../Common/SplitViewContainer/SplitViewContainerItem'
import SplitViewContainerList from '../Common/SplitViewContainer/SplitViewContainerList'
import PageHeader from '../UIKit/Page/PageHeader'

// TODO remove this when we have notifications in the backend
export const notifications = [
  {
    createdAt: new Date(new Date().getTime() - 60 * 60 * 1000),
    description:
      'Here is an overview of your Jackie Lander’s call on July 9, 2024 at 1:06pm that rose above call rating [X].',
    id: '1',
    isUnread: true,
    title: '[Agent Name]: Call Rating Below [X]',
  },
  {
    createdAt: new Date(new Date().getTime() - 2 * 60 * 60 * 1000),
    description:
      'Make sure you to provide feedback and coaching so their score does not continue to fall.',
    id: '2',
    isUnread: true,
    title: '[Agent Name]: Call Rating Above [X]',
  },
  {
    createdAt: new Date(new Date().getTime() - 4 * 60 * 60 * 1000),
    description:
      "Your average call rating last week was X - It's time to dig in on how you can improve and highlight where you are winning!",
    id: '3',
    isUnread: true,
    title: '[Agent Name]: Average Call Rating Fell Below [X]',
  },
  {
    createdAt: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
    description:
      "Your average call rating last week was X - It's time to dig in on how you can improve and highlight where you are winning!",
    id: '4',
    isUnread: true,
    title: 'Weekly Wrap-Up: [Date Range]',
  },
  {
    createdAt: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
    description: "Let's check out the stats.",
    id: '5',
    isUnread: true,
    title: 'Monthly Rewind: [Month]',
  },
]

const Notifications = () => {
  const { notificationId, organizationId } = useParams()
  const navigate = useNavigate()

  const areNotificationsLoading = false

  // TODO add a handler
  const search = <SearchInput />

  // TODO handle filters
  const filters = (
    <>
      <TeamSelect agentCounter={[]} options={[]} />
      <GroupSelect agentCounter={[]} options={[]} />
      <SortBySelect onChange={() => {}} value={undefined} />
    </>
  )
  const controlBar = <ControlBar leftFilters={filters} />
  const handleItemClick = (notification: TODO) => {
    navigate(`/organization/${organizationId}/notifications/${notification.id}`)
  }
  return (
    <Stack sx={{ height: '100%' }}>
      <PageHeader
        actions={search}
        sx={{ alignItems: 'center', flexWrap: 'wrap', gap: 2 }}
        title="Notifications"
      />
      {controlBar}
      <Card sx={{ height: 1, minHeight: 0, minWidth: 0 }}>
        <CardContent sx={{ height: 1, minHeight: 0, minWidth: 0 }}>
          <SplitViewContainer>
            <SplitViewContainerList>
              <NotificationList
                notifications={notifications}
                onItemClick={handleItemClick}
                selectedId={notificationId}
              />
            </SplitViewContainerList>
            {/* TODO add loading state */}
            {!areNotificationsLoading && notificationId ? (
              <SplitViewContainerItem>
                <Notification id={notificationId} sx={{ height: 1 }} />
              </SplitViewContainerItem>
            ) : null}
          </SplitViewContainer>
        </CardContent>
      </Card>
    </Stack>
  )
}

export default Notifications
