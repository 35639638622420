import { Box, Stack, Typography, type StackProps } from '@mui/material'

import PointIcon from '@/assets/icons/shilo-point-outlined-icon.svg?react'
type BulletKeyPointProps = StackProps & {
  variant?: 'highlight' | 'regular'
}

const typographyMap = {
  highlight: 'subtitle2Semibold',
  regular: 'body2',
} as const
const colorMap = {
  highlight: 'primary.main',
  regular: undefined,
}
const BulletKeyPoint = ({
  children,
  sx,
  variant = 'highlight',
  ...rest
}: BulletKeyPointProps) => {
  return (
    <Stack
      alignItems="center"
      color={colorMap[variant]}
      direction="row"
      gap={1}
      {...rest}
    >
      <Box component={PointIcon} sx={{ color: 'primary.main' }} />
      <Typography variant={typographyMap[variant]}>{children}</Typography>
    </Stack>
  )
}

export default BulletKeyPoint
