import {
  createApi,
  fetchBaseQuery,
  type BaseQueryFn,
  type FetchArgs,
  type FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'

export const shiloAPIUrl = `${process.env.REACT_APP_SHILO_API_URL as string}/api`
export const shiloQuery = fetchBaseQuery({
  baseUrl: shiloAPIUrl,
})

export const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let extendedArgs = args

  if (
    extraOptions &&
    'includeToken' in extraOptions &&
    extraOptions.includeToken &&
    extendedArgs &&
    typeof extendedArgs === 'object'
  ) {
    const { auth } = await import('@/config/firebase')
    const user = auth.currentUser
    const token = user ? await user.getIdToken() : ''
    extendedArgs = token
      ? {
          ...extendedArgs,
          headers: {
            ...extendedArgs.headers,
            Authorization: `Bearer ${token}`,
          },
        }
      : args
  }
  const result = await shiloQuery(extendedArgs, api, extraOptions)
  return result
}

export const shiloApi = createApi({
  reducerPath: 'shiloApi',
  baseQuery: baseQueryWithAuth,
  tagTypes: [],
  endpoints: () => ({}),
})
