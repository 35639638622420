import { useEffect, useRef } from 'react'
import { Stack, type SxProps } from '@mui/material'

import { SELECT_BOX_SHADOW } from '@/theme/elevation'

import SearchInput from '../SearchInput/SearchInput'

type SelectFilterBarProps = {
  // dont rename to value, it will break the select logic
  inputValue: string
  onChange: (value: string) => void
  sx?: SxProps
}

const SelectFilterBar = ({
  inputValue,
  onChange,
  sx,
}: SelectFilterBarProps) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (ref.current) {
      // to prevent mui default behavior
      ref.current.focus()
    }
  }, [inputValue])

  return (
    <Stack sx={sx}>
      <SearchInput
        inputProps={{
          onKeyDown: (e) => e.stopPropagation(),
        }}
        inputRef={ref}
        onChange={onChange}
        sx={{
          boxShadow: SELECT_BOX_SHADOW,
          maxWidth: 1,
        }}
        value={inputValue}
        variant="outlined"
      />
    </Stack>
  )
}

SelectFilterBar.muiSkipListHighlight = true

export default SelectFilterBar
