const LogoTextBlack = () => {
  return (
    <svg
      fill="none"
      height="30"
      viewBox="0 0 88 30"
      width="88"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.41778 29.6124C7.65804 29.6124 6.07427 29.3108 4.66648 28.7074C3.25869 28.079 2.15253 27.1991 1.34803 26.0678C0.543614 24.9365 0.141357 23.6167 0.141357 22.1083H4.74184C4.84245 23.2396 5.28234 24.1698 6.06169 24.8988C6.86611 25.6279 7.98483 25.9923 9.41778 25.9923C10.901 25.9923 12.0574 25.6404 12.8871 24.9365C13.7166 24.2074 14.1314 23.2773 14.1314 22.146C14.1314 21.2662 13.8674 20.5497 13.3395 19.9966C12.8367 19.4435 12.1957 19.0162 11.4164 18.7145C10.6622 18.4129 9.60633 18.0861 8.24885 17.7341C6.53931 17.2816 5.14409 16.8291 4.0631 16.3765C3.00725 15.8989 2.10223 15.1699 1.34803 14.1895C0.59392 13.209 0.216819 11.9017 0.216819 10.2677C0.216819 8.75933 0.59392 7.43948 1.34803 6.30827C2.10223 5.17697 3.15807 4.30967 4.51565 3.70629C5.87314 3.10301 7.44434 2.80127 9.22923 2.80127C11.7683 2.80127 13.8423 3.44238 15.4512 4.7245C17.0853 5.98147 17.9903 7.71606 18.1663 9.92827H13.415C13.3395 8.97304 12.8871 8.15595 12.0574 7.47721C11.2278 6.79846 10.1343 6.45909 8.77677 6.45909C7.54495 6.45909 6.53931 6.77331 5.76005 7.40185C4.9807 8.03028 4.59102 8.93531 4.59102 10.1168C4.59102 10.9213 4.82987 11.5875 5.30749 12.1154C5.81026 12.6182 6.4388 13.0204 7.193 13.3221C7.94711 13.6238 8.97789 13.9506 10.2851 14.3026C12.0198 14.7802 13.4276 15.2579 14.5085 15.7355C15.6146 16.2131 16.5448 16.9548 17.299 17.9603C18.0783 18.9408 18.4679 20.2606 18.4679 21.9198C18.4679 23.2522 18.1034 24.5092 17.3744 25.6907C16.6705 26.8723 15.6272 27.8275 14.2445 28.5566C12.8871 29.2605 11.2781 29.6124 9.41778 29.6124Z"
        fill="black"
      />
      <path
        d="M34.2873 8.23148C35.8709 8.23148 37.2786 8.57085 38.511 9.24959C39.7677 9.92834 40.7477 10.934 41.452 12.2663C42.1815 13.5987 42.5452 15.2077 42.5452 17.0931V29.3486H38.2847V17.7342C38.2847 15.8738 37.8193 14.4535 36.8896 13.4731C35.959 12.4674 34.6897 11.9647 33.0809 11.9647C31.4721 11.9647 30.1893 12.4674 29.2344 13.4731C28.3038 14.4535 27.8394 15.8738 27.8394 17.7342V29.3486H23.5403V1.44385H27.8394V10.9843C28.5679 10.1043 29.486 9.42557 30.5917 8.94795C31.7236 8.47033 32.9551 8.23148 34.2873 8.23148Z"
        fill="black"
      />
      <path
        d="M50.2159 5.818C49.4372 5.818 48.7832 5.55409 48.255 5.02617C47.7277 4.49825 47.4636 3.84465 47.4636 3.0653C47.4636 2.28594 47.7277 1.63235 48.255 1.10443C48.7832 0.57651 49.4372 0.3125 50.2159 0.3125C50.9705 0.3125 51.6119 0.57651 52.1392 1.10443C52.6674 1.63235 52.9315 2.28594 52.9315 3.0653C52.9315 3.84465 52.6674 4.49825 52.1392 5.02617C51.6119 5.55409 50.9705 5.818 50.2159 5.818ZM52.3278 8.5708V29.3485H48.0296V8.5708H52.3278Z"
        fill="black"
      />
      <path
        d="M62.2711 1.44385V29.3486H57.9719V1.44385H62.2711Z"
        fill="black"
      />
      <path
        d="M76.9275 29.6879C74.9666 29.6879 73.1943 29.2479 71.6106 28.3681C70.0269 27.463 68.7819 26.2061 67.8773 24.5971C66.9718 22.9631 66.52 21.0777 66.52 18.9408C66.52 16.8291 66.9844 14.9562 67.915 13.3221C68.8447 11.6881 70.115 10.4312 71.7238 9.5513C73.3326 8.67133 75.1301 8.23145 77.1162 8.23145C79.1023 8.23145 80.8998 8.67133 82.5086 9.5513C84.1174 10.4312 85.3867 11.6881 86.3164 13.3221C87.247 14.9562 87.7124 16.8291 87.7124 18.9408C87.7124 21.0525 87.2345 22.9254 86.2796 24.5594C85.3238 26.1935 84.0168 27.463 82.3577 28.3681C80.7237 29.2479 78.9137 29.6879 76.9275 29.6879ZM76.9275 25.9547C78.0333 25.9547 79.0646 25.6907 80.0194 25.1628C80.9994 24.6349 81.7917 23.843 82.3954 22.7871C82.9981 21.7312 83.3 20.4492 83.3 18.9408C83.3 17.4324 83.0107 16.163 82.4332 15.1322C81.8546 14.0763 81.0875 13.2845 80.1326 12.7566C79.1768 12.2286 78.1465 11.9646 77.0407 11.9646C75.934 11.9646 74.9037 12.2286 73.9479 12.7566C73.0182 13.2845 72.2762 14.0763 71.7238 15.1322C71.1704 16.163 70.8937 17.4324 70.8937 18.9408C70.8937 21.1782 71.4597 22.9129 72.5906 24.1447C73.7476 25.3513 75.193 25.9547 76.9275 25.9547Z"
        fill="black"
      />
    </svg>
  )
}

export default LogoTextBlack
