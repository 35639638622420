const primary = {
  '50': '#EEE8FD',
  '100': '#D3C7FA',
  '200': '#B4A1F9',
  '300': '#9379F7',
  '400': '#765BF5',
  '500': '#553df2',
  '600': '#4638EB',
  '700': '#2C30E2',
  '800': '#002BDC',
  '900': '#001ED4',
  A100: '#ffffff',
  A200: '#eae8ff',
  A400: '#bab5ff',
  A700: '#a29cff',
}

export default primary
