import { Stack, Typography, type StackProps } from '@mui/material'

import Bullet from '@/components/Common/Bullet/Bullet'

type NotificationCallOverviewItemProps = Omit<StackProps, 'title'> & {
  color?: string
  hasBullet?: boolean
  label: string
  title: string | number | React.ReactNode
  titleProps?: StackProps
  trend?: number
}
const NotificationCallOverviewItem = ({
  color = 'primary',
  hasBullet = true,
  label,
  title,
  titleProps,
  trend,
  ...rest
}: NotificationCallOverviewItemProps) => {
  return (
    <Stack {...rest}>
      <Typography component={Stack} variant="h6" {...titleProps}>
        {title}
      </Typography>
      <Stack alignItems="center" direction="row" spacing={0.5}>
        {hasBullet && <Bullet color={color} size="small" />}
        <Typography color={(theme) => theme.palette.grey[500]} variant="body3">
          {label}
        </Typography>
        {trend != null && (
          <Typography variant="caption2">
            {trend > 0 ? '+' : ''}
            {trend}%
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default NotificationCallOverviewItem
