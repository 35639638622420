import { Box } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { map } from 'lodash'

import { getDurationInSeconds } from '@/formatters/time'
import green from '@/theme/palette/colors/green'
import pink from '@/theme/palette/colors/pink'
import primary from '@/theme/palette/colors/primary'
import tertiary from '@/theme/palette/colors/tertiary'
import yellowOrange from '@/theme/palette/colors/yellowOrange'
import secondary from '@/theme/secondary2'

import NotificationCallOverviewItem from './NotificationCallOverviewItem'
import NotificationSectionGroup from './NotificationSectionGroup'

const COLUMN_SPACING = 2

type ItemConfig<T = number> = {
  color?: string
  label?: string
  title: T
  trend?: number
}
type NotificationCallOverviewProps = {
  duration?: number | ItemConfig
  fillerWords?: number | ItemConfig
  incoming?: boolean | ItemConfig<boolean>
  incomingCalls?: number | ItemConfig
  listening?: number | ItemConfig
  monologues?: number | ItemConfig
  outgoingCalls?: number | ItemConfig
  questionsAsked?: number | ItemConfig
  rating?: number | ItemConfig
  talkToListen?: [number, number] | ItemConfig<[number, number]>
  talking?: number | ItemConfig
  title?: string
  totalCalls?: number | ItemConfig
  totalTalkTime?: number | ItemConfig
}

type OverviewItem = {
  color: string
  label: string
  title: React.ReactNode
  trend?: number
}

const defaultConfig = {
  duration: {
    color: pink[500],
    label: 'Total call time',
  },
  fillerWords: {
    color: secondary[500],
    label: 'Filler words',
  },
  incoming: {
    color: tertiary[500],
    label: 'Call type',
  },
  incomingCalls: {
    color: secondary[500],
    label: 'Inbound',
  },
  listening: {
    color: yellowOrange[800],
    label: 'Listening',
  },
  monologues: {
    color: green[500],
    label: 'Monologues',
  },
  outgoingCalls: {
    color: primary[500],
    label: 'Outbound',
  },
  questionsAsked: {
    color: primary[500],
    label: 'Question asked',
  },
  rating: {
    color: tertiary[500],
    label: 'Rating',
  },
  talking: {
    color: yellowOrange[800],
    label: 'Talking',
  },
  talkToListen: {
    color: yellowOrange[800],
    label: 'Talk to listen',
  },
  totalCalls: {
    color: tertiary[500],
    label: 'Total Calls',
  },
  totalTalkTime: {
    color: pink[500],
    label: 'Total talk time',
  },
} as const

const getValueFromItem = <T,>(item: T | ItemConfig<T>) => {
  if (item == null) {
    return null
  }
  if (typeof item === 'object') {
    return 'title' in item ? item.title : item
  }
  return item
}
const NotificationCallOverview = ({
  duration,
  fillerWords,
  incoming,
  incomingCalls,
  listening,
  monologues,
  outgoingCalls,
  questionsAsked,
  rating,
  talkToListen,
  talking,
  title = 'Call Overview',
  totalCalls,
  totalTalkTime,
}: NotificationCallOverviewProps) => {
  const incomingValue = getValueFromItem(incoming)
  const durationValue = getValueFromItem(duration)
  const totalTalkTimeValue = getValueFromItem(totalTalkTime)
  const talkingValue = getValueFromItem(talking)
  const listeningValue = getValueFromItem(listening)
  const talkToListenValue = getValueFromItem(talkToListen)

  const items = [
    [totalCalls, 'totalCalls'] as const,
    [incomingCalls, 'incomingCalls'] as const,
    [outgoingCalls, 'outgoingCalls'] as const,
    [rating, 'rating'] as const,
    [questionsAsked, 'questionsAsked'] as const,
    [fillerWords, 'fillerWords'] as const,
    [monologues, 'monologues'] as const,
    [
      talkToListen,
      'talkToListen',
      talkToListenValue
        ? `${talkToListenValue[0]}% : ${talkToListenValue[1]}%`
        : null,
    ] as const,
    [talking, 'talking', `${talkingValue}%`] as const,
    [listening, 'listening', `${listeningValue}%`] as const,
    [
      duration,
      'duration',
      durationValue ? getDurationInSeconds(durationValue) : null,
    ] as const,
    [
      totalTalkTime,
      'totalTalkTime',
      totalTalkTimeValue ? getDurationInSeconds(totalTalkTimeValue) : null,
    ] as const,
    [incoming, 'incoming', incomingValue ? 'Inbound' : 'Outbound'] as const,
  ]
    .map(([item, key, formattedTitle]): OverviewItem | null => {
      if (!item) return null
      const isItemConfig = typeof item === 'object' && 'title' in item
      return isItemConfig
        ? {
            ...defaultConfig[key],
            ...item,
            title: formattedTitle || item.title,
          }
        : {
            ...defaultConfig[key],
            title: formattedTitle || item,
          }
    })
    .filter((item): item is OverviewItem => !!item)

  return (
    <NotificationSectionGroup title={title}>
      <Box>
        <Grid2
          container
          rowSpacing={COLUMN_SPACING}
          spacing={COLUMN_SPACING}
          width={1}
        >
          {map(items, (item) => (
            <Grid2 key={item.label} xs={4}>
              <NotificationCallOverviewItem
                color={item.color}
                label={item.label}
                title={item.title}
                trend={item.trend}
              />
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </NotificationSectionGroup>
  )
}

export default NotificationCallOverview
