import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface ToastState {
  autoHideDuration: number
  isOpened: boolean
  message: string
}

const initialState: ToastState = {
  autoHideDuration: 5000,
  isOpened: false,
  message: '',
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    open: (
      state,
      action: PayloadAction<{ message: string; timeout?: number }>
    ) => {
      const { message, timeout } = action.payload
      state.isOpened = true
      state.message = message
      if (timeout) state.autoHideDuration = timeout
    },

    close: () => initialState,
  },
})

export const { close, open } = toastSlice.actions
