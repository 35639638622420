import { Typography, useTheme } from '@mui/material'

import NeonTypography from '../../../packages/neon/NeonTypography'
import { INPUT_WIDTH } from '../../Login/consts'

type PageModalSecondaryHeaderTextProps = {
  children: React.ReactNode
}

const PageModalSecondaryHeaderText = ({
  children,
}: PageModalSecondaryHeaderTextProps) => {
  const theme = useTheme()
  const darkMode = theme.palette.mode === 'dark'
  return darkMode ? (
    <NeonTypography align="center" sx={{ width: INPUT_WIDTH }} variant="body1">
      {children}
    </NeonTypography>
  ) : (
    <Typography align="center" sx={{ width: INPUT_WIDTH }} variant="body1">
      {children}
    </Typography>
  )
}

export default PageModalSecondaryHeaderText
