import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'

dayjs.extend(isToday)

export type BaseRangeFilter = {
  endDate?: string
  startDate?: string
}

export const isSameDayRange = ({ endDate, startDate }: BaseRangeFilter) => {
  if (startDate == null && endDate == null) {
    return false
  }
  return dayjs(startDate).isSame(dayjs(endDate), 'day')
}

/**
 * Formats a given date based on whether it is today or not.
 * If the date is today, it returns the time in 'hh:mm A' format. (e.g. 12:00 PM)
 * If the date is not today, it returns the date in 'MMM D' format. (e.g. Jan 1)
 */
export const formatDateBasedOnToday = (date: Date) => {
  const dateObj = dayjs(date)
  return dateObj.isToday() ? dateObj.format('hh:mm A') : dateObj.format('MMM D')
}
