import { Divider, Stack } from '@mui/material'
import { map } from 'lodash'

import NotificationListItem from './NotificationListItem'

export type NotificationVariant = 'separated' | 'compact'

type NotificationListProps = {
  notifications: TODO[]
  onItemClick: (index: number) => void
  selectedId: string | undefined
  variant?: NotificationVariant
}

const NotificationList = ({
  notifications,
  onItemClick,
  selectedId,
  variant,
}: NotificationListProps) => {
  return (
    <Stack
      divider={variant === 'separated' ? <Divider /> : null}
      gap={variant === 'separated' ? 0 : 0.5}
    >
      {map(notifications, (notification) => {
        return (
          <NotificationListItem
            active={notification.id === selectedId}
            key={notification.id}
            notification={notification}
            onClick={onItemClick}
            variant={variant}
          />
        )
      })}
    </Stack>
  )
}

export default NotificationList
