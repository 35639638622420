const neutral = {
  // TODO figure out with design team what to do with this color
  '20': '#EAEAEC',
  '50': '#E5E2E2',
  '100': '#C8C6C6',
  '200': '#ADABAB',
  '300': '#929090',
  '400': '#787777',
  '500': '#5f5e5e',
  '600': '#474647',
  '700': '#313030',
  '800': '#1C1B1C',
  '900': '#000000',
  A100: '#f49292',
  A200: '#ef6464',
  A400: '#ff2121',
  A700: '#ff0808',
}

export default neutral
