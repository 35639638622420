import { useEffect, useRef } from 'react'
import { CircularProgress, Stack } from '@mui/material'
import { skipToken } from '@reduxjs/toolkit/query'
import { useParams, useSearchParams } from 'react-router-dom'

import { useGetStripeSetupSessionQuery } from '@/services/shilo/payment'

import { paymentSetupBroadcast } from './utils'

const PaymentSetup = () => {
  const { organizationId } = useParams()
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const priceId = searchParams.get('priceId')
  const isCompleteStep = searchParams.get('complete')
  const isInitialized = useRef(false)

  const mode = isCompleteStep ? 'complete' : 'setup'

  const hasSetupDetails = Boolean(priceId || email)
  const { data, isError } = useGetStripeSetupSessionQuery(
    hasSetupDetails
      ? {
          email: email!,
          organizationId: organizationId!,
          priceId: priceId!,
        }
      : skipToken
  )
  useEffect(() => {
    if (isInitialized.current) {
      return
    }
    if (mode === 'setup' && (!hasSetupDetails || isError)) {
      window.close()
    }
    if (mode === 'setup' && data) {
      if (data.url) {
        window.location.href = data.url
      } else {
        window.close()
      }
      isInitialized.current = true
    }

    if (mode === 'complete') {
      paymentSetupBroadcast.postMessage({
        type: 'setup-complete',
      })
      isInitialized.current = true
    }
  }, [organizationId, hasSetupDetails, data, mode, isError])

  return (
    <Stack alignItems="center" height="100vh" justifyContent="center">
      <CircularProgress />
    </Stack>
  )
}

export default PaymentSetup
