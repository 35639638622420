import { useState, type PropsWithChildren } from 'react'
import {
  Box,
  FormControl,
  InputLabel,
  List,
  MenuItem,
  Stack,
  type SxProps,
} from '@mui/material'
import MUISelect, { type SelectChangeEvent } from '@mui/material/Select'
import { map } from 'lodash'

import SelectFooter from './SelectFooter'
import SelectHeader from './SelectHeader'
import SelectMenuItem from './SelectMenuItem'
import {
  selectButtonStyles,
  selectMenuLabelStyles,
  selectMenuStyles,
} from './styles'
import type {
  RenderOptionFunction,
  MenuHorizontalAlign,
  RenderValueFunction,
  SelectListItem,
  SelectMenuSize,
  SelectVariant,
} from './types'
import { getFilteredOption, getMenuAlignProps, groupOptions } from './utils'
import type { SelectOption, UseSelect } from '../../../hooks/MUI/useSelect'

export type SelectProps<T extends string = string> = Partial<UseSelect> & {
  children?: React.ReactNode
  hasFilter?: boolean
  hasGrouping?: boolean
  hasResetOption?: boolean
  horizontalAlign?: MenuHorizontalAlign
  id?: string
  isOpen?: boolean
  label?: string
  labelId?: string
  menuSize?: SelectMenuSize
  onChange?: (value: T) => void
  onClose?: (event: any) => void
  onItemClick?: (value: T) => void
  onOpen?: () => void
  options: SelectOption<T>[]
  renderOption?: RenderOptionFunction
  renderValue?: RenderValueFunction
  size?: 'medium' | 'large'
  title?: string
  variant?: SelectVariant
}

const SelectContainer = ({
  children,
  sx,
}: PropsWithChildren<{ sx?: SxProps }>) => {
  return (
    <Stack height={1} overflow="hidden" sx={sx}>
      {children}
    </Stack>
  )
}

SelectContainer.muiSkipListHighlight = true

const Select = <T extends string = string>({
  children,
  isOpen: isOpenProp,
  onOpen: onOpenProp,
  onClose: onCloseProp,
  value,
  options,
  variant,
  hasGrouping = false,
  hasResetOption = true,
  onChange: onChangeProp,
  onItemClick,
  handleChange: handleChangeProp,
  labelId,
  label,
  hasFilter,
  horizontalAlign = 'left',
  id,
  renderOption = (option) => option.label,
  menuSize = 'md',
  renderValue = (_, selectedOption) =>
    selectedOption ? renderOption(selectedOption) : '',
  size = 'medium',
  title,
}: SelectProps<T>) => {
  const isOpenControlled = isOpenProp != null
  const [isOpen, setIsOpen] = useState(false)
  const isOpenValue = isOpenControlled ? isOpenProp : isOpen

  const onOpen = () => {
    if (!isOpenControlled) {
      setIsOpen(true)
    }
    onOpenProp?.()
  }
  const onClose = (_event: any) => {
    if (!isOpenControlled) {
      setIsOpen(false)
    }
    onCloseProp?.(null)
  }

  const [filter, setFilter] = useState('')

  const isSelectedStyleActive = value != null && value !== ''
  const selectedOption = options.find((option) => option.value === value)
  const isOutlined = variant === 'outlined'

  const filteredOptions = filter
    ? options.map((option) => getFilteredOption(option, filter))
    : options

  const groupedOptions: SelectListItem[] = hasGrouping
    ? groupOptions(filteredOptions)
    : filteredOptions

  const shouldShowResetOption = hasResetOption && isSelectedStyleActive

  const handleChange = (event: SelectChangeEvent) => {
    handleChangeProp?.(event)
    onChangeProp?.(event.target.value as T)
  }

  /* TODO remove custom event trigger function when the issue will be fixed by MUI
    https://github.com/mui/material-ui/issues/31006 */
  const handleItemClick = (value: T | undefined) => {
    if (value) {
      onItemClick?.(value)
    }
    handleChange?.({
      target: { value },
    } as unknown as SelectChangeEvent)
    onClose('manual')
  }

  return (
    <Box>
      <FormControl size="small">
        <InputLabel
          id={labelId}
          sx={(theme) =>
            selectMenuLabelStyles({
              hidden: isSelectedStyleActive,
              size,
              theme,
              value,
            })
          }
        >
          {label}
        </InputLabel>
        <MUISelect
          MenuProps={{
            sx: selectMenuStyles({
              hasXOffset: false,
              menuSize,
              noScroll: true,
            }),
            ...getMenuAlignProps(horizontalAlign),
          }}
          displayEmpty
          id={id}
          labelId={labelId}
          onClose={onClose}
          onOpen={onOpen}
          open={isOpenValue}
          renderValue={(value) => renderValue(value, selectedOption) || label}
          sx={(theme) =>
            selectButtonStyles({
              isActive: isSelectedStyleActive,
              isOutlined,
              size,
              theme,
              valueOpacity: isSelectedStyleActive ? 1 : 0,
            })
          }
          value={value ?? ''}
        >
          {/* render hidden options to prevent mui warnings */}
          {map(options, (option) => (
            <MenuItem
              key={option.value}
              sx={{ display: 'none' }}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
          <SelectContainer sx={{ display: children ? 'none' : 'flex' }}>
            <SelectHeader
              filter={filter}
              hasFilter={hasFilter}
              setFilter={setFilter}
              title={title}
            />
            <List
              aria-label="menu options"
              component="nav"
              sx={{ flex: 1, overflow: 'auto', p: 0 }}
            >
              {map(groupedOptions, (option, index) => (
                // TODO is there a better way to handle handleItemClick in TS?
                <SelectMenuItem
                  key={index}
                  {...{
                    handleItemClick: handleItemClick as (
                      value: string | undefined
                    ) => void,
                    option,
                    renderOption,
                    value,
                  }}
                />
              ))}
            </List>
            <SelectFooter
              hasReset={shouldShowResetOption}
              onReset={() => handleItemClick(undefined)}
            />
          </SelectContainer>
          {children}
        </MUISelect>
      </FormControl>
    </Box>
  )
}

export default Select
