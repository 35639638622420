const pink = {
  '50': '#fbe5ed',
  '100': '#f6bed3',
  '200': '#f195b6',
  '300': '#ec6c99',
  '400': '#e85082',
  '500': '#E53A6E',
  '600': '#d43669',
  '700': '#bd3263',
  '800': '#a82e5e',
  '900': '#812854',
  A100: '#ffffff',
  A200: '#ffd3db',
  A400: '#ffa0b1',
  A700: '#ff869c',
}

export default pink
