import { Button, Stack, Typography } from '@mui/material'

type SelectClearButtonProps = {
  onReset: () => void
}

const SelectClearButton = ({ onReset }: SelectClearButtonProps) => {
  return (
    <Stack alignItems="flex-start">
      <Button
        color="primary"
        onClick={onReset}
        size="small"
        sx={{
          height: 'auto',
          px: 1.5,
          py: 1.25,
        }}
        variant="text"
      >
        <Typography variant="button2">Clear</Typography>
      </Button>
    </Stack>
  )
}

export default SelectClearButton
