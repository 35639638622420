import { Stack, Typography } from '@mui/material'

type AuthNotificationProps = {
  Icon?: any
  authError?: any
  authNotification?: any
}

const AuthNotification = ({
  Icon,
  authError,
  authNotification,
}: AuthNotificationProps) => {
  const type = authError ? 'error' : 'notification'
  const message = authError || authNotification
  const backgroundColor = type === 'error' ? '#EDB7B7' : '#D9EBE7'
  const color = type === 'error' ? '#FF0000' : '#009A75'

  return message ? (
    <Stack
      alignItems="center"
      px={1.75}
      py={2.5}
      spacing={1}
      sx={{ backgroundColor, borderRadius: 2 }}
    >
      {Icon ? <Icon sx={{ color }} /> : null}
      <Typography
        sx={{ color, fontSize: 11, fontWeight: 700, lineHeight: '15px' }}
      >
        {message}
      </Typography>
    </Stack>
  ) : null
}

export default AuthNotification
