import { ReactNode } from 'react'
import {
  IconButton,
  Skeleton,
  Stack,
  type SxProps,
  Typography,
  useTheme,
} from '@mui/material'

import ChevronLeftOutlinedIcon from '@/components/Common/BoxIcon/outlined/ChevronLeft'
import { whiteTransparentGradient } from '@/theme/background'

import NeonIcon from '../../../packages/neon/NeonIcon'
import NeonTypography from '../../../packages/neon/NeonTypography'

type PageHeaderProps = {
  actions?: ReactNode
  loading?: boolean
  onBackButtonClick?: () => void
  size?: 'xs' | 'small' | 'medium'
  subtitle?: string
  subtitleLoading?: boolean
  sx?: SxProps
  title: string
}

const PageHeader = ({
  actions,
  loading = false,
  onBackButtonClick,
  size = 'medium',
  subtitle,
  subtitleLoading = false,
  sx,
  title,
}: PageHeaderProps) => {
  const theme = useTheme()
  const darkMode = theme.palette.mode === 'dark'

  // TODO is there a name for this type of button?
  const iconComponent = darkMode ? (
    <NeonIcon
      actionable={true}
      color="primary"
      component={ChevronLeftOutlinedIcon}
    />
  ) : (
    <IconButton
      onClick={onBackButtonClick}
      sx={{
        background: whiteTransparentGradient,
        border: (theme) => `1px solid ${theme.palette.grey[50]}`,
        boxSizing: 'border-box',
        height: 40,
        width: 40,
      }}
    >
      <ChevronLeftOutlinedIcon color="primary" />
    </IconButton>
  )

  const TextComponent = darkMode ? NeonTypography : Typography

  const getTitleVariant = () => {
    switch (size) {
      case 'xs':
        return 'subtitle1Semibold'
      case 'small':
        return 'h6'
      case 'medium':
        return 'h4'
    }
  }

  return (
    <Stack direction="row" justifyContent="space-between" mb={4} sx={sx}>
      <Stack alignItems="center" direction="row" spacing={2}>
        {onBackButtonClick ? iconComponent : null}
        <Stack>
          <TextComponent variant={getTitleVariant()}>
            {loading ? <Skeleton width={240} /> : title}
          </TextComponent>
          {subtitle || subtitleLoading ? (
            <Typography
              sx={{ color: (theme) => theme.palette.grey[500] }}
              variant="body2"
            >
              {subtitleLoading ? <Skeleton width={160} /> : subtitle}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
      {actions}
    </Stack>
  )
}

export default PageHeader
