import { Box, Stack } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { map } from 'lodash'

import NotificationSectionGroup from './NotificationSectionGroup'
import CallInsightChip from '../Call/CallInsights/CallInsightChip'

type Keyword = {
  count: number
  title: string
}
// TODO remove readonly after having real data
type NotificationCallKeywordsProps = {
  keywords?: readonly Keyword[]
  objections?: readonly Keyword[]
}
const Group = ({
  children,
  title,
}: React.PropsWithChildren<{ title: string }>) => {
  return (
    <NotificationSectionGroup title={title}>
      <Stack direction="row" flexWrap="wrap" gap={1}>
        {children}
      </Stack>
    </NotificationSectionGroup>
  )
}
const NotificationCallKeywords = ({
  keywords,
  objections,
}: NotificationCallKeywordsProps) => {
  return (
    <Stack>
      <Grid2 container rowSpacing={3} spacing={3} width={1}>
        {!!objections && (
          <Grid2 md={6} xs={12}>
            <Group title="Objections">
              {map(objections, ({ count, title }) => (
                <CallInsightChip key={title} {...{ count, title }} />
              ))}
            </Group>
          </Grid2>
        )}
        {!!keywords && (
          <Grid2 md={6} xs={12}>
            <Group title="Keywords">
              {keywords.map(({ count, title }) => (
                <CallInsightChip key={title} {...{ count, title }} />
              ))}
            </Group>
          </Grid2>
        )}
      </Grid2>

      <Box></Box>
    </Stack>
  )
}

export default NotificationCallKeywords
