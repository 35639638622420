import { useMediaQuery, useTheme } from '@mui/material'
import { skipToken } from '@reduxjs/toolkit/query'
import { Link, useParams } from 'react-router-dom'

import { useAuth } from '../../../context/AuthContext'
import { useGetUserByFirebaseIdQuery } from '../../../services/hasura/users'
import Avatar from '../Avatar/Avatar'

const UserAvatar = () => {
  const { organizationId } = useParams()
  const { profile, user } = useAuth()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const { data: hasuraUser, isLoading: isHasuraUserLoading } =
    useGetUserByFirebaseIdQuery(user?.uid || skipToken, { skip: !user?.uid })

  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={`/organization/${organizationId}/settings`}
    >
      <Avatar
        alt={hasuraUser?.name || ''}
        loading={isHasuraUserLoading}
        size={mdUp ? 36 : 32}
        src={profile?.picture || undefined}
      />
    </Link>
  )
}

export default UserAvatar
