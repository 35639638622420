import { SelectChangeEvent } from '@mui/material'

import type { FilterConfig } from './filters/types'
import useFilters from './filters/useFilters'

export const getPaginationCacheKey = (slug: string) => `${slug}_pagination`

export type UsePagination = {
  handleNextButtonClick: () => void
  handlePageChange: (event: SelectChangeEvent) => void
  handlePrevButtonClick: () => void
  limit: number
  offset: number
  page: string
}

export type PaginationOptions = {
  cache?: boolean
  cacheKey?: string
  limit?: number
  page?: string
}
const config = {
  limit: {
    type: 'number',
    required: true,
  },
  page: {
    required: true,
  },
} satisfies FilterConfig
const usePagination = (options?: PaginationOptions) => {
  const { filters, selects, setFilter } = useFilters(config, {
    defaultValues: {
      limit: options?.limit || 10,
      page: options?.page || '1',
    },
    cache: options?.cache,
    cacheKey: options?.cacheKey,
  })

  const { limit, page } = filters

  const handlePageChange = (event: SelectChangeEvent) => {
    selects.pageSelect.onChange(event.target.value)
  }

  const handlePrevButtonClick = () => {
    setFilter('page', (Number(page) - 1).toString())
  }

  const handleNextButtonClick = () => {
    setFilter('page', (Number(page) + 1).toString())
  }

  const offset = (Number(page) - 1) * limit

  return {
    handleNextButtonClick,
    handlePageChange: handlePageChange,
    handlePrevButtonClick,
    limit,
    offset,
    page,
  } as UsePagination
}

export default usePagination
