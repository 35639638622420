import type { MenuHorizontalAlign, SelectItem, SelectListItem } from './types'
import type { SelectOption } from '../../../hooks/MUI/useSelect'

export const groupOptions = (options: SelectItem[]): SelectListItem[] => {
  const groupMap = options.reduce(
    (acc, option) => {
      const group = option.group || ''
      if (!acc[group]) {
        acc[group] = {
          isHidden: true,
          options: [],
        }
      }

      const accGroup = acc[group]
      if (!accGroup) return acc
      // group is visible as long as one of its options is visible
      if (!option.isHidden) {
        accGroup.isHidden = false
      }
      accGroup.options.push(option)
      return acc
    },
    {} as Record<string, { isHidden?: boolean; options: SelectOption[] }>
  )

  return Object.keys(groupMap).flatMap((groupKey) => {
    const group = groupMap[groupKey]
    if (!group) return []
    return [
      ...(groupKey
        ? [
            {
              type: 'title',
              label: groupKey,
              isHidden: group.isHidden,
            } as const,
          ]
        : []),
      ...group.options,
    ]
  })
}

export const getFilteredOption = (
  option: SelectOption,
  filter: string
): SelectItem => {
  const isHidden = !option.label.toLowerCase().includes(filter.toLowerCase())
  return {
    ...option,
    isHidden,
  }
}

export const getMenuAlignProps = (x: MenuHorizontalAlign) =>
  ({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: x,
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: x,
    },
  }) as const
