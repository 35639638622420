import type React from 'react'
import { CircularProgress, Stack, Typography } from '@mui/material'

type WithLoaderProps = {
  children: (() => React.ReactNode) | React.ReactNode
  error?: Error | null | string
  isLoading?: boolean
  loader?: React.ReactNode
}
const WithLoader = ({
  children,
  error,
  isLoading,
  loader = <CircularProgress />,
}: WithLoaderProps) => {
  // TODO add styled error message
  if (error) {
    return (
      <Typography
        sx={{ color: (theme) => theme.palette.error.main, textAlign: 'center' }}
      >
        {typeof error === 'string' ? error : error.message}
      </Typography>
    )
  }
  if (isLoading) {
    return (
      <Stack alignItems="center" height={1} justifyContent="center" width={1}>
        {loader}
      </Stack>
    )
  }
  return <>{typeof children === 'function' ? children() : children}</>
}

export default WithLoader
