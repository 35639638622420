export enum Role {
  admin = 'admin',
  member = 'member',
  owner = 'owner',
}

export type UserRole = keyof typeof Role
export type User = {
  agentId: string | null
  createdDate: string | null
  firebaseUid: string
  id: string
  name: string | null
  organizationId: string | null
  picture: string | null
  role: Role
  // updated_date: string
}

export type UserInput = {
  agent_id: string | null
  email: string
  // created_date: string
  firebase_uid: string
  id: string
  name: string | null
  organization_id: string | null
  picture: string | null
  role: Role
  // updated_date: string
}
