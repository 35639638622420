import { Stack } from '@mui/material'

import NotificationCallCard from './NotificationCallCard'
import NotificationSectionGroup from './NotificationSectionGroup'

type NotificationCallTopRecentProps = {
  title: string
}
const NotificationCallTopRecent = ({
  title,
}: NotificationCallTopRecentProps) => {
  return (
    <NotificationSectionGroup title={title}>
      <Stack spacing={2}>
        <NotificationCallCard
          agentName="Agent"
          date={new Date().toISOString()}
          leadSource="Source"
          leadStage="Stage"
          rating={5}
        />
        <NotificationCallCard
          agentName="Agent"
          date={new Date().toISOString()}
          leadSource="Source"
          leadStage="Stage"
          rating={5}
        />
        <NotificationCallCard
          agentName="Agent"
          date={new Date().toISOString()}
          leadSource="Source"
          leadStage="Stage"
          rating={5}
        />
      </Stack>
    </NotificationSectionGroup>
  )
}

export default NotificationCallTopRecent
