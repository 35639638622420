import { Stack, Typography } from '@mui/material'

type NotificationCallListGroupProps = {
  title: string
}
const NotificationCallListGroup = ({
  children,
  title,
}: React.PropsWithChildren<NotificationCallListGroupProps>) => {
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2Semibold">{title}</Typography>
      {children}
    </Stack>
  )
}

export default NotificationCallListGroup
