import type { Theme } from '@mui/material'

import { PRIMARY_HOVER } from '@/theme/palette/action'

import type { SelectMenuSize } from './types'
import { button2 } from '../../../theme/Typography/styles'
import { MENU_BOX_SHADOW, SELECT_BOX_SHADOW } from '../../../theme/elevation'
import neutral from '../../../theme/palette/colors/neutral'
import { BUTTON_LARGE_HEIGHT, BUTTON_MEDIUM_HEIGHT } from '../Button/styles'

export const selectMenuItemStyles = ({
  hasXOffset = false,
}: { hasXOffset?: boolean } = {}) => ({
  ...button2,
  backgroundColor: 'transparent',
  color: neutral[800],
  px: hasXOffset ? 2 : 0,
  py: 1,
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  '&:hover': {
    backgroundColor: 'transparent',
    color: 'primary.main',
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  '&.Mui-selected': {
    color: 'primary.main',
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  '&.Mui-focusVisible': {
    background: 'transparent',
  },
})
export const selectMenuStyles = ({
  hasXOffset = true,
  menuSize = 'md',
  noScroll = false,
}: {
  hasXOffset?: boolean
  menuSize?: SelectMenuSize
  noScroll?: boolean
} = {}) => ({
  '.MuiMenu-list': {
    p: 0,
    ...(noScroll && {
      display: 'flex',
      flexDirection: 'column',
      height: 1,
      overflow: 'hidden',
    }),
  },
  '.MuiMenu-paper': {
    borderRadius: 4,
    boxShadow: `inset 0 0 0 1px ${neutral[20]}, ${MENU_BOX_SHADOW}`,
    mt: 1,
    px: hasXOffset ? 2 : 0,
    py: 2,
    width: menuSize === 'md' ? 240 : menuSize === 'lg' ? 400 : 'auto',
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    '.MuiMenuItem-root': selectMenuItemStyles({ hasXOffset: !hasXOffset }),
    maxHeight: 744,
    ...(noScroll && {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    }),
  },
})

type SelectButtonStylesParams = {
  isActive?: boolean
  isOutlined?: boolean
  minWidth?: number
  size: 'medium' | 'large'
  theme: Theme
  valueOpacity?: number
}
export const selectButtonStyles = ({
  isActive,
  isOutlined,
  minWidth,
  size,
  theme,
  valueOpacity,
}: SelectButtonStylesParams) => ({
  ...button2,
  backgroundColor: isActive ? 'primary.main' : 'background.default',
  color: isActive ? 'common.white' : theme.palette.grey[500],
  height: size === 'medium' ? BUTTON_MEDIUM_HEIGHT : BUTTON_LARGE_HEIGHT,
  transition: 'background-color 0.2s ease-in-out',
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  '&:hover': {
    backgroundColor: isActive ? 'primary.main' : `${PRIMARY_HOVER}!important`,
    color: isActive ? 'common.white' : theme.palette.grey[500],
  },
  borderRadius: 10,
  boxShadow: isOutlined ? 'none' : SELECT_BOX_SHADOW,
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  '.MuiSelect-select': {
    opacity: valueOpacity,
    py: 0.75,
  },
  minWidth,
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  '.MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline': {
    border: isOutlined ? `1px solid ${theme.palette.grey[50]}` : 'none',
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  '& .MuiButton-endIcon': {
    paddingLeft: '8px',
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  '& .MuiSvgIcon-root.MuiSvgIcon-root': {
    color: isActive ? 'currentColor' : theme.palette.grey[800],
    transition: 'all 100ms',
  },
  '&[aria-expanded="true"]': {
    '& .MuiSvgIcon-root': {
      transform: 'rotate(180deg)',
    },
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  '&.MuiButton-root:hover': {
    boxShadow: isOutlined ? 'none' : SELECT_BOX_SHADOW,
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  '&.MuiButton-root:active': {
    boxShadow: isOutlined ? 'none' : SELECT_BOX_SHADOW,
  },
})

type SelectMenuLabelStylesParams = {
  hidden?: boolean
  size: 'medium' | 'large'
  theme: Theme
  value?: any
}
export const selectMenuLabelStyles = ({
  hidden,
  size,
  theme,
  value,
}: SelectMenuLabelStylesParams) => ({
  ...button2,
  transform:
    size === 'medium'
      ? 'translate(14px, 5px) scale(1)'
      : 'translate(14px, 9px) scale(1)',
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  '&[data-shrink="true"]': {
    transform: value
      ? 'scale(0)'
      : size === 'medium'
        ? 'translate(14px, 5px) scale(1)'
        : 'translate(14px, 9px) scale(1)',
  },
  minWidth: 'max-content',
  ...(hidden && {
    visibility: 'hidden',
  }),
  '&.Mui-focused': {
    color: theme.palette.grey[500],
  },
})
