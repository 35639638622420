import type { BillingPortalSession } from '@/models/stripe/BillingPortalSession'
import type { CheckoutSession } from '@/models/stripe/CheckoutSession'
import type { InvoiceWithProduct } from '@/models/stripe/Invoice'
import type { Product } from '@/models/stripe/Product'

import { shiloApi } from '../clients/shilo'

export const shiloPaymentAPI = shiloApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query<
      InvoiceWithProduct[],
      { organizationId: string }
    >({
      extraOptions: { includeToken: true },
      query: ({ organizationId }) => ({
        url: `/payment/invoices/${organizationId}`,
      }),
      transformResponse: (response: { data: InvoiceWithProduct[] }) =>
        response.data,
    }),
    getStripeBillingPortalSession: builder.query<
      BillingPortalSession,
      { organizationId: string }
    >({
      extraOptions: { includeToken: true },
      query: ({ organizationId }) => ({
        url: `/payment/billing-portal-session/${organizationId}`,
        params: { returnURL: window.location.href },
      }),
    }),
    getStripeSetupSession: builder.query<
      CheckoutSession,
      { email: string; organizationId: string; priceId: string }
    >({
      query: ({ email, organizationId, priceId }) => ({
        url: `/payment/setup-session`,
        params: { email, organizationId, priceId },
      }),
    }),
    getStripeProducts: builder.query<Product[], void>({
      query: () => ({
        url: `/payment/products`,
      }),
    }),
  }),
})

export const {
  useGetInvoicesQuery,
  useGetStripeBillingPortalSessionQuery,
  useGetStripeProductsQuery,
  useGetStripeSetupSessionQuery,
} = shiloPaymentAPI
