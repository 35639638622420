const tertiary = {
  '50': '#e7effc',
  '100': '#c8d8e7',
  '200': '#abbdd0',
  '300': '#8ca2b9',
  '400': '#758da7',
  '500': '#5e7a96',
  '600': '#506b85',
  '700': '#41586e',
  '800': '#324558',
  '900': '#203040',
  A100: '#6eacff',
  A200: '#3b8fff',
  A400: '#0872ff',
  A700: '#0066ed',
}

export default tertiary
