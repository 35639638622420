import { Button, ButtonProps } from '@mui/material'

import NeonTypography from './NeonTypography'

type NeonOutlinedButtonProps = ButtonProps

const NeonOutlinedButton = ({
  children,
  ...restButtonProps
}: NeonOutlinedButtonProps) => {
  return (
    <Button
      {...restButtonProps}
      size="large"
      sx={{
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
        borderRadius: 21,
      }}
      variant="outlined"
    >
      <NeonTypography>{children}</NeonTypography>
    </Button>
  )
}

export default NeonOutlinedButton
