import { forwardRef } from 'react'
import {
  Box,
  IconButton,
  Stack,
  SvgIconTypeMap,
  SxProps,
  useTheme,
  type Theme,
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { Link } from 'react-router-dom'

import SideMenuItemBackground from './SideMenuItemBackground'
import useSideMenuItemSize from '../../../hooks/MUI/useSideMenuItemSize'
import NeonIcon from '../../../packages/neon/NeonIcon'
import { CONTENT_BACKGROUND_BOX_SHADOW } from '../../../theme/elevation'
import useHover from '../ListItem/useHover'

type SVGIcon = OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
type SideMenuItemProps = {
  ActiveIcon?: SVGIcon
  Icon: SVGIcon
  active: boolean
  iconSx?: SxProps
  label: string
  onClick?: (slug: string) => void
  slug: string
  to?: string
}

const SideMenuItem = forwardRef<HTMLDivElement, SideMenuItemProps>(
  ({ ActiveIcon, Icon, active, iconSx, label, onClick, slug, to }, ref) => {
    const theme = useTheme()
    const darkMode = theme.palette.mode === 'dark'

    const sideMenuItemSize = useSideMenuItemSize()

    const { handleMouseOut, handleMouseOver, hovered } = useHover()

    const IconElement = darkMode ? (
      <NeonIcon component={Icon} sx={{ fontSize: 32 }} />
    ) : (
      <Icon />
    )
    const ActiveIconElement = ActiveIcon ? (
      darkMode ? (
        <NeonIcon
          component={ActiveIcon}
          neonComponent={Icon}
          sx={{ fontSize: 32 }}
        />
      ) : (
        <ActiveIcon />
      )
    ) : null

    const isIconActive = active || hovered
    const shouldResetFillColor = !isIconActive && !ActiveIcon
    const getColor = () => {
      return isIconActive && !ActiveIcon
        ? theme.palette.primary.main
        : theme.palette.common.white
    }

    const handleClick = () => {
      onClick?.(slug)
    }

    const combinedIconSx = {
      fontSize: sideMenuItemSize / 2,
      ...iconSx,
    }

    return (
      <Stack
        component={to ? Link : 'div'}
        ref={ref}
        sx={{ position: 'relative' }}
        {...(to && { to })}
      >
        <IconButton
          aria-label={label}
          onClick={handleClick}
          onMouseOut={handleMouseOut}
          onMouseOver={handleMouseOver}
          sx={{
            ...(shouldResetFillColor && {
              path: {
                fill: 'currentColor',
              },
            }),
            borderRadius: 4,
            boxShadow: CONTENT_BACKGROUND_BOX_SHADOW,
            color: getColor(),
            height: sideMenuItemSize,
            transition: 'all .2s ease-in-out',
            width: sideMenuItemSize,
            zIndex: 2,
            // eslint-disable-next-line sort-keys-fix/sort-keys-fix
            '&:hover': {
              backgroundColor: 'inherit',
            },
          }}
        >
          {ActiveIcon ? (
            <Stack position="relative">
              <FloatIcon
                appearOnActive={false}
                icon={IconElement}
                isActive={isIconActive}
                sx={combinedIconSx}
              />
              <FloatIcon
                appearOnActive
                icon={ActiveIconElement}
                isActive={isIconActive}
                sx={combinedIconSx}
              />
            </Stack>
          ) : (
            <Box component={Icon} sx={combinedIconSx} />
          )}
        </IconButton>
        {!darkMode && (
          <SideMenuItemBackground active={active} hovered={hovered} />
        )}
      </Stack>
    )
  }
)

const FloatIcon = ({
  appearOnActive,
  icon,
  isActive,
  sx,
}: {
  appearOnActive: boolean
  icon: React.ReactNode
  isActive: boolean
  sx?: SxProps<Theme>
}) => {
  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        opacity: Number(!appearOnActive),
        ...(appearOnActive && {
          left: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }),
        transition: 'opacity 200ms ease-in-out',
        ...(isActive && {
          opacity: Number(appearOnActive),
        }),
      }}
    >
      {icon}
    </Box>
  )
}
export default SideMenuItem
