import { forwardRef, type ForwardedRef } from 'react'
import StarIcon from '@mui/icons-material/Star'
import {
  Skeleton,
  Stack,
  Typography,
  useTheme,
  type StackProps,
  type SxProps,
  type TypographyProps,
} from '@mui/material'

import NeonIcon from '../../../packages/neon/NeonIcon'
import NeonTypography from '../../../packages/neon/NeonTypography'
import yellowOrange from '../../../theme/palette/colors/yellowOrange'

type RatingProps = StackProps & {
  decimal?: boolean
  iconSx?: SxProps
  loading?: boolean
  rating?: number
  spacing?: number
  textVariant?: TypographyProps['variant']
}

const Rating = forwardRef(
  (
    {
      decimal,
      iconSx,
      loading = false,
      rating,
      spacing = 1,
      sx,
      textVariant = 'caption',
      ...rest
    }: RatingProps,
    ref: ForwardedRef<any>
  ) => {
    const theme = useTheme()
    const darkMode = theme.palette.mode === 'dark'
    const iconComponent = darkMode ? (
      <NeonIcon
        component={StarIcon}
        fontSize="small"
        sx={{ color: yellowOrange[800], ...iconSx }}
      />
    ) : (
      <StarIcon fontSize="small" sx={{ color: yellowOrange[800], ...iconSx }} />
    )

    const textComponent = darkMode ? (
      <NeonTypography color={yellowOrange[800]} variant={textVariant}>
        {decimal ? rating?.toFixed(1) : rating}
      </NeonTypography>
    ) : (
      <Typography color={yellowOrange[800]} variant={textVariant}>
        {decimal ? rating?.toFixed(1) : rating}
      </Typography>
    )

    return (
      <Stack
        alignItems="center"
        direction="row"
        ref={ref}
        spacing={spacing}
        {...rest}
      >
        {loading ? (
          <Typography variant="caption">
            <Skeleton width={34} />
          </Typography>
        ) : null}
        {!loading && rating ? (
          <Stack alignItems="center" direction="row" spacing={spacing}>
            {iconComponent}
            {textComponent}
          </Stack>
        ) : null}
      </Stack>
    )
  }
)

export default Rating
