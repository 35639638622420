import { useContext } from 'react'
import { IconButton, useMediaQuery, useTheme } from '@mui/material'

import LogoutOutlinedIcon from '../../../assets/icons/logout-outlined-icon.svg?react'
import { AuthContext } from '../../../context/AuthContext'

const LogoutButton = () => {
  const { logout } = useContext(AuthContext)
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const handleClick = async () => {
    await logout()
    sessionStorage.clear()
    // navigate handled by ProtectedRoutes logic, otherwise there's a blip
    //navigate('/login')
  }

  return (
    <IconButton
      aria-label="logout"
      onClick={handleClick}
      sx={{ fontSize: mdUp ? 32 : 20 }}
    >
      <LogoutOutlinedIcon color="white" />
    </IconButton>
  )
}

export default LogoutButton
