import { useCallback, useMemo } from 'react'

import { useAuth } from '../context/AuthContext'
import type { UserRole } from '../models/hasura/User'

// type CanIDoRules = 'filter:team' | 'filter:group'

const useAccessControl = () => {
  const { isUserLoading, profile, user } = useAuth()

  const hasRole = useCallback(
    (roleToCheck: UserRole | UserRole[]) => {
      const roles = Array.isArray(roleToCheck) ? roleToCheck : [roleToCheck]
      return roles.some((role) => profile?.role === role)
    },
    [profile]
  )

  const isAdmin = useMemo(() => hasRole('admin'), [hasRole])
  const isOwner = useMemo(() => hasRole('owner'), [hasRole])
  const isMember = useMemo(() => hasRole('member'), [hasRole])
  const isOwnerOrAdmin = useMemo(() => hasRole(['admin', 'owner']), [hasRole])

  // maybe individual rules for each filter/rule?
  // later can add rules like 'members:invite'/ 'members:remove' etc
  // const rules = useRef({
  //   'filter:team': () => hasRole(['owner', 'admin']),
  //   'filter:group': () => hasRole(['owner', 'admin']),
  // } satisfies Record<CanIDoRules, () => boolean>);

  // const canIDo = useCallback((rule: CanIDoRules) => {
  //   return rules.current[rule]();
  // }, []);

  return {
    hasRole,
    profile,
    user,
    isAdmin,
    isOwner,
    isMember,
    isOwnerOrAdmin,
    isUserLoading,
  }
}

export default useAccessControl
