import NotificationCallActionPoints from '../NotificationCallActionPoints'
import NotificationCallAgentCoaching from '../NotificationCallAgentCoaching'
import NotificationCallGreetings from '../NotificationCallGreetings'
import NotificationCallInfo from '../NotificationCallInfo'
import NotificationCallOutcome from '../NotificationCallOutcome'
import NotificationCallOverview from '../NotificationCallOverview'
import NotificationCallRating from '../NotificationCallRating'
import NotificationCallSummary from '../NotificationCallSummary'
import NotificationDivider from '../NotificationDivider'

type NotificationCallRatingOutRangeProps = {
  notification: TODO
}
const NotificationCallRatingOutRange = ({
  notification,
}: NotificationCallRatingOutRangeProps) => {
  const divider = <NotificationDivider />
  return (
    <>
      <NotificationCallGreetings
        date={notification.date}
        personName={notification.personName}
      />
      <NotificationCallInfo
        clientName="Frank Warren"
        sourceName="Google Location Services (GLS)"
        stageName="Application Accepted (Rental)"
      />
      {divider}
      <NotificationCallRating rating={4} ratingTitle="This call" />
      <NotificationCallOverview
        fillerWords={{
          title: notification.fillerWords,
          trend: 25,
        }}
        incoming={notification.incoming}
        monologues={notification.monologues}
        questionsAsked={notification.questionsAsked}
        talkToListen={[100 - notification.listening, notification.listening]}
        totalTalkTime={notification.talking}
      />
      {divider}
      <NotificationCallSummary summary={notification.summary} />
      {divider}
      <NotificationCallOutcome outcomes={notification.outcomes} />
      {divider}
      <NotificationCallAgentCoaching
        topMoments={notification.topMoments}
        topPossibleImprovements={notification.topPossibleImprovements}
      />
      {divider}
      <NotificationCallActionPoints
        additionalItems={notification.additionalItems}
        mostImportant={notification.mostImportant}
      />
      {divider}
    </>
  )
}

export default NotificationCallRatingOutRange
