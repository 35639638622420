const LogoWhite32 = () => {
  return (
    <svg
      fill="none"
      height="32"
      viewBox="0 0 18 32"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9804 7.99996C16.9804 7.99996 14.047 9.12589 11.1581 10.9778C9.73592 11.8815 8.31366 12.9778 7.2618 14.1926C5.54327 16.1778 4.9211 18.6074 6.80254 21.037C0.713664 19.5556 -5.76041 11.8815 8.98033 0L16.9804 7.99996Z"
        fill="white"
      />
      <path
        d="M10.699 17.822C9.64717 19.0369 8.22498 20.1183 6.80271 21.0368C4.92127 18.5924 5.54344 16.1776 7.26197 14.1924C8.31383 12.9775 9.73609 11.896 11.1583 10.9775C13.0398 13.4072 12.4323 15.822 10.699 17.822Z"
        fill="#9F9F9F"
      />
      <path
        d="M8.98007 31.9999L0.980103 24C0.980103 24 3.9134 22.874 6.80228 21.0222C8.22455 20.1036 9.64674 19.0221 10.6986 17.8073C12.4171 15.8221 13.0394 13.3925 11.1578 10.9629C17.2616 12.4444 23.7357 20.1184 8.98007 31.9999Z"
        fill="white"
      />
    </svg>
  )
}

export default LogoWhite32
