import { useCallback } from 'react'

import {
  resetFiltersCache,
  setFiltersCache,
  useFiltersCacheByKeySelector,
  type FilterCacheValues,
} from '../../app/features/filtersCache/filtersCacheSlice'
import { useAppDispatch, useAppSelector } from '../../app/store'

const useFiltersCache = <T extends FilterCacheValues>(key: string) => {
  const initialFilters = useAppSelector(useFiltersCacheByKeySelector(key)) as
    | T
    | undefined

  const dispatch = useAppDispatch()

  const cache = useCallback(
    (data: T) => {
      dispatch(
        setFiltersCache({
          key,
          data,
        })
      )
    },
    [dispatch, key]
  )

  const reset = useCallback(
    (keyToReset: string = key) => {
      dispatch(
        resetFiltersCache({
          key: keyToReset,
        })
      )
    },
    [dispatch, key]
  )
  return { initialFilters, cache, reset }
}

export default useFiltersCache
