import { Stack, Typography } from '@mui/material'

import EmailSentOutlinedIcon from '../../../assets/icons/email-sent-outlined-icon.svg?react'
import PageModalHeaderText from '../../UIKit/Page/PageModalHeaderText'
import LoginContent from '../LoginContent'
import LoginHeader from '../LoginHeader'
import { INPUT_WIDTH } from '../consts'

type CheckInboxProps = {
  email: string
}

const CheckInbox = ({ email }: CheckInboxProps) => {
  const handleRedirectToSignUp = () => {
    // TODO - Implement the logic for redirect to the Sign Up page
  }

  return (
    <LoginContent
      onBack={handleRedirectToSignUp}
      onClose={handleRedirectToSignUp}
    >
      <LoginHeader Icon={EmailSentOutlinedIcon} sx={{ pb: 0 }}>
        <Stack alignItems="center" width={INPUT_WIDTH}>
          <PageModalHeaderText>Check your inbox</PageModalHeaderText>
          <Stack py={1} textAlign="center">
            <Typography variant="body2">
              Please open the link in the email to continue that we sent to
            </Typography>
          </Stack>
          <Typography color="primary" variant="subtitle2Semibold">
            {email}
          </Typography>
        </Stack>
      </LoginHeader>
    </LoginContent>
  )
}

export default CheckInbox
