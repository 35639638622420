export const toSnakeCase = <T extends Record<string, any>>(
  obj: T
): DeepCamelToSnakeCase<T> => {
  return Object.keys(obj).reduce((acc, key) => {
    return {
      ...acc,
      [key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)]: obj[key],
    }
  }, {} as DeepCamelToSnakeCase<T>)
}
