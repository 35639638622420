import { Stack } from '@mui/material'

import LogoutButton from './LogoutButton'
import NotificationsButton from './NotificationsButton'
import UserAvatar from './UserAvatar'
import { SHOW_NOTIFICATIONS } from '../../..'
import LogoWhite32 from '../../../logos/LogoWhite32'
import LogoWhite64 from '../../../logos/LogoWhite64'
import SideMenu from '../SideMenu/SideMenu'

const SM_WIDTH = 72
const MD_WIDTH = 120

type SideBarProps = {
  logoSize: 32 | 64
}
const logos = {
  32: LogoWhite32,
  64: LogoWhite64,
}
const SideBar = ({ logoSize = 32 }: SideBarProps) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      sx={{
        height: '100vh',
        maxWidth: { md: MD_WIDTH, sm: SM_WIDTH },
        minWidth: { md: MD_WIDTH, sm: SM_WIDTH },
        py: 6,
      }}
    >
      <Stack alignItems="center" spacing={2}>
        {logos[logoSize]()}
        {SHOW_NOTIFICATIONS ? <NotificationsButton /> : null}
      </Stack>
      <SideMenu />
      <Stack alignItems="center" spacing={1}>
        <UserAvatar />
        <LogoutButton />
      </Stack>
    </Stack>
  )
}

export default SideBar
