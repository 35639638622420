import { useEffect, useState } from 'react'

import { auth } from '../../../config/firebase'

const useEmailVerification = () => {
  const [emailVerified, setEmailVerified] = useState<boolean>(
    Boolean(auth.currentUser?.emailVerified)
  )

  const emailVerificationInterval = () => {
    console.log('email verification ping')
    if (!auth.currentUser?.emailVerified) {
      return auth.currentUser?.reload().then(() => {
        setEmailVerified(Boolean(auth.currentUser?.emailVerified))
        if (auth.currentUser?.emailVerified) {
          console.log('Email successfully verified.')
        }
      })
    } else {
      return
    }
  }

  useEffect(() => {
    let id: any = undefined
    if (!emailVerified) {
      id = setInterval(emailVerificationInterval, 2000)
    } else {
      clearInterval(id)
    }
    return () => clearInterval(id)
  }, [emailVerified])

  return emailVerified
}

export default useEmailVerification
