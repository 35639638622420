import { IconButton, SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

type ContinueButtonProps = React.PropsWithChildren<{
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  onClick?: () => void
}>

const IconButtonLayout = ({ Icon, onClick }: ContinueButtonProps) => {
  return (
    <IconButton onClick={onClick} sx={{ height: 40, width: 40 }}>
      <Icon sx={{ fontSize: 18 }} />
    </IconButton>
  )
}

export default IconButtonLayout
