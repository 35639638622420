import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const findMinDate = <T extends { date: string }>(data: T[]): string | 0 => {
  if (data.length === 0) {
    return 0
  }
  return data.reduce(
    (min, item) => (item.date < min ? item.date : min),
    data[0]?.date ?? ''
  )
}

type BaseItem = { date: string }
type FillDateGapsParams<T extends BaseItem> = {
  data: T[]
  end?: string
  factory: (date: string) => T
  start?: string
  step?: number
}
export const fillDateGaps = <T extends BaseItem>({
  data,
  end,
  factory,
  start,
}: FillDateGapsParams<T>): T[] => {
  const result: T[] = []

  const startDate = dayjs(start || findMinDate(data)).toDate()
  const endDate = dayjs(end).toDate()

  const dateMap = new Map<string, T>()

  data.forEach((item) => {
    dateMap.set(item.date, item)
  })

  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    const dateString = dayjs(d).format('YYYY-MM-DD')
    if (dateMap.has(dateString)) {
      result.push(dateMap.get(dateString)!)
    } else {
      result.push(factory(dateString))
    }
  }

  return result
}

export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:00:00+00:00'
export const fillDateTimeGaps = <T extends BaseItem>({
  data,
  end,
  factory,
  start,
  step = 24,
}: FillDateGapsParams<T>): T[] => {
  const result: T[] = []
  const dateMap = new Map<string, T>()

  data.forEach((item) => {
    dateMap.set(dayjs(item.date).utc().utc().format(DATE_TIME_FORMAT), item)
  })
  const startDate = dayjs(start ? new Date(start) : findMinDate(data))
    .utc()
    .startOf('day')
  const endDate = dayjs(end ? new Date(end) : undefined)
    .utc()
    .endOf('day')

  for (
    let d = startDate;
    d.isBefore(endDate) || d.isSame(endDate);
    d = d.add(step, 'hours')
  ) {
    const dateString = d.utc().format(DATE_TIME_FORMAT)
    if (dateMap.has(dateString)) {
      result.push(dateMap.get(dateString)!)
    } else {
      result.push(factory(dateString))
    }
  }
  return result
}
