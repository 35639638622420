const Logo1 = () => {
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 23 40"
      width="23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5128 9.99995C21.5128 9.99995 17.8461 11.4074 14.235 13.7222C12.4573 14.8519 10.6794 16.2222 9.3646 17.7408C7.21644 20.2222 6.43873 23.2593 8.79053 26.2963C1.17943 24.4444 -6.91316 14.8519 11.5128 0L21.5128 9.99995Z"
        fill="url(#paint0_linear_2013_11161)"
      />
      <path
        d="M13.6612 22.2778C12.3464 23.7963 10.5687 25.1481 8.79084 26.2962C6.43903 23.2407 7.21674 20.2222 9.3649 17.7407C10.6797 16.2222 12.4576 14.8703 14.2353 13.7222C16.5872 16.7592 15.8279 19.7777 13.6612 22.2778Z"
        fill="#394694"
      />
      <path
        d="M11.5124 39.9999L1.51245 29.9999C1.51245 29.9999 5.17908 28.5925 8.79018 26.2777C10.568 25.1296 12.3457 23.7777 13.6606 22.2591C15.8087 19.7777 16.5865 16.7406 14.2346 13.7036C21.8643 15.5555 29.9569 25.148 11.5124 39.9999Z"
        fill="url(#paint1_linear_2013_11161)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2013_11161"
          x1="16.1962"
          x2="-0.403274"
          y1="2.62278"
          y2="23.584"
        >
          <stop stopColor="#B7C1FC" />
          <stop offset="1" stopColor="#4F65F0" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_2013_11161"
          x1="17.4316"
          x2="0.83458"
          y1="16.3264"
          y2="37.2982"
        >
          <stop stopColor="#B7C1FC" />
          <stop offset="1" stopColor="#4F65F0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Logo1
