import { Link, Stack, Typography } from '@mui/material'

export const LOGIN_PAGE_FOOTER_HEIGHT = 80

const LoginPageFooter = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        maxHeight: LOGIN_PAGE_FOOTER_HEIGHT,
        minHeight: LOGIN_PAGE_FOOTER_HEIGHT,
        zIndex: 1,
      }}
    >
      <Stack direction="row" spacing={1}>
        <Link
          href="https://app.termly.io/document/privacy-policy/9e1a0070-1423-4c36-8a2d-745ea1ad6aa7"
          rel="noreferrer"
          sx={{ color: (theme) => theme.palette.background.default }}
          target="_blank"
          underline="none"
          variant="caption"
        >
          Privacy Policy
        </Link>
        <Link
          href="https://app.termly.io/document/cookie-policy/dfaf7524-48d8-462f-a082-1cd1ba8da724"
          rel="noreferrer"
          sx={{ color: (theme) => theme.palette.background.default }}
          target="_blank"
          underline="none"
          variant="caption"
        >
          Cookies
        </Link>
        <Link
          href="https://app.termly.io/document/terms-of-service/29af4708-d94e-4fef-b0c3-f5de4a47e03d"
          rel="noreferrer"
          sx={{ color: (theme) => theme.palette.background.default }}
          target="_blank"
          underline="none"
          variant="caption"
        >
          Terms
        </Link>
        <Link
          href="https://app.termly.io/document/acceptable-use/859621e6-6c19-4bfe-9bad-e091dfb265a4"
          rel="noreferrer"
          sx={{ color: (theme) => theme.palette.background.default }}
          target="_blank"
          underline="none"
          variant="caption"
        >
          Acceptable Use
        </Link>
      </Stack>

      <Typography
        sx={{ color: (theme) => theme.palette.common.white }}
        variant="caption"
      >
        © 2024 Shilo
      </Typography>
    </Stack>
  )
}

export default LoginPageFooter
