import CheckIcon from '@mui/icons-material/Check'
import { MenuItem, Stack } from '@mui/material'
import clsx from 'clsx'

import { SelectProps } from './Select'
import SelectTitle from './SelectTitle'
import { SelectItem, SelectListItem } from './types'

type SelectMenuItemProps<T extends string = string> = Pick<
  SelectProps,
  'renderOption'
> & {
  handleItemClick: (value: string | undefined) => void
  option: SelectListItem
  value?: T
}

const SelectMenuItem = <T extends string = string>({
  handleItemClick,
  option,
  renderOption = (option) => option.label,
  value,
}: SelectMenuItemProps) => {
  const hiddenStyles = option.isHidden ? { display: 'none' } : {}
  if ('type' in option && option.type === 'title') {
    return (
      <SelectTitle hasBorder key={option.label} sx={hiddenStyles}>
        {option.label}
      </SelectTitle>
    )
  }

  const onClick = () => {
    handleItemClick(option.value as T)
  }

  const checkIcon =
    option.value === value ? (
      <CheckIcon color="inherit" sx={{ fontSize: 18 }} />
    ) : null
  return (
    <MenuItem
      className={clsx({ 'Mui-selected': option.value === value })}
      key={option.value}
      onClick={onClick}
      sx={{
        display: 'flex',
        ...hiddenStyles,
      }}
    >
      <Stack direction="row" flex={1} gap={1}>
        {option.appendSlot ? checkIcon : null}
        {renderOption(option as SelectItem)}
        {!option.appendSlot ? checkIcon : null}
      </Stack>
      {option.appendSlot}
    </MenuItem>
  )
}

export default SelectMenuItem
