import { Typography, type SxProps } from '@mui/material'

type SelectTitleProps = React.PropsWithChildren<{
  hasBorder?: boolean
  sx?: SxProps
}>

const SelectTitle = ({ children, hasBorder, sx }: SelectTitleProps) => {
  return (
    <Typography
      sx={{
        color: (theme) => theme.palette.grey[500],
        display: 'inline-block',
        width: 1,
        ...(hasBorder && {
          boxShadow: (theme) => `inset 0 -1px 0 ${theme.palette.grey[50]}`,
          py: 1,
        }),
        ...sx,
      }}
      variant="overline"
    >
      {children}
    </Typography>
  )
}

SelectTitle.muiSkipListHighlight = true

export default SelectTitle
