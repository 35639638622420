import styled from '@emotion/styled'
import { Paper, Stack, Typography, type SxProps } from '@mui/material'

import constructionIllustration from '../../assets/construction-illustration.svg'
import { CONTENT_BACKGROUND } from '../../theme/backgroundImages'
import DialogButton from '../UIKit/Button/DialogButton'

type MobileWarningProps = {
  onClose: () => void
  sx?: SxProps
}

const StyledImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 294 / 200;
  position: relative;
`
const StyledImage = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: contain;
`
const MobileWarning = ({ onClose, sx }: MobileWarningProps) => {
  return (
    <Paper
      sx={{
        ...sx,
        alignItems: 'center',
        background: CONTENT_BACKGROUND,
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        justifyContent: 'center',
        p: 3,
        textAlign: 'center',
      }}
    >
      <StyledImageContainer>
        <StyledImage alt="" src={constructionIllustration} />
      </StyledImageContainer>
      <Stack spacing={1}>
        <Typography variant="h5">Uh oh!</Typography>
        <Typography variant="body2">
          Shilo is best experienced on a desktop or tablet.
        </Typography>
      </Stack>
      <DialogButton onClick={onClose} sx={{ width: 1 }} variant="contained">
        Proceed anyway
      </DialogButton>
    </Paper>
  )
}

export default MobileWarning
