// purple
//export const lightBackground = `linear-gradient(180deg,#553DF2 0%,#31238C 100%)`
// blue
export const lightBackground = `linear-gradient(180deg,#4B94F2 0%,#526AF2 100%)`

//const lightBackground = `linear-gradient(180deg,#F1F4F8 0%,#FFFFFF 100%)`
//const lightBackground = `radial-gradient(#3059b5, #1f1013)`
//`radial-gradient(${BACKGROUND_2}, #1f1013)`
//#3059b5
//#553DF2
//100%
//#31238C
//100%

export const whiteTransparentGradient =
  'linear-gradient(90deg, rgba(255, 255, 255, 0.72) 0.34%, rgba(255, 255, 255, 0.48) 100%)'
