import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { hasuraBaseUrl } from '..'

export const baseQuery = fetchBaseQuery({
  baseUrl: hasuraBaseUrl,
})

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const { auth } = await import('@/config/firebase')
  const user = auth.currentUser
  const token = user ? await user.getIdToken() : ''
  const extendedArgs =
    typeof args === 'object' && token
      ? {
          ...args,
          headers: {
            ...args.headers,
            Authorization: `Bearer ${token}`,
          },
        }
      : args
  if (
    extraOptions &&
    'includeUserId' in extraOptions &&
    extraOptions.includeUserId &&
    extendedArgs &&
    typeof extendedArgs === 'object' &&
    'body' in extendedArgs &&
    typeof extendedArgs.body === 'object' &&
    'object' in extendedArgs.body &&
    typeof extendedArgs.body.object === 'object' &&
    user
  ) {
    extendedArgs.body.object = {
      ...extendedArgs.body.object,
      auth_user_id: user.uid,
    }
  }
  const result = await baseQuery(extendedArgs, api, extraOptions)
  return result
}

export const hasuraApi = createApi({
  reducerPath: 'hasuraApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'AgentPref',
    'Integrations',
    'Invitation',
    'Organization',
    'StagePref',
    'User',
    'InvitationTotal',
    'UserTotal',
    'Identity',
    'NotificationPref',
  ],
  endpoints: () => ({}),
})
