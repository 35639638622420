import { useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import { useBoolean } from 'usehooks-ts'

import FloatLogoutButton from './FloatLogoutButton'

const LoginSpinner = () => {
  const { setFalse, setTrue, value: hasCloseAction } = useBoolean(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setTrue()
    }, 5000)
    return () => clearTimeout(timer)
  }, [setTrue])

  const handleClick = () => {
    setFalse()
  }
  return (
    <>
      <CircularProgress />
      {hasCloseAction ? <FloatLogoutButton onClick={handleClick} /> : null}
    </>
  )
}

export default LoginSpinner
