import { Box, Stack } from '@mui/material'

import useSideMenuItemSize from '../../../hooks/MUI/useSideMenuItemSize'

type SideMenuItemBackgroundProps = {
  active: boolean
  hovered: boolean
}

const SideMenuItemBackground = ({
  active,
  hovered,
}: SideMenuItemBackgroundProps) => {
  const sideMenuItemSize = useSideMenuItemSize()

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        left: active || hovered ? 0 : sideMenuItemSize / 2,
        position: 'absolute',

        top: active || hovered ? 0 : sideMenuItemSize / 2,
        transition: 'all .2s ease-in-out',
      }}
    >
      <Box
        sx={{
          background: (theme) =>
            hovered || active ? theme.palette.background.paper : 'transparent',
          borderRadius: 4,
          height: hovered || active ? sideMenuItemSize : 0,
          transition: 'all .2s ease-in-out',
          width: hovered || active ? sideMenuItemSize : 0,
          zIndex: 1,
        }}
      />
    </Stack>
  )
}

export default SideMenuItemBackground
