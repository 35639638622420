import { Box, Stack, Typography, type SxProps } from '@mui/material'
import dayjs from 'dayjs'

import DownloadIcon from '@/assets/icons/download-outlined-icon.svg?react'
import ShareIcon from '@/assets/icons/share-outlined-icon.svg?react'
import TrashIcon from '@/assets/icons/trash-outlined-icon.svg?react'

import ActionButton from '../UIKit/ActionButton/ActionButton'

type NotificationHeaderProps = {
  date: string
  sx?: SxProps
  title: string
}
const NotificationHeader = ({ date, sx, title }: NotificationHeaderProps) => {
  return (
    <Stack
      direction="row"
      gap={2}
      justifyContent="space-between"
      pl={1}
      sx={sx}
    >
      <Box>
        <Typography variant="h6">{title}</Typography>
        <Typography color={(theme) => theme.palette.grey[500]} variant="body2">
          {dayjs(date).format('MMMM DD, YYYY, hh:mm A')}
        </Typography>
      </Box>
      <Stack direction="row" spacing={1}>
        <ActionButton>
          <ShareIcon />
        </ActionButton>
        <ActionButton>
          <DownloadIcon />
        </ActionButton>
        <ActionButton>
          <TrashIcon />
        </ActionButton>
      </Stack>
    </Stack>
  )
}

export default NotificationHeader
