import { Stack } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

import InfoBlock from '../Call/LeftPanel/InfoBlock'

type NotificationCallInfoProps = {
  clientName?: string
  isLoading?: boolean
  sourceName?: string
  stageName?: string
}
const NotificationCallInfo = ({
  clientName,
  isLoading,
  sourceName,
  stageName,
}: NotificationCallInfoProps) => {
  return (
    <Stack>
      <Grid2 container rowSpacing={3} spacing={2} width={1}>
        <Grid2 md={4} xs={12}>
          <InfoBlock loading={isLoading} title="Client Name">
            {clientName || 'Unknown'}
          </InfoBlock>
        </Grid2>
        <Grid2 md={4} xs={12}>
          <InfoBlock loading={isLoading} title="Lead Source">
            {sourceName || 'None'}
          </InfoBlock>
        </Grid2>
        <Grid2 md={4} xs={12}>
          <InfoBlock loading={isLoading} title="Lead Stage">
            {stageName || 'None'}
          </InfoBlock>
        </Grid2>
      </Grid2>
    </Stack>
  )
}

export default NotificationCallInfo
