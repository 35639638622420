import * as React from 'react'

interface useFormHelperTextProps {
  errorHelperText?: React.ReactNode
  helperText?: React.ReactNode
  // validator should return an error string if not validated
  validator?(): string | undefined
  // value is the most common use case for components i.e. TextField
  value?: string
}

function useFormHelperText({
  errorHelperText = '',
  helperText = '',
  validator,
  value = '',
}: useFormHelperTextProps) {
  const [error, setError] = React.useState<boolean>(false)

  // TODO fix
  const validate = () => (validator ? !validator() : true) /*Boolean(value)*/

  const getErrorText = () => (validator ? validator() : errorHelperText)

  const getHelperText = () => (error && getErrorText()) || helperText

  const handleBlur = (
    _event: React.FocusEvent<
      HTMLInputElement | HTMLParagraphElement | HTMLTextAreaElement
    >
  ): void => {
    if (!validate()) {
      setError(true)
    }
  }

  React.useEffect(() => {
    if (validate()) {
      setError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return {
    error,
    handleBlur,
    helperText: getHelperText(),
  }
}

export default useFormHelperText
