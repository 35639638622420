import { Stack } from '@mui/material'

import { body3 } from '@/theme/Typography/styles'

type SelectItemCounterProps = {
  count: number
  icon: React.ReactNode
}

const SelectItemCounter = ({ count, icon }: SelectItemCounterProps) => {
  return (
    <Stack alignItems="center" direction="row" gap={0.5} sx={body3}>
      {icon}
      {count}
    </Stack>
  )
}

export default SelectItemCounter
