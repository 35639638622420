import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { map } from 'lodash'

import NotificationCallListGroup from './NotificationCallListGroup'
import NotificationSectionGroup from './NotificationSectionGroup'
import BulletKeyPoint from '../Common/BulletKeyPoint/BulletKeyPoint'

type NotificationCallAgentCoachingProps = {
  topMoments: string[]
  topPossibleImprovements: string[]
}
const NotificationCallAgentCoaching = ({
  topMoments,
  topPossibleImprovements,
}: NotificationCallAgentCoachingProps) => {
  return (
    <NotificationSectionGroup title="Agent Coaching">
      <Grid2 container spacing={1} width={1}>
        <Grid2 xs={6}>
          <NotificationCallListGroup title="Top Moments">
            {map(topMoments, (moment) => (
              <BulletKeyPoint key={moment}>{moment}</BulletKeyPoint>
            ))}
          </NotificationCallListGroup>
        </Grid2>
        <Grid2 xs={6}>
          <NotificationCallListGroup title="Top Possible Improvements">
            {map(topPossibleImprovements, (moment) => (
              <BulletKeyPoint key={moment}>{moment}</BulletKeyPoint>
            ))}
          </NotificationCallListGroup>
        </Grid2>
      </Grid2>
    </NotificationSectionGroup>
  )
}

export default NotificationCallAgentCoaching
