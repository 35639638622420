import { Box } from '@mui/material'

export const BULLET_SIZE_SMALL = 6
export const BULLET_SIZE_MEDIUM = 8

type BulletProps = {
  color: string
  size?: 'small' | 'medium'
}

const Bullet = ({ color, size = 'medium' }: BulletProps) => {
  const getBackgroundColor = () => {
    if (color === 'primary' || color === 'secondary') {
      return `${color}.main`
    } else {
      return color
    }
  }

  const sizeNumber = size === 'small' ? BULLET_SIZE_SMALL : BULLET_SIZE_MEDIUM
  return (
    <Box
      sx={{
        backgroundColor: getBackgroundColor(),
        borderRadius: `${sizeNumber / 2}px`,
        flex: 'none',
        height: sizeNumber,
        width: sizeNumber,
      }}
    />
  )
}

export default Bullet
