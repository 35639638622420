import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  PaletteMode,
  Shadows,
  alpha,
  createTheme,
  lighten,
  type Theme,
  type ThemeOptions,
} from '@mui/material'

import {
  BUTTON_LARGE_HEIGHT,
  BUTTON_MEDIUM_HEIGHT,
  BUTTON_SMALL_HEIGHT,
} from '@/components/UIKit/Button/styles'

import * as typographyStyles from './Typography/styles'
import { whiteTransparentGradient } from './background'
import { SELECT_ICON_FONT_SIZE } from './components/Select'
import {
  BUTTON_CONTAINED_HOVERED_BOX_SHADOW,
  CONTENT_BACKGROUND_BOX_SHADOW,
} from './elevation'
import {
  DISABLED_OPACITY,
  FOCUS_OPACITY,
  HOVER_OPACITY,
  PRIMARY_FOCUS,
  PRIMARY_HOVER,
  PRIMARY_PRESS,
} from './palette/action'
import { error } from './palette/colors/error'
import neutral from './palette/colors/neutral'
import primary from './palette/colors/primary'
import secondary from './palette/colors/secondary'
import tertiary from './palette/colors/tertiary'

export const getModeGrey = (mode: PaletteMode) => {
  return mode === 'light'
    ? neutral
    : {
        // invert greys for dark mode
        50: neutral[900],
        100: neutral[900],
        200: neutral[800],
        300: neutral[700],
        400: neutral[600],
        500: neutral[500],
        600: neutral[400],
        700: neutral[300],
        800: neutral[200],
        900: neutral[100],
        A100: neutral['A700'],
        A200: neutral['A400'],
        A400: neutral['A200'],
        A700: neutral['A100'],
      }
}

const getDesignTokens = (mode: PaletteMode): ThemeOptions => {
  const modeGrey = getModeGrey(mode)

  return {
    components: {
      MuiAccordion: {
        defaultProps: {
          defaultExpanded: true,
          disableGutters: true,
        },
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '&.Mui-expanded:before,&::before': {
              opacity: 0,
              display: 'none',
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            margin: 0,
            minHeight: 24,
          },
          root: {
            minHeight: 24,
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            '.MuiAutocomplete-option': {
              '&.Mui-focused': { backgroundColor: PRIMARY_HOVER },
              '&.Mui-focusVisible': { backgroundColor: PRIMARY_HOVER },
              '&[aria-selected="true"]': {
                backgroundColor: PRIMARY_FOCUS,
                '&.Mui-focused': { backgroundColor: PRIMARY_FOCUS },
              },
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: 'large',
        },
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: 100,
            '.bx': {
              fontSize: 'inherit',
            },
            '.MuiButton-icon': {
              '& > *:first-of-type': {
                fontSize: 'inherit',
              },
            },
            '& .MuiButton-startIcon, & .MuiButton-endIcon': {
              '&:only-child': {
                marginRight: 0,
                marginLeft: 0,
              },
            },
            '&.MuiButton-sizeSmall': {
              height: BUTTON_SMALL_HEIGHT,
              minWidth: BUTTON_SMALL_HEIGHT,
              '& .MuiSvgIcon-root': {
                fontSize: 14,
              },
              '.MuiButton-startIcon': {
                marginLeft: -4,
                marginRight: 4,
              },
              '.MuiButton-endIcon': {
                marginLeft: 4,
                marginRight: -4,
              },
              '.MuiButton-startIcon:only-child, .MuiButton-endIcon:only-child, .MuiSvgIcon-root:only-child':
                {
                  marginRight: 0,
                  marginLeft: 0,
                },
              '&:has(> .bx:only-child)': {
                padding: `${theme.spacing(0.625)} !important`,
                fontSize: 14,
              },
              '&:has(> .MuiButton-startIcon:only-child, > .MuiButton-endIcon:only-child, > .MuiSvgIcon-root:only-child)':
                {
                  padding: `${theme.spacing(0.625)} !important`,
                },
              '&.MuiButton-contained': {
                padding: theme.spacing(0.5, 1.5),
              },
              '&.MuiButton-text': {
                padding: theme.spacing(0.5, 0.75),
                '&:has(> .MuiButton-startIcon, > .MuiButton-endIcon)': {
                  padding: theme.spacing(0.5, 1),
                },
              },
              '&.MuiButton-outlined': {
                padding: theme.spacing(0.5, 1.5),
              },
            },
            '&.MuiButton-sizeMedium': {
              height: BUTTON_MEDIUM_HEIGHT,
              minWidth: BUTTON_MEDIUM_HEIGHT,
              '& .MuiSvgIcon-root': {
                fontSize: 16,
              },
              '.MuiButton-startIcon': {
                marginLeft: -4,
                marginRight: 8,
              },
              '.MuiButton-endIcon': {
                marginLeft: 8,
                marginRight: -4,
              },
              '.MuiButton-startIcon:only-child, .MuiButton-endIcon:only-child, .MuiSvgIcon-root:only-child':
                {
                  marginRight: 0,
                  marginLeft: 0,
                },
              '&:has(> .bx:only-child)': {
                padding: `${theme.spacing(1)} !important`,
                fontSize: 16,
              },
              '&:has(> .MuiButton-startIcon:only-child, > .MuiButton-endIcon:only-child, > .MuiSvgIcon-root:only-child)':
                {
                  padding: `${theme.spacing(1)} !important`,
                },
              '&.MuiButton-contained': {
                padding: theme.spacing(0.75, 2),
              },
              '&.MuiButton-text': {
                padding: theme.spacing(0.75, 1),
                '&:has(> .MuiButton-startIcon, > .MuiButton-endIcon)': {
                  padding: theme.spacing(0.75, 1.5),
                },
              },
              '&.MuiButton-outlined': {
                padding: theme.spacing(0.75, 2),
              },
            },
            '&.MuiButton-sizeLarge': {
              height: BUTTON_LARGE_HEIGHT,
              minWidth: BUTTON_LARGE_HEIGHT,
              '& .MuiSvgIcon-root': {
                fontSize: 18,
              },
              '.MuiButton-startIcon': {
                marginLeft: -8,
                marginRight: 8,
              },
              '.MuiButton-endIcon': {
                marginLeft: 8,
                marginRight: -8,
              },
              '.MuiButton-startIcon:only-child, .MuiButton-endIcon:only-child, .MuiSvgIcon-root:only-child':
                {
                  marginRight: 0,
                  marginLeft: 0,
                },
              '&:has(> .bx:only-child)': {
                padding: `${theme.spacing(1.25)} !important`,
                fontSize: 18,
              },
              '&:has(> .MuiButton-startIcon:only-child, > .MuiButton-endIcon:only-child, > .MuiSvgIcon-root:only-child)':
                {
                  padding: `${theme.spacing(1.25)} !important`,
                },
              '&.MuiButton-contained': {
                padding: theme.spacing(1.25, 3),
              },
              '&.MuiButton-text': {
                padding: theme.spacing(1.25, 1.5),
                '&:has(> .MuiButton-startIcon, > .MuiButton-endIcon)': {
                  padding: theme.spacing(1.25, 2),
                },
              },
              '&.MuiButton-outlined': {
                padding: theme.spacing(1.25, 3),
              },
            },
            '&.MuiButton-text': {
              '&:active, &:focus': {
                backgroundColor: PRIMARY_PRESS,
              },
              '&.Mui-disabled': {
                backgroundColor: 'transparent',
              },
            },
            '&.Mui-disabled': {
              backgroundColor: alpha(modeGrey[800], DISABLED_OPACITY),
              color: alpha(modeGrey[800], 0.38),
            },
            '&.MuiButton-outlined': {
              '&.Mui-disabled': {
                backgroundColor: 'transparent',
                border: `1px solid ${alpha(modeGrey[800], DISABLED_OPACITY)}`,
              },
              background: whiteTransparentGradient,
              border: `1px solid ${theme.palette.grey[50]}`,
              '&:focus': {
                border: `1px solid ${theme.palette.primary.main}`,
              },
              '&:hover': {
                backgroundColor: PRIMARY_HOVER,
              },
              '&:active, &:focus': {
                backgroundColor: PRIMARY_PRESS,
              },
            },
            '&.MuiButton-contained': {
              '&:active, &:hover, &:focus': {
                backgroundColor: lighten(
                  theme.palette.primary.main,
                  HOVER_OPACITY
                ),
              },
              '&:hover': {
                boxShadow: BUTTON_CONTAINED_HOVERED_BOX_SHADOW,
              },
            },
          }),
          iconSizeMedium: {
            fontSize: 16,
          },
          iconSizeSmall: {
            fontSize: 14,
          },
          iconSizeLarge: {
            fontSize: 18,
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      // TODO remove hardcoded borderRadius values from cards
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '20px',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '24px',
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          sx: {
            // only way to keep text centered vertically
            lineHeight: '23px',
          },
        },
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.grey[500],
          }),
        },
      },
      MuiFilledInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            borderRadius: 8,
            background: theme.palette.background.default,
            outlineStyle: 'solid',
            outlineWidth: 1,
            outlineColor: theme.palette.grey[50],
            '&:hover': {
              background: theme.palette.background.default,
            },
            '&.Mui-focused': {
              background: theme.palette.background.default,
            },
          }),
          input: () => ({
            background: 'transparent',
          }),
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.grey[50],
            padding: 0,
            fontSize: 20,
            '&.MuiCheckbox-sizeSmall': {
              fontSize: 16,
            },
            '&.Mui-checked': {
              color: theme.palette.common.white,
              '.MuiSvgIcon-root': {
                backgroundColor: `var(--checkbox-color, ${theme.palette.primary.main})`,
              },
            },
            '&.Mui-disabled': {
              color: theme.palette.grey[50],
              '&.Mui-checked': {
                color: alpha(theme.palette.grey[800], 0.16),
              },
              '.MuiSvgIcon-root': {
                backgroundColor: alpha(theme.palette.grey[800], 0.12),
              },
            },
            '&.MuiButtonBase-root:hover': {
              backgroundColor: 'transparent',
              color: `var(--checkbox-color, ${theme.palette.primary.main})`,
              '&.Mui-checked': {
                color: theme.palette.common.white,
                '.MuiSvgIcon-root': {
                  background: `var(--checkbox-active-hover-color, ${lighten(
                    theme.palette.primary.main,
                    HOVER_OPACITY
                  )})`,
                },
              },
            },
            '.MuiSvgIcon-root': {
              padding: theme.spacing(3 / 8),
              borderRadius: theme.spacing(0.5),
              fontSize: 'inherit',
              width: '1em',
              height: '1em',
            },
          }),
        },
      },
      MuiSelect: {
        defaultProps: {
          IconComponent: KeyboardArrowDownIcon,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            background: theme.palette.background.paper,
            borderRadius: '3vmin',
            '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' },
            '& .MuiSvgIcon-root': {
              fontSize: SELECT_ICON_FONT_SIZE,
              color: theme.palette.grey[800],
            },
          }),
        },
      },
    },
    palette: {
      action: {
        focus: `rgba(0, 0, 0, ${FOCUS_OPACITY})`,
        focusOpacity: FOCUS_OPACITY,
        hover: `rgba(0, 0, 0, ${HOVER_OPACITY})`,
        hoverOpacity: HOVER_OPACITY,
      },
      text: {
        primary: modeGrey[800],
      },
      mode,
      grey: modeGrey,
      // MUI bug not applying main,light, and dark properly
      secondary: {
        ...secondary,
        dark: secondary[700],
        light: secondary[300],
        main: secondary[500],
      },
      tertiary: tertiary,
      error: error,
      ...(mode === 'light'
        ? {
            // palette values for light mode
            primary: primary,
          }
        : {
            // palette values for dark mode
            primary: primary, //darkPrimary,
          }),
    },
    typography: {
      //fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      fontFamily: `"Manrope"`,
      ...typographyStyles,
    },
    shadows: [
      'none',
      CONTENT_BACKGROUND_BOX_SHADOW,
      ...Array(23).fill('none'),
    ] as Shadows,
  }
}

const theme = createTheme(getDesignTokens('light'), {
  /*components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  },
  typography: {
    //fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontFamily: `"Manrope"`,
  },*/
})

export default theme
