import { ReactNode, useRef } from 'react'
import { Button, Stack, SxProps, useMediaQuery, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { useResizeObserver } from 'usehooks-ts'

import { useAuth } from '@/context/AuthContext'
import LogoTextWhite32 from '@/logos/LogoTextWhite32'

import PageSurface from './PageSurface'
import {
  BACKGROUND_IMAGE,
  DARK_BACKGROUND_IMAGE_1,
} from '../../../theme/backgroundImages'
import PrettyLights from '../../Onboarding/PrettyLights/PrettyLights'
import LoginPageFooter, {
  LOGIN_PAGE_FOOTER_HEIGHT,
} from '../LoginPage/LoginPageFooter'
import LoginPageHeader, {
  LOGIN_PAGE_HEADER_HEIGHT,
} from '../LoginPage/LoginPageHeader'
import SideBar from '../SideBar/SideBar'

const MAX_CONTENT_WIDTH = 1296

type PageProps = {
  children: ReactNode
  innerSx?: SxProps
  layoutWrapperSx?: SxProps
  prettyLights?: boolean
  walkthrough?: boolean
}

const Page = ({
  children,
  innerSx,
  layoutWrapperSx,
  prettyLights,
  walkthrough = false,
}: PageProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { width } = useResizeObserver({
    ref,
  })

  const theme = useTheme()
  const darkMode = theme.palette.mode === 'dark'

  const { isAuth, isUserLoading } = useAuth()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const isAnonymous = !isAuth && !isUserLoading
  const hasSideBar = !isAnonymous && !walkthrough

  const contentMaxWidth = Math.min(
    width || MAX_CONTENT_WIDTH,
    MAX_CONTENT_WIDTH
  )

  const backgroundImage = darkMode
    ? `url(${DARK_BACKGROUND_IMAGE_1})`
    : `url(${BACKGROUND_IMAGE})`

  const p = 3

  return (
    <Stack
      ref={ref}
      sx={{
        backgroundImage: backgroundImage,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100vh',
        width: '100vw',
      }}
    >
      {prettyLights ? <PrettyLights /> : null}

      {walkthrough ? <LoginPageHeader /> : null}

      <Stack direction="row" sx={{ height: '100vh', ...layoutWrapperSx }}>
        {hasSideBar ? <SideBar logoSize={mdUp ? 64 : 32} /> : null}

        <Stack
          alignItems="center"
          sx={{ flex: 1, minWidth: 0, pl: hasSideBar ? 0 : p, pr: p, py: p }}
        >
          <Stack
            sx={{
              height: walkthrough
                ? `calc(100vh - ${
                    LOGIN_PAGE_HEADER_HEIGHT +
                    LOGIN_PAGE_FOOTER_HEIGHT +
                    p * 8 * 2
                  }px)`
                : '100%',
              maxWidth: { md: contentMaxWidth, xs: '100vw' },
              minWidth: 0,

              width: 1,
              ...innerSx,
            }}
          >
            {isAnonymous && (
              <Stack
                alignItems="center"
                direction="row"
                flex="none"
                justifyContent="space-between"
                overflow="hidden"
                pb={2.5}
                px={4}
                width={1}
              >
                <Link to="/">
                  <LogoTextWhite32 />
                </Link>
                <Button
                  href="/"
                  sx={{
                    '&:hover': {
                      backgroundColor: (theme) =>
                        theme.palette.background.default,
                    },
                    backgroundColor: (theme) =>
                      `${theme.palette.background.default}!important`,
                    color: (theme) => theme.palette.primary.main,
                  }}
                  variant="contained"
                >
                  Sign in
                </Button>
              </Stack>
            )}

            <PageSurface>{children}</PageSurface>
          </Stack>
        </Stack>
      </Stack>
      {walkthrough ? <LoginPageFooter /> : null}
    </Stack>
  )
}

export default Page
