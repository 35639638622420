import { IconButton, type IconButtonProps } from '@mui/material'

import { whiteTransparentGradient } from '@/theme/background'

type ActionButtonProps = Omit<IconButtonProps, 'size'> & {
  size?: 'md' | 'sm'
}
const iconSizeMap = {
  md: {
    fontSize: 18,
    size: 40,
  },
  sm: {
    fontSize: 18,
    size: 24,
  },
}
const ActionButton = ({
  children,
  size = 'md',
  sx,
  ...rest
}: ActionButtonProps) => {
  return (
    <IconButton
      sx={{
        background: whiteTransparentGradient,
        boxShadow: (theme) => `inset 0 0 0 1px ${theme.palette.grey[50]}`,
        color: 'primary.main',
        fontSize: iconSizeMap[size].fontSize,
        height: iconSizeMap[size].size,
        width: iconSizeMap[size].size,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </IconButton>
  )
}

export default ActionButton
