import { TextField, TextFieldProps } from '@mui/material'

import { INPUT_WIDTH } from './consts'

type LoginTextFieldProps = Pick<TextFieldProps, 'disabled' | 'helperText'> & {
  InputProps?: TextFieldProps['InputProps']
  label: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  type?: React.HTMLInputTypeAttribute
  value: string
}

const LoginTextField = ({ InputProps, ...rest }: LoginTextFieldProps) => {
  return (
    <TextField
      {...rest}
      InputProps={InputProps}
      sx={{
        maxWidth: INPUT_WIDTH,
        minWidth: INPUT_WIDTH,
      }}
      variant="filled"
    />
  )
}

export default LoginTextField
