const green = {
  '50': '#DFF4EF',
  '100': '#B2E4D5',
  '200': '#7FD2BA',
  '300': '#47C09E',
  '400': '#0BB189',
  '500': '#00A278',
  '600': '#00956B',
  '700': '#00845C',
  '800': '#00744E',
  '900': '#005733',
  A100: '#a3ffd6',
  A200: '#70ffbf',
  A400: '#3dffa8',
  A700: '#24ff9c',
}

export default green
