import { map } from 'lodash'

import type {
  InvitationsCountFilterArguments,
  InvitationsFilterArguments,
} from '@/models/hasura/InvitationsFilterArguments'

import { hasuraBaseUrl } from '..'
import {
  Invitation,
  InvitationStatus,
  type InsertInvitationInput,
  type UpdateInvitationInput,
} from '../../models/hasura/Invitation'
import { hasuraApi } from '../clients/hasura'

export const baseURL = hasuraBaseUrl

export const invitationsApi = hasuraApi.injectEndpoints({
  endpoints: (builder) => ({
    insertInvitation: builder.mutation<Invitation, InsertInvitationInput>({
      query: (invitation) => ({
        url: '/invitation',
        method: 'POST',
        body: {
          object: invitation,
        },
      }),
      transformResponse: (response: { insert_invitation_one: Invitation }) =>
        response.insert_invitation_one,
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'Invitation', id: 'LIST' },
              { type: 'InvitationTotal', id: InvitationStatus.pending },
            ]
          : [],
    }),
    updateInvitation: builder.mutation<Invitation, UpdateInvitationInput>({
      query: ({ id, ...patch }) => ({
        url: `/invitation/${id}`,
        method: 'PATCH',
        body: { object: patch },
      }),
      transformResponse: (response: { update_invitation_by_pk: Invitation }) =>
        response.update_invitation_by_pk,
      invalidatesTags: (result, _arg) =>
        result ? [{ type: 'Invitation', id: result.id }] : [],
    }),
    getInvitations: builder.query<Invitation[], InvitationsFilterArguments>({
      extraOptions: { includeUserId: true },
      query: (params) => ({
        url: '/invitations_by_organization_id',
        method: 'POST',
        body: {
          object: params,
        },
      }),
      transformResponse: (response: {
        invitations_by_organization_id: Invitation[]
      }) => response.invitations_by_organization_id,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Invitation', id }) as const),
              { type: 'Invitation', id: 'LIST' },
            ]
          : [{ type: 'Invitation', id: 'LIST' }],
    }),
    getInvitationsCount: builder.query<number, InvitationsCountFilterArguments>(
      {
        extraOptions: { includeUserId: true },
        query: (params) => ({
          url: '/invitations_count_by_organization_id',
          method: 'POST',
          body: {
            object: params,
          },
        }),
        providesTags: (result, _, args) =>
          result == null
            ? []
            : [{ type: 'InvitationTotal', id: args.status || 'all' }],
        transformResponse: (response: {
          invitations_count_by_organization_id: { count: number }[]
        }) => response.invitations_count_by_organization_id[0]?.count || 0,
      }
    ),
    getInvitation: builder.query<Invitation, string>({
      query: (id) => ({
        url: `/invitation/${id}`,
      }),
      transformResponse: (response: { invitation_by_pk: Invitation }) =>
        response.invitation_by_pk,
      providesTags: (result) =>
        result ? [{ type: 'Invitation', id: result.id }] : [],
    }),
    getInvitedUserEmails: builder.query<string[], string>({
      query: (id) => ({
        url: `/invitations_emails_by_organization_id/${id}`,
        method: 'POST',
        body: {
          status: [InvitationStatus.pending, InvitationStatus.accepted],
        },
      }),
      transformResponse: (response: { invitation: { email: string }[] }) =>
        map(response.invitation, 'email'),
    }),
  }),
})

export const {
  useGetInvitationQuery,
  useGetInvitationsCountQuery,
  useGetInvitationsQuery,
  useGetInvitedUserEmailsQuery,
  useInsertInvitationMutation,
  useUpdateInvitationMutation,
} = invitationsApi
