const yellowOrange = {
  '50': '#FFFDE8',
  '100': '#FFF9C6',
  '200': '#FFF5A0',
  '300': '#FFF07B',
  '400': '#FDEB5D',
  '500': '#FBE641',
  '600': '#FFDA42',
  '700': '#FDC23B',
  '800': '#FAAA33',
  '900': '#F58326',
  A100: '#ffffff',
  A200: '#fffef9',
  A400: '#fff5c6',
  A700: '#fff1ad',
}

export default yellowOrange
