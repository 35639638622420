import { ReactNode } from 'react'
import { Card, CardContent, type SxProps } from '@mui/material'

import { PAGE_MODAL_SURFACE } from '../../../theme/surfaces'

type OnboardingSurfaceProps = {
  children: ReactNode
  ignoreOpacity?: boolean
  login?: boolean
  sx?: SxProps
}

// Surface used mainly for login and onboarding pages
const OnboardingSurface = ({
  children,
  ignoreOpacity = false,
  login,
  sx,
}: OnboardingSurfaceProps) => {
  return (
    <Card
      sx={{
        background: (theme) =>
          ignoreOpacity ? theme.palette.background.default : PAGE_MODAL_SURFACE,
        flexGrow: login ? 1 : 0,
        width: {
          md: 'calc(700px - 48px)',
          sm: 'calc(600px - 48px)',
          xs: 'calc(100vw - 48px)',
        },
        zIndex: 1,
        ...sx,
      }}
    >
      <CardContent
        sx={{
          height: '100%',
          ...(login && {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }),
        }}
      >
        {children}
      </CardContent>
    </Card>
  )
}

export default OnboardingSurface
