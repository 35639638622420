import { useMediaQuery } from '@mui/material'

import theme from '../../theme/MuiTheme'

export const SIDE_MENU_ITEM_SIZE_LARGE = 64
export const SIDE_MENU_ITEM_SIZE_SMALL = 48

// TODO not sure we need a separate hook for this
export default function useSideMenuItemSize(): number {
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const size = mdUp ? SIDE_MENU_ITEM_SIZE_LARGE : SIDE_MENU_ITEM_SIZE_SMALL

  return size
}
