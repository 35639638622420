import WarningIcon from '@mui/icons-material/Warning'
import { Stack } from '@mui/material'

import LockIcon from '../../../assets/icons/lock-outlined-icon.svg?react'
import DialogButton from '../../UIKit/Button/DialogButton'
import PageModalHeaderText from '../../UIKit/Page/PageModalHeaderText'
import PageModalSecondaryHeaderText from '../../UIKit/Page/PageModalSecondaryHeaderText'
import AuthError from '../AuthError'
import AuthNotification from '../AuthNotification'
import EmailField from '../EmailField'
import LoginContent from '../LoginContent'
import LoginFields from '../LoginFields'
import LoginForm from '../LoginForm'
import LoginHeader from '../LoginHeader'
import PasswordField from '../PasswordField'
import SecondaryLoginButton from '../SecondaryLoginButton'
import { LoginState } from '../shared'

type SignInProps = {
  authError: any
  authNotification: any
  email: string
  handleLoginStateChange: (loginState: LoginState) => void
  handleSendPasswordResetClick: () => void
  loading: boolean
  loginState: LoginState
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onLoginClick: () => void
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  password: string
}

const SignIn = ({
  authError,
  authNotification,
  email,
  handleLoginStateChange,
  handleSendPasswordResetClick,
  loading,
  loginState,
  onEmailChange,
  onLoginClick,
  onPasswordChange,
  password,
}: SignInProps) => {
  const headerText =
    loginState === LoginState.SignIn ? 'Sign in' : 'Forgot Password'
  const isForgotPasswordState = loginState === LoginState.ForgotPassword

  return (
    <LoginContent>
      <LoginHeader Icon={isForgotPasswordState ? LockIcon : undefined}>
        <PageModalHeaderText>{headerText}</PageModalHeaderText>
        {isForgotPasswordState && (
          <PageModalSecondaryHeaderText>
            Please enter the Email or phone number used during sign up.
          </PageModalSecondaryHeaderText>
        )}
      </LoginHeader>
      <LoginForm>
        <AuthNotification
          Icon={WarningIcon}
          authNotification={authNotification}
        />
        <LoginFields>
          <Stack>
            <EmailField email={email} onEmailChange={onEmailChange} />
            {isForgotPasswordState && authError && (
              <AuthError error={authError} />
            )}
          </Stack>
          {loginState === LoginState.SignIn ? (
            <Stack>
              <PasswordField onChange={onPasswordChange} value={password} />
              {authError ? <AuthError error={authError} /> : null}
            </Stack>
          ) : null}
        </LoginFields>
        {loginState === LoginState.SignIn ? (
          <DialogButton
            disabled={loading}
            onClick={onLoginClick}
            variant="contained"
          >
            Sign In
          </DialogButton>
        ) : null}
        {isForgotPasswordState ? (
          <DialogButton
            disabled={loading || !email}
            onClick={handleSendPasswordResetClick}
            variant="contained"
          >
            Send Password Reset Email
          </DialogButton>
        ) : null}
        <Stack direction="row" justifyContent="space-between">
          {loginState === LoginState.SignIn ? (
            <SecondaryLoginButton
              label="Forgot Password?"
              onClick={() => handleLoginStateChange(LoginState.ForgotPassword)}
            />
          ) : (
            <SecondaryLoginButton
              label="Login"
              onClick={() => handleLoginStateChange(LoginState.SignIn)}
            />
          )}
          <SecondaryLoginButton
            label="Sign Up"
            onClick={() => handleLoginStateChange(LoginState.SignUp)}
          />
        </Stack>
      </LoginForm>
    </LoginContent>
  )
}

export default SignIn
