import { ReactNode } from 'react'
import { Stack, SxProps, useTheme } from '@mui/material'

import LoginPageContent from './LoginPageContent'
import LoginPageFooter from './LoginPageFooter'
import LoginPageHeader from './LoginPageHeader'
import {
  BACKGROUND_IMAGE,
  DARK_BACKGROUND_IMAGE_1,
} from '../../../theme/backgroundImages'

type LoginPageProps = {
  children: ReactNode
  contentSx?: SxProps
  sx?: SxProps
}

const LoginPage = ({ children, contentSx, sx = {} }: LoginPageProps) => {
  const theme = useTheme()
  const darkMode = theme.palette.mode === 'dark'

  const backgroundImage = darkMode
    ? `url(${DARK_BACKGROUND_IMAGE_1})`
    : `url(${BACKGROUND_IMAGE})`

  return (
    <Stack
      //className="background"

      sx={{
        backgroundImage,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100vh',
        overflow: 'auto',
        width: 1,
        ...sx,
        //py: 2,
      }}
    >
      <LoginPageHeader />
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          //width: '100%',
          //maxWidth: { xs: '100vw', sm: 600, md: contentMaxWidth },
          //marginX: 'auto',
          flex: 1,
        }}
      >
        <LoginPageContent sx={contentSx}>{children}</LoginPageContent>
      </Stack>
      <LoginPageFooter />
    </Stack>
  )
}

export default LoginPage
