import { ReactNode } from 'react'
import { Paper, Stack, useTheme } from '@mui/material'

import { CONTENT_BACKGROUND } from '../../../theme/backgroundImages'
import {
  OPACITY_BACKGROUND_BACKDROP_FILTER,
  OPACITY_BACKGROUND_BOX_SHADOW,
} from '../../../theme/elevation'

type PageSurfaceProps = {
  children: ReactNode
}

const PageSurface = ({ children }: PageSurfaceProps) => {
  const theme = useTheme()
  const darkMode = theme.palette.mode === 'dark'
  return (
    <Paper
      sx={{
        backdropFilter: !darkMode ? OPACITY_BACKGROUND_BACKDROP_FILTER : 'none',
        background: !darkMode ? CONTENT_BACKGROUND : 'inherit',
        borderRadius: 6,
        boxShadow: !darkMode ? OPACITY_BACKGROUND_BOX_SHADOW : 'none',
        height: '100%',
        overflowY: 'auto',
        p: 3,
      }}
    >
      <Stack sx={{ height: '100%' }}>{children}</Stack>
    </Paper>
  )
}

export default PageSurface
