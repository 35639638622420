import { Stack, Typography } from '@mui/material'

type NotificationSectionGroupProps = {
  title: string
}
const NotificationSectionGroup = ({
  children,
  title,
}: React.PropsWithChildren<NotificationSectionGroupProps>) => {
  return (
    <Stack spacing={3}>
      <Typography variant="h6">{title}</Typography>
      {children}
    </Stack>
  )
}

export default NotificationSectionGroup
