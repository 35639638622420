import { useCallback } from 'react'
import { alpha, Box, Button, Popover, Stack, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { TOOLTIP_BACKGROUND_BOX_SHADOW as POPOVER_BACKGROUND_BOX_SHADOW } from '@/theme/elevation'

import NotificationList from './NotificationList'
import { notifications } from './Notifications'

const NotificationPopover = ({
  anchorRef,
  close,
  isOpen,
}: {
  anchorRef: React.RefObject<HTMLDivElement>
  close: () => void
  isOpen: boolean
}) => {
  const { organizationId } = useParams()
  const navigate = useNavigate()

  const handleMarkAllAsRead = () => {
    // TODO Mark all notifications as read
    close()
  }

  const handleItemClick = useCallback(
    (notification: TODO) => {
      navigate(
        `/organization/${organizationId}/notifications/${notification.id}`
      )
      close()
    },
    [close, navigate, organizationId]
  )

  const handleViewAll = useCallback(() => {
    navigate(`/organization/${organizationId}/notifications`)
    close()
  }, [close, navigate, organizationId])
  return (
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      id="notifications-popover"
      onClose={close}
      open={isOpen}
      sx={{
        transform: 'translate(8px, 0px)',
        // eslint-disable-next-line sort-keys-fix/sort-keys-fix
        '& .MuiPopover-paper': {
          background: 'transparent',
          borderRadius: 4,
          boxShadow: POPOVER_BACKGROUND_BOX_SHADOW,
          overflow: 'hidden',
          width: 520,
        },
      }}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}
    >
      <Box
        sx={{
          backdropFilter: 'blur(100px)',
          background: (theme) => alpha(theme.palette.common.white, 0.72),
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          justifyContent="space-between"
          p={3}
        >
          <Typography variant="subtitle1Semibold">
            Notifications ({notifications.length})
          </Typography>
          <Stack direction="row" gap={1}>
            <Button
              color="primary"
              onClick={handleMarkAllAsRead}
              size="large"
              sx={{
                height: 'auto',
                px: 1.5,
                py: 1.25,
              }}
              variant="text"
            >
              <Typography variant="button2">Mark all as read</Typography>
            </Button>
            <Button
              color="primary"
              onClick={handleViewAll}
              size="large"
              variant="contained"
            >
              <Typography variant="button2">View all</Typography>
            </Button>
          </Stack>
        </Stack>
        <NotificationList
          notifications={notifications}
          onItemClick={handleItemClick}
          selectedId={undefined}
          variant="separated"
        />
      </Box>
    </Popover>
  )
}

export default NotificationPopover
