import { SelectChangeEvent } from '@mui/material/Select'

import type { SortState } from '@/hooks/filters/types'
import type { InvitationsSortBy } from '@/hooks/filters/useInvitationsFilters'

import Select from '../../UIKit/Select/Select'

const items: Array<Option<InvitationsSortBy>> = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Role',
    value: 'role',
  },
]

type SortBySelectProps = {
  onChange: (value: SortState<InvitationsSortBy> | undefined) => void
  value?: SortState<InvitationsSortBy>
}
export default function SortBySelect({ onChange, value }: SortBySelectProps) {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChange(
      event.target.value
        ? {
            sortBy: event.target.value as InvitationsSortBy,
            sortDirection: 'asc',
          }
        : undefined
    )
  }

  return (
    <Select
      handleChange={handleChange}
      horizontalAlign="right"
      id="invites-sort-by-select"
      label="Sort By"
      labelId="invites-sort-by-select-label"
      options={items}
      value={value?.sortBy || ''}
      variant="outlined"
    />
  )
}
