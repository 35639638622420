import { ReactNode } from 'react'
import { Divider, Stack, type SxProps } from '@mui/material'

import { transparentNeutral } from '../../../theme/palette/colors/transparentNeutral'

type ControlBarFiltersProps = {
  leftFilters?: ReactNode
  rightFilters?: ReactNode
  sx?: SxProps
}

const ControlBarFilters = ({
  leftFilters,
  rightFilters,
  sx,
}: ControlBarFiltersProps) => {
  const hasDivider = leftFilters && rightFilters
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="end"
      spacing={1}
      sx={{
        py: 0.5,
        ...sx,
      }}
    >
      <Stack direction="row" spacing={1}>
        {leftFilters}
        {hasDivider && (
          <Divider
            orientation="vertical"
            sx={{
              borderColor: transparentNeutral[16],
              height: 'auto',
            }}
          />
        )}
        {rightFilters}
      </Stack>
    </Stack>
  )
}

export default ControlBarFilters
