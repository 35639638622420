import { FontWeight } from '../fontWeights'

export const h1 = {
  fontSize: 96,
  fontWeight: FontWeight.light,
  letterSpacing: -1.5,
  lineHeight: 1.5,
}
export const h2 = {
  fontSize: 60,
  fontWeight: FontWeight.light,
  letterSpacing: -0.5,
  lineHeight: 1.5,
}
export const h3 = {
  fontSize: 48,
  fontWeight: FontWeight.semibold,
  letterSpacing: 0,
  lineHeight: 1.5,
}
export const h4 = {
  fontSize: 34,
  fontWeight: FontWeight.semibold,
  letterSpacing: 0.25,
  lineHeight: 1.5,
}
export const h5 = {
  fontSize: 24,
  fontWeight: FontWeight.semibold,
  letterSpacing: 0,
  lineHeight: 1.5,
}
export const h6 = {
  fontSize: 20,
  fontWeight: FontWeight.semibold,
  letterSpacing: 0.15,
  lineHeight: 1.5,
}
export const subtitle1 = {
  fontSize: 16,
  fontWeight: FontWeight.medium,
  letterSpacing: 0.15,
  lineHeight: 1.5,
}
export const subtitle1Semibold = {
  fontSize: 16,
  fontWeight: FontWeight.semibold,
  letterSpacing: 0.15,
  lineHeight: 1.5,
}
export const subtitle2 = {
  fontSize: 14,
  fontWeight: FontWeight.medium,
  letterSpacing: 0.1,
  lineHeight: '20px',
}
export const subtitle2Semibold = {
  fontSize: 14,
  fontWeight: FontWeight.semibold,
  letterSpacing: 0.1,
  lineHeight: '20px',
}
export const body1 = {
  fontSize: 16,
  fontWeight: FontWeight.regular,
  letterSpacing: 0.5,
  lineHeight: 1.5,
}
export const body2 = {
  fontSize: 14,
  fontWeight: FontWeight.medium,
  letterSpacing: 0.25,
  lineHeight: '20px',
}
export const body3 = {
  ...body2,
  fontSize: 12,
  lineHeight: 1.5,
}
export const button = {
  fontSize: 16,
  textTransform: 'none' as const,
  fontWeight: FontWeight.medium,
  letterSpacing: 0.25,
  lineHeight: 1.5,
}
export const button2 = {
  fontSize: 14,
  fontWeight: FontWeight.medium,
  letterSpacing: 0.25,
  lineHeight: '20px',
}
export const button3 = {
  fontSize: 12,
  fontWeight: FontWeight.medium,
  letterSpacing: 0.25,
  lineHeight: '16px',
}
// caption/caption 1 medium
export const caption = {
  fontSize: 12,
  fontWeight: FontWeight.medium,
  letterSpacing: 0.4,
  lineHeight: 1.5,
}
export const caption2 = {
  fontSize: 12,
  fontWeight: FontWeight.semibold,
  letterSpacing: 0.4,
  lineHeight: 1.5,
}
export const caption3 = {
  fontSize: 10,
  fontWeight: FontWeight.semibold,
  letterSpacing: 0.4,
  lineHeight: 1.6,
}
export const overline = {
  fontSize: 10,
  fontWeight: FontWeight.medium,
  letterSpacing: 1.5,
  lineHeight: 1.6,
}
