import { ReactNode } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CloseIcon from '@mui/icons-material/Close'
import { Stack } from '@mui/material'

import IconButtonLayout from './IconButtonLayout'

type LoginContentProps = {
  children: ReactNode
  onBack?: () => void
  onClose?: () => void
  showBackButton?: boolean
  showControlButtons?: boolean
}

const LoginContent = ({
  children,
  onBack,
  onClose,
  showBackButton = true,
  showControlButtons = false,
}: LoginContentProps) => {
  return (
    <Stack sx={{ height: '100%' }}>
      {showControlButtons && (
        <Stack direction="row" justifyContent="space-between">
          {showBackButton && (
            <IconButtonLayout Icon={ArrowBackIosIcon} onClick={onBack} />
          )}
          <IconButtonLayout Icon={CloseIcon} onClick={onClose} />
        </Stack>
      )}
      <Stack alignItems="center" flex={1} justifyContent="center">
        {children}
      </Stack>
    </Stack>
  )
}

export default LoginContent
