import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { skipToken } from '@reduxjs/toolkit/query'
import type { FirebaseError } from 'firebase/app'
import {
  User as FBUser,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
import { useLocalStorage } from 'usehooks-ts'

import { useImpersonateMutation } from '@/services/shilo/auth'

import { auth } from '../config/firebase'
import type { User as UserProfile } from '../models/hasura/User'
import {
  useGetEmailInWhitelistMutation,
  useGetUserByFirebaseIdQuery,
} from '../services/hasura/users'

export type AuthUser = FBUser | null
export type AuthProfile = UserProfile | null

interface IAuthContext {
  isAuth: boolean
  isImpersonating: boolean
  isUserLoading: boolean
  login: (
    email: string,
    password: string,
    impersonateId?: string
  ) => Promise<AuthUser>
  logout: () => Promise<void>
  profile: AuthProfile | undefined
  requestPasswordReset: (email: string) => Promise<void>
  signUp: (email: string, password: string) => Promise<AuthUser>
  user: AuthUser | undefined
}

export const AuthContext = createContext<IAuthContext>({
  isAuth: false,
  isImpersonating: false,
  isUserLoading: false,
  login: () => Promise.resolve(null),
  logout: () => Promise.resolve(),
  profile: null,
  requestPasswordReset: () => Promise.resolve(),
  signUp: () => Promise.resolve(null),
  user: null,
})

export const useAuth = () => {
  return useContext(AuthContext)
}

export enum AuthQueryStatus {
  fulfilled = 'fulfilled',
  pending = 'pending',
  rejected = 'rejected',
  uninitialized = 'uninitialized',
}

type AuthProviderProps = {
  children: ReactNode
}

const signupErrorStatusToMessage: Record<FirebaseError['code'], string> = {
  'auth/email-already-in-use': 'Email already in use',
  'auth/invalid-email': 'Invalid email',
  'auth/weak-password': 'Password too weak',
}

const signinErrorStatusToMessage: Record<FirebaseError['code'], string> = {
  'auth/invalid-credential':
    'The email or password you entered is incorrect. Please double-check your information and try again.',
}

const invalidEmailMessage =
  'The email you entered is incorrect. Please double-check your information and try again.'
const resetPasswordErrorStatusToMessage: Record<FirebaseError['code'], string> =
  {
    'auth/invalid-email': invalidEmailMessage,
    'auth/missing-email': invalidEmailMessage,
  }

const IMPERSONATE_LS_KEY = 'impersonate'

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<AuthUser | undefined>(undefined)

  const [impersonate] = useImpersonateMutation()
  const [impersonation, setImpersonation] = useLocalStorage<
    { id: string } | undefined
  >(IMPERSONATE_LS_KEY, undefined)

  const [isManager, setIsManager] = useState(false)

  const { data: profile, isLoading: isProfileLoading } =
    useGetUserByFirebaseIdQuery(user?.uid || skipToken, {
      skip: !user?.uid,
    })

  const setUserWithClaims = useCallback(async (user: AuthUser) => {
    if (!user) {
      setUser(user)
      return
    }
    const token = await user.getIdTokenResult()
    setIsManager(token.claims.manager as boolean)
    setUser(user)
  }, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserWithClaims(user)
    })
    return () => unsubscribe()
  }, [setUserWithClaims])

  const [getIsEmailInWhitelist] = useGetEmailInWhitelistMutation()

  const signUp = useCallback(
    async (email: string, password: string) => {
      try {
        const isEmailInWhitelist = await getIsEmailInWhitelist(email).unwrap()
        if (!isEmailInWhitelist) {
          throw new Error(
            "Nice try H4XX0R! You are not authorized to create an account. You have automatically been put on Shilo's naughty list. Congratz!"
          )
        }
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        )
        const { user } = userCredential
        await setUserWithClaims(user)
        await sendEmailVerification(user)
        return user
      } catch (error) {
        const typedError = error as FirebaseError
        throw new Error(
          signupErrorStatusToMessage[typedError.code] || typedError.message
        )
      }
    },
    [setUserWithClaims, getIsEmailInWhitelist]
  )

  const login = useCallback(
    async (email: string, password: string, impersonateId?: string) => {
      const isEmailInWhitelist = await getIsEmailInWhitelist(email).unwrap()

      if (!isEmailInWhitelist) {
        throw new Error(
          "No! No! No! You are not authorized to login. You have automatically been put on Shilo's naughty list. Congratz!"
        )
      }
      try {
        if (impersonateId) {
          setImpersonation({ id: impersonateId })
          const { token } = await impersonate({
            email,
            id: impersonateId,
            password,
          }).unwrap()
          const { user } = await signInWithCustomToken(auth, token)
          return user
        }
        const { user } = await signInWithEmailAndPassword(auth, email, password)
        await setUserWithClaims(user)
        return user
      } catch (error) {
        const typedError = error as FirebaseError
        throw new Error(
          signinErrorStatusToMessage[typedError.code] || typedError.message
        )
      }
    },
    [setUserWithClaims, setImpersonation, impersonate, getIsEmailInWhitelist]
  )

  const requestPasswordReset = useCallback(async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email)
    } catch (error) {
      const typedError = error as FirebaseError
      throw new Error(
        resetPasswordErrorStatusToMessage[typedError.code] || typedError.message
      )
    }
  }, [])

  const logout = useCallback(async () => {
    try {
      await signOut(auth)
      setUser(null)
      setImpersonation(undefined)
      setIsManager(false)
      // useLocalStorage hook doesn't support undefined values
      localStorage.removeItem(IMPERSONATE_LS_KEY)
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }, [setImpersonation])

  const isAuth = useMemo(() => {
    return Boolean(user)
  }, [user])

  // Only return the profile if the profile belongs to auth user and auth user is loaded
  // TODO what do we do if we are using impersonation?
  const authProfile = user?.uid === profile?.firebaseUid ? profile : null
  return (
    <AuthContext.Provider
      value={{
        isAuth,
        isImpersonating: Boolean(impersonation?.id && isManager),
        isUserLoading: user === undefined || isProfileLoading,
        login,
        logout,
        profile: authProfile,
        requestPasswordReset,
        signUp,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
