import { SxProps, Typography, useTheme } from '@mui/material'

import NeonTypography from '../../../packages/neon/NeonTypography'

type PageModalHeaderTextProps = {
  children: React.ReactNode
  sx?: SxProps
}

const PageModalHeaderText = ({ children, sx }: PageModalHeaderTextProps) => {
  const theme = useTheme()
  const darkMode = theme.palette.mode === 'dark'
  return darkMode ? (
    <NeonTypography sx={sx} variant="h5">
      {children}
    </NeonTypography>
  ) : (
    <Typography sx={sx} variant="h5">
      {children}
    </Typography>
  )
}

export default PageModalHeaderText
