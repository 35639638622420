import { Typography } from '@mui/material'
import { map } from 'lodash'

import NotificationCallListGroup from './NotificationCallListGroup'
import NotificationSectionGroup from './NotificationSectionGroup'
import BulletKeyPoint from '../Common/BulletKeyPoint/BulletKeyPoint'

type NotificationCallActionPointsProps = {
  additionalItems: string[]
  mostImportant: string[]
}
const NotificationCallActionPoints = ({
  additionalItems,
  mostImportant,
}: NotificationCallActionPointsProps) => {
  return (
    <NotificationSectionGroup title="Action Items">
      <NotificationCallListGroup title="Most important">
        {map(mostImportant, (moment) => (
          <BulletKeyPoint key={moment} variant="regular">
            {moment}
          </BulletKeyPoint>
        ))}
      </NotificationCallListGroup>

      <NotificationCallListGroup title="Additional Items">
        {additionalItems.length ? (
          map(additionalItems, (moment) => (
            <AdditionalItem key={moment}>{moment}</AdditionalItem>
          ))
        ) : (
          <AdditionalItem>No Additional Items</AdditionalItem>
        )}
      </NotificationCallListGroup>
    </NotificationSectionGroup>
  )
}

const AdditionalItem = ({ children }: React.PropsWithChildren) => {
  return (
    <Typography color={(theme) => theme.palette.grey[500]} variant="body2">
      {children}
    </Typography>
  )
}
export default NotificationCallActionPoints
