import { ReactNode } from 'react'
import { Card, type SxProps } from '@mui/material'

import { PAGE_MODAL_SURFACE } from '../../../theme/surfaces'

type LoginPageContentProps = {
  children: ReactNode
  sx?: SxProps
}

const LoginPageContent = ({ children, sx }: LoginPageContentProps) => {
  return (
    <Card
      sx={{
        background: PAGE_MODAL_SURFACE,
        display: 'flex',
        // TODO remove hardcoded value
        flexGrow: 1,
        maxHeight: 748,
        minHeight: 600,
        p: 3,
        width: 'fit-content',
        ...sx,
      }}
    >
      {children}
    </Card>
  )
}

export default LoginPageContent
