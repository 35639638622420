import { skipToken } from '@reduxjs/toolkit/query'
import { omit } from 'lodash'

import type { CallsFilters } from '@/hooks/filters/useCallsFilters'
import useSelectOptionsWithCounters from '@/hooks/useSelectOptionsWithCounters'
import type { GroupedCount } from '@/models/hasura/GroupedCount'
import { toSnakeCase } from '@/modules/object/caseTransformer'
import { useGetCallsCountGroupedByTeamQuery } from '@/services/hasura/calls'

import { UseSelect } from '../../../hooks/MUI/useSelect'
import Select from '../../UIKit/Select/Select'
import type { SelectVariant } from '../../UIKit/Select/types'

type TeamSelectProps = Partial<UseSelect> & {
  agentCounter?: GroupedCount[]
  filters?: CallsFilters
  onChange?: (value: string) => void
  options: Array<Option<string>>
  variant?: SelectVariant
}
export default function TeamSelect({
  agentCounter,
  filters,
  handleChange,
  onChange,
  options,
  value,
  variant,
}: TeamSelectProps) {
  const { data } = useGetCallsCountGroupedByTeamQuery(
    filters ? toSnakeCase(omit(filters, ['teamId'])) : skipToken,
    {
      skip: !filters || !!agentCounter,
    }
  )

  const { extendedOptions } = useSelectOptionsWithCounters({
    agentCounter,
    callCounter: data,
    options,
  })

  return (
    <Select
      handleChange={handleChange}
      hasFilter
      id="team-select"
      label="Team"
      labelId="team-select-label"
      menuSize="lg"
      onChange={onChange}
      options={extendedOptions}
      renderValue={(_, option) => option?.label}
      title="Team"
      value={value || ''}
      variant={variant}
    />
  )
}
