import { useState } from 'react'

export default function useHover() {
  const [hovered, setHovered] = useState<boolean>(false)

  const handleMouseOver = (_event: any) => {
    setHovered(true)
  }

  const handleMouseOut = (_event: any) => {
    setHovered(false)
  }

  return { hovered, handleMouseOver, handleMouseOut }
}
