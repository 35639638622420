import LoginTextField from './LoginTextField'

type PasswordFieldProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: string
}

const PasswordField = ({ onChange, value }: PasswordFieldProps) => {
  return (
    <LoginTextField
      label="Password"
      onChange={onChange}
      placeholder="••••••••"
      type="password"
      value={value}
    />
  )
}

export default PasswordField
