import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import type {
  FilterConfig,
  FilterOptions,
  FilterValues,
  FilterSelects,
} from './types'
import useFiltersCache from './useFiltersCache'

const onlyKnownFields = <T extends Record<string, any>>(
  obj: T,
  keys: string[]
): T => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (keys.includes(key)) {
      acc[key] = value
    }
    return acc
  }, {} as any)
}

const useFilters = <T extends FilterConfig, TTransformed>(
  config: T,
  options: FilterOptions<T, TTransformed>
) => {
  const cacheControl = useFiltersCache<FilterValues<T>>(
    options.cacheKey || window.location.pathname
  )

  const { cache } = cacheControl
  const [rawFilters, setFilters] = useState<FilterValues<T>>({
    ...(options.defaultValues || {}),
    ...(options.cache && cacheControl.initialFilters
      ? onlyKnownFields(cacheControl.initialFilters, Object.keys(config))
      : {} || {}),
  })

  useEffect(() => {
    if (options.cache) {
      cache({
        ...rawFilters,
      })
    }
  }, [rawFilters, cache, options.cache])

  const setFilter = useCallback(
    <TKey extends keyof T>(key: TKey, value?: FilterValues<T>[TKey]) => {
      setFilters((oldFilters) => ({
        ...oldFilters,
        [key]: value,
      }))
    },
    []
  )

  const selects: FilterSelects<T> = useMemo(() => {
    return Object.keys(config).reduce((acc, key) => {
      const typedKey = key as keyof T
      const value =
        rawFilters[typedKey] == null
          ? undefined
          : config[typedKey]?.type === 'boolean'
            ? String(Number(rawFilters[typedKey]))
            : rawFilters[typedKey]

      acc[`${key}Select`] = {
        value,
        onChange: (value: FilterValues<T>[typeof typedKey]) =>
          setFilter(typedKey, value),
      }
      return acc
    }, {} as any)
  }, [setFilter, rawFilters, config])

  const transformRef = useRef(options.transform)
  const filters = useMemo(() => {
    return (transformRef.current?.(rawFilters) ||
      rawFilters) as TTransformed extends {} ? TTransformed : FilterValues<T>
  }, [rawFilters])

  return { rawFilters, setFilter, selects, cacheControl, filters }
}

export default useFilters
