import { Stack } from '@mui/material'

type SplitViewContainerItemProps = {
  children: React.ReactNode
}

const SplitViewContainerItem = ({ children }: SplitViewContainerItemProps) => {
  return <Stack sx={{ flex: 1, minHeight: 0, minWidth: 0 }}>{children}</Stack>
}

export default SplitViewContainerItem
