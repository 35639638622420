import {
  AvatarProps as MuiAvatarProps,
  Avatar as MuiAvatar,
  Skeleton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'

function stringToColor(string: string) {
  if (!string) {
    return ''
  }
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

const getChildren = (name: string): string | undefined => {
  if (!name) {
    return ''
  }

  const tokens = name.split(' ')
  if (tokens.length === 1) {
    return `${tokens[0]?.[0] ?? ''}`
  } else if (tokens.length >= 2) {
    return `${tokens[0]?.[0] ?? ''}${tokens[tokens.length - 1]?.[0] ?? ''}`
  }
}

export const stringAvatar = ({
  name,
  size,
  sx,
}: {
  name: string
  size: number
  sx?: SxProps<Theme>
}) => {
  const variant = (() => {
    if (size <= 24) {
      return 'caption3'
    }
    if (size <= 50) {
      return 'caption2'
    }
    if (size <= 70) {
      return 'h5'
    }
    return 'h4'
  })()
  return {
    alt: name,
    children: name ? (
      <Typography variant={variant}>{getChildren(name)}</Typography>
    ) : undefined,
    sx: {
      bgcolor: stringToColor(name),
      ...sx,
    },
  }
}

type AvatarProps = MuiAvatarProps & {
  loading?: boolean
  size?: number
}

const Avatar = ({
  alt = '',
  loading = false,
  onError,
  onLoad,
  size = 24,
  src,
  sx,
}: AvatarProps) => {
  const combinedSize: number = (() => {
    if (sx && 'width' in sx && typeof sx.width === 'number') {
      return sx.width
    }
    return size
  })()

  const combinedSx: SxProps<Theme> = {
    height: combinedSize,
    width: combinedSize,
    ...sx,
  }

  if (loading) {
    return (
      <Skeleton variant="circular">
        <MuiAvatar sx={combinedSx} />
      </Skeleton>
    )
  }
  return src ? (
    <MuiAvatar
      alt={alt}
      onError={onError}
      onLoad={onLoad}
      src={src}
      sx={combinedSx}
    />
  ) : (
    <MuiAvatar
      {...stringAvatar({ name: alt, size: combinedSize, sx: combinedSx })}
    />
  )
}

export default Avatar
