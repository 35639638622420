const LogoTextWhite = () => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 71 24"
      width="71"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50439 23.6901C6.09659 23.6901 4.82958 23.4488 3.70335 22.9661C2.57711 22.4634 1.69219 21.7595 1.04859 20.8544C0.405057 19.9494 0.083252 18.8936 0.083252 17.6868H3.76364C3.84413 18.5919 4.19604 19.336 4.81952 19.9193C5.46305 20.5024 6.35803 20.794 7.50439 20.794C8.69099 20.794 9.61608 20.5125 10.2798 19.9494C10.9435 19.3661 11.2753 18.6221 11.2753 17.717C11.2753 17.0131 11.0641 16.44 10.6418 15.9975C10.2396 15.555 9.72676 15.2131 9.10327 14.9718C8.49991 14.7305 7.65523 14.469 6.56924 14.1875C5.20162 13.8254 4.08544 13.4635 3.22064 13.1014C2.37597 12.7193 1.65195 12.1361 1.04859 11.3518C0.445302 10.5674 0.143621 9.52157 0.143621 8.21431C0.143621 7.00766 0.445302 5.95178 1.04859 5.04681C1.65195 4.14177 2.49662 3.44793 3.58269 2.96523C4.66868 2.4826 5.92563 2.24121 7.35355 2.24121C9.38483 2.24121 11.044 2.7541 12.3311 3.7798C13.6384 4.7853 14.3624 6.17305 14.5032 7.94281H10.7021C10.6418 7.17862 10.2798 6.52496 9.61608 5.98196C8.95243 5.43897 8.07757 5.16747 6.99158 5.16747C6.00612 5.16747 5.20162 5.41884 4.57821 5.92167C3.95472 6.42442 3.64298 7.14844 3.64298 8.09365C3.64298 8.73726 3.83406 9.27019 4.21616 9.69253C4.61837 10.0947 5.1212 10.4165 5.72457 10.6579C6.32785 10.8993 7.15248 11.1607 8.19823 11.4422C9.58597 11.8243 10.7122 12.2065 11.577 12.5886C12.4618 12.9707 13.206 13.564 13.8094 14.3684C14.4328 15.1528 14.7445 16.2087 14.7445 17.536C14.7445 18.6019 14.4529 19.6075 13.8697 20.5527C13.3066 21.4979 12.4719 22.2622 11.3658 22.8455C10.2798 23.4086 8.99267 23.6901 7.50439 23.6901Z"
        fill="white"
      />
      <path
        d="M27.4001 6.58538C28.667 6.58538 29.7931 6.85687 30.7791 7.39987C31.7844 7.94286 32.5684 8.74737 33.1319 9.81324C33.7154 10.8792 34.0064 12.1663 34.0064 13.6746V23.479H30.598V14.1875C30.598 12.6993 30.2257 11.563 29.482 10.7786C28.7375 9.97414 27.722 9.57193 26.435 9.57193C25.1479 9.57193 24.1217 9.97414 23.3578 10.7786C22.6133 11.563 22.2418 12.6993 22.2418 14.1875V23.479H18.8025V1.15527H22.2418V8.78754C22.8246 8.08364 23.559 7.54065 24.4436 7.15855C25.3492 6.77646 26.3344 6.58538 27.4001 6.58538Z"
        fill="white"
      />
      <path
        d="M40.1429 4.6544C39.5199 4.6544 38.9967 4.44327 38.5741 4.02093C38.1523 3.5986 37.941 3.07565 37.941 2.45224C37.941 1.82875 38.1523 1.30588 38.5741 0.883546C38.9967 0.461208 39.5199 0.25 40.1429 0.25C40.7466 0.25 41.2597 0.461208 41.6815 0.883546C42.104 1.30588 42.3153 1.82875 42.3153 2.45224C42.3153 3.07565 42.104 3.5986 41.6815 4.02093C41.2597 4.44327 40.7466 4.6544 40.1429 4.6544ZM41.8324 6.85664V23.4788H38.3938V6.85664H41.8324Z"
        fill="white"
      />
      <path d="M49.787 1.15527V23.479H46.3477V1.15527H49.787Z" fill="white" />
      <path
        d="M61.5122 23.7501C59.9434 23.7501 58.5256 23.3981 57.2586 22.6942C55.9917 21.9702 54.9956 20.9647 54.272 19.6775C53.5476 18.3703 53.1862 16.8619 53.1862 15.1525C53.1862 13.4631 53.5577 11.9648 54.3022 10.6575C55.0459 9.35032 56.0621 8.34474 57.3492 7.64077C58.6362 6.93687 60.0742 6.58496 61.6631 6.58496C63.252 6.58496 64.69 6.93687 65.977 7.64077C67.2641 8.34474 68.2795 9.35032 69.0233 10.6575C69.7678 11.9648 70.14 13.4631 70.14 15.1525C70.14 16.8418 69.7577 18.3401 68.9938 19.6473C68.2292 20.9546 67.1836 21.9702 65.8563 22.6942C64.5491 23.3981 63.1011 23.7501 61.5122 23.7501ZM61.5122 20.7636C62.3968 20.7636 63.2218 20.5524 63.9857 20.13C64.7697 19.7077 65.4035 19.0742 65.8865 18.2295C66.3686 17.3848 66.6101 16.3591 66.6101 15.1525C66.6101 13.9457 66.3787 12.9301 65.9167 12.1055C65.4538 11.2609 64.8401 10.6274 64.0762 10.2051C63.3116 9.78264 62.4873 9.57151 61.6027 9.57151C60.7173 9.57151 59.8931 9.78264 59.1285 10.2051C58.3847 10.6274 57.7911 11.2609 57.3492 12.1055C56.9065 12.9301 56.6851 13.9457 56.6851 15.1525C56.6851 16.9423 57.1379 18.3301 58.0426 19.3155C58.9682 20.2809 60.1245 20.7636 61.5122 20.7636Z"
        fill="white"
      />
    </svg>
  )
}

export default LogoTextWhite
