import * as React from 'react'

import useFormHelperText from './useFormHelperText'

type useTextFieldProps = {
  defaultValue?: string
  errorHelperText?: React.ReactNode
  forceLowerCase?: boolean
  helperText?: React.ReactNode
  valueController?: (value: string) => void
}

const useTextField = ({
  defaultValue = '',
  errorHelperText: defaultErrorHelperText,
  forceLowerCase = false,
  helperText: defaultHelperText,
  valueController = () => {},
}: useTextFieldProps) => {
  const [value, setValue] = React.useState<string>(defaultValue)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(
      forceLowerCase ? event.target.value.toLowerCase() : event.target.value
    )
  }

  React.useEffect(() => {
    valueController?.(value)
  }, [value, valueController])

  const { error, handleBlur, helperText } = useFormHelperText({
    errorHelperText: defaultErrorHelperText,
    helperText: defaultHelperText,
    value,
  })

  return {
    error,
    handleBlur,
    handleChange,
    helperText,
    setValue,
    value,
  }
}

export default useTextField
