import { Stack } from '@mui/material'

import SelectFilterBar from './SelectFilterBar'
import SelectTitle from './SelectTitle'

type SelectHeaderProps = {
  filter: string
  hasFilter?: boolean
  setFilter: (value: string) => void
  title?: string
}
const SelectHeader = ({
  filter,
  hasFilter,
  setFilter,
  title,
}: SelectHeaderProps) => {
  const hasContent = title || hasFilter
  if (!hasContent) {
    return null
  }
  return (
    <Stack flex="none" mb={1.5} px={2}>
      <Stack alignItems="center" direction="row">
        {!!title && (
          <Stack>
            <SelectTitle sx={{ ml: 1 }}>{title}</SelectTitle>
          </Stack>
        )}
      </Stack>
      {hasFilter && (
        <SelectFilterBar
          inputValue={filter!}
          onChange={setFilter!}
          sx={{ mt: 2 }}
        />
      )}
    </Stack>
  )
}

SelectHeader.muiSkipListHighlight = true

export default SelectHeader
