const LogoWhite64 = () => {
  return (
    <svg
      fill="none"
      height="64"
      viewBox="0 0 37 64"
      width="37"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.7217 15.9999C34.7217 15.9999 28.855 18.2518 23.0772 21.9555C20.2328 23.763 17.3883 25.9556 15.2846 28.3853C11.8475 32.3556 10.6032 37.2148 14.3661 42.0741C2.18831 39.1111 -10.7598 23.763 18.7216 0L34.7217 15.9999Z"
        fill="white"
      />
      <path
        d="M22.159 35.644C20.0553 38.0737 17.2109 40.2366 14.3664 42.0736C10.6035 37.1848 11.8479 32.3551 15.2849 28.3848C17.3886 25.9551 20.2332 23.7921 23.0775 21.9551C26.8406 26.8143 25.6257 31.644 22.159 35.644Z"
        fill="#9F9F9F"
      />
      <path
        d="M18.7211 63.9998L2.72119 47.9999C2.72119 47.9999 8.58779 45.7479 14.3656 42.0443C17.2101 40.2073 20.0545 38.0443 22.1582 35.6146C25.5952 31.6443 26.8397 26.785 23.0767 21.9258C35.2842 24.8887 48.2323 40.2368 18.7211 63.9998Z"
        fill="white"
      />
    </svg>
  )
}

export default LogoWhite64
