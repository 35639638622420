import { alpha } from '@mui/material'

import neutral from './neutral'

export const transparentNeutral = {
  '8': alpha(neutral[800], 0.08),
  '12': alpha(neutral[800], 0.12),
  '16': alpha(neutral[800], 0.16),
  '38': alpha(neutral[800], 0.38),
  '80': alpha(neutral[800], 0.8),
}
