import { Stack, type StackProps } from '@mui/material'

type LoginFormProps<C extends React.ElementType> = StackProps<C>

const LoginForm = <C extends React.ElementType>({
  children,
  ...rest
}: LoginFormProps<C>) => {
  return (
    <Stack spacing={3} {...rest}>
      {children}
    </Stack>
  )
}

export default LoginForm
