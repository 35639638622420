import { Box } from '@mui/material'

import SelectClearButton from './SelectClearButton'

type SelectFooterProps = {
  hasReset?: boolean
  onReset: () => void
}

const SelectFooter = ({ hasReset = false, onReset }: SelectFooterProps) => {
  return hasReset ? (
    <Box
      sx={{
        borderColor: 'divider',
        borderTop: 1,
        p: 2,
        pb: 0,
      }}
    >
      <SelectClearButton onReset={onReset} />
    </Box>
  ) : null
}

export default SelectFooter
