import { Stack, SxProps } from '@mui/material'

import { mergeSx } from '@/modules/sx/mergeSx'
import { PRIMARY_FOCUS, PRIMARY_HOVER } from '@/theme/palette/action'

type SplitViewContainerListItemProps = {
  active: boolean
  children: React.ReactNode
  onClick?: () => void
  sx?: SxProps
}

const SplitViewContainerListItem = ({
  active,
  children,
  onClick,
  sx,
}: SplitViewContainerListItemProps) => {
  return (
    <Stack
      onClick={onClick}
      sx={mergeSx(
        {
          background: active ? PRIMARY_FOCUS : 'inherit',
          borderRadius: 2,
          cursor: 'pointer',
          px: 2,
          py: 1.5,
          transition: 'all 0.2s ease-in-out',
          // eslint-disable-next-line sort-keys-fix/sort-keys-fix
          '&:hover': {
            background: active ? PRIMARY_FOCUS : PRIMARY_HOVER,
          },
        },
        sx
      )}
    >
      {children}
    </Stack>
  )
}

export default SplitViewContainerListItem
