import { Divider } from '@mui/material'

const NotificationDivider = () => {
  return (
    <Divider
      sx={{ backgroundColor: (theme) => theme.palette.grey[20], mx: 1 }}
    />
  )
}

export default NotificationDivider
