import { toastSlice } from '@/app/features/toast/toastSlice'
import { useAppDispatch, useAppSelector } from '@/app/store'

const useToast = () => {
  const dispatch = useAppDispatch()

  const isOpened = useAppSelector((s) => s.toast.isOpened)
  const message = useAppSelector((s) => s.toast.message)
  const autoHideDuration = useAppSelector((s) => s.toast.autoHideDuration)
  const toastState = {
    isOpened,
    message,
    autoHideDuration,
  }

  const openToast = (message: string, timeout?: number) => {
    dispatch(toastSlice.actions.open({ message, timeout }))
  }

  const closeToast = () => {
    dispatch(toastSlice.actions.close())
  }

  return {
    openToast,
    closeToast,
    toastState,
  }
}

export default useToast
