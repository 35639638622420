import { ListItem, Typography, type SxProps } from '@mui/material'

import Bullet from '@/components/Common/Bullet/Bullet'

type OutcomeItemProps = {
  sx?: SxProps
  title: string
}

const OutcomeItem = ({ sx, title }: OutcomeItemProps) => {
  return (
    <ListItem sx={{ display: 'flex', gap: 0.5, p: 0, ...sx }}>
      <Bullet color="primary" size="small" />
      <Typography color={(theme) => theme.palette.grey[500]} variant="body2">
        {title}
      </Typography>
    </ListItem>
  )
}

export default OutcomeItem
