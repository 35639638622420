import { ReactNode } from 'react'
import { Stack, type SxProps } from '@mui/material'
import { Theme } from '@mui/material'

import ControlBarFilters from './ControlBarFilters'

type ControlBarProps = {
  isFiltersLoading?: boolean
  leftFilters?: ReactNode
  rightFilters?: ReactNode
  start?: ReactNode
  sx?: SxProps<Theme>
}

const ControlBar = ({
  isFiltersLoading,
  leftFilters,
  rightFilters,
  start,
  sx,
}: ControlBarProps) => {
  return (
    <Stack
      alignItems="center"
      columnGap={1}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
      mb={4}
      rowGap={3}
      sx={sx}
    >
      {start}
      <ControlBarFilters
        leftFilters={leftFilters}
        rightFilters={rightFilters}
        sx={{
          visibility: isFiltersLoading ? 'hidden' : 'visible',
        }}
      />
    </Stack>
  )
}

export default ControlBar
