import { Skeleton, Stack, Typography, type StackProps } from '@mui/material'

type InfoBlockProps = StackProps & {
  loading?: boolean
  title: string
}

const InfoBlock = ({ children, loading, title, ...rest }: InfoBlockProps) => {
  return (
    <Stack spacing={1} {...rest}>
      <Typography variant="caption">{title}</Typography>
      <Typography variant="body2">
        {loading ? <Skeleton width="90%" /> : children}
      </Typography>
    </Stack>
  )
}

export default InfoBlock
