import { TextFieldProps } from '@mui/material'

import LoginTextField from './LoginTextField'

type EmailFieldProps = Pick<TextFieldProps, 'disabled'> & {
  email: string
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const EmailField = ({ disabled, email, onEmailChange }: EmailFieldProps) => {
  return (
    <LoginTextField
      disabled={disabled}
      label="Email Address"
      onChange={onEmailChange}
      value={email}
      //placeholder="john@yourcompany.com"
    />
  )
}

export default EmailField
