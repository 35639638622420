import { CircularProgress, Stack } from '@mui/material'

import Page from '../components/UIKit/Page/Page'

const LoadingPage = () => {
  return (
    <Page>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%' }}
      >
        <CircularProgress />
      </Stack>
    </Page>
  )
}

export default LoadingPage
